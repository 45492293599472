import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mosaic-journey-key',
	templateUrl: './journey-key.component.html',
	styleUrls: ['./journey-key.component.scss']
})
export class JourneyKeyComponent {

}
