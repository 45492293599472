// filter.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';
import { Network } from '../../shared/models/npi-group';
import { NetworkAcoOption } from '../../shared/models/network-aco-option';

@Pipe({ name: 'filterNetworks' })
export class FilterNetworksPipe implements PipeTransform {
	/**
	 * Transform
	 *
	 * @param items
	 * @param searchText
	 * @returns
	 */
	transform(networks: NetworkAcoOption[], searchText: string): any[] {
		if (!networks) 
			return [];
		
		if (!searchText) 
			return networks;
		
		searchText = searchText.toLocaleLowerCase();

		return networks.filter(network => network.name.toLocaleLowerCase().includes(searchText));
	}
}
