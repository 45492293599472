import { Injectable } from '@angular/core';
import { NetworkApi } from 'src/app/api/network.api';
import { Network } from 'src/app/shared/models/npi-group';
import { NetworkSelection } from '../models/network-selection';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AcoDashboardService } from './aco-dashboard.service';
import { NetworkAcoOption } from '../models/network-aco-option';
import { NETWORK_TYPE } from '../models/network';
import { ProgramType } from '../enums/aco-period-type.enum';
import { AcoPeriod } from '../models/aco-period';
import { Filter } from '@appcore/models/filter.model';

@Injectable({
	providedIn: 'root'
})
export class NetworkSelectionService {
	acoPeriodNetworkTypes: {programType: ProgramType, networkTypes: NETWORK_TYPE[]}[] = [{programType: ProgramType.Dce, networkTypes: [NETWORK_TYPE.DCE_STANDARD]}];
	hasNetworks: boolean;
	public readonly networks: Observable<Partial<Network>[]>;
	public readonly selectedNetwork: Observable<NetworkSelection>;

	private _networks: BehaviorSubject<Partial<Network>[]> = new BehaviorSubject([]);
	private _selectedNetwork: BehaviorSubject<NetworkSelection> = new BehaviorSubject(null);
	private networkFilter: Filter;
	private selectedGroupId: number;

	constructor(private networkApi: NetworkApi, private acoDashboardService: AcoDashboardService) {
		this.networks = this._networks.asObservable();
		this.selectedNetwork = this._selectedNetwork.asObservable();
	}

	delete(groups: any[]) {
		return this.networkApi.delete(groups);
	}

	getEditInfo(networkId: string) {
		return this.networkApi.getNetworkEdit(networkId);
	}

	getNetworks() {
		return this.networkApi.list().pipe(
			map(response => {
				if (!response.length) {
					this.hasNetworks = false;
					return response;
				}

				this._networks.next(
					response
						.map(x => ({
							id: x.id,
							title: x.name
						}))
						.sort((a, b) => {
							const textA = a.title.toUpperCase();
							const textB = b.title.toUpperCase();
							return textA < textB ? -1 : textA > textB ? 1 : 0;
						})
				);

				this.hasNetworks = true;

				const lastViewedId = this.acoDashboardService.getLastViewedNetworkAco();
				const groups = this._networks.getValue();

				const firstGroup = groups[0];
				this.selectedGroupId = groups.some(x => x.id === lastViewedId) ? lastViewedId : firstGroup.id;
				this.acoDashboardService.setLastViewedNetworkAco(this.selectedGroupId.toString());

				if (this.selectedGroupId !== lastViewedId)
					this.select(firstGroup);
				

				this.setNetworkFilter();

				return response;
			})
		);
	}

	removeFromGroup(npis: Array<string>, networkId: number) {
		return this.networkApi.removeNpisFromGroup(npis, networkId).pipe(
			map(response => true),
			catchError(err => of(false))
		);
	}

	selectNetwork(id: number) {
		if (id === this.selectedGroupId) 
			return;
		

		this.selectedGroupId = id;
		this.acoDashboardService.setLastViewedNetworkAco(this.selectedGroupId.toString());
		this.setNetworkFilter();
	}

	setNetworkFilter() {
		this.networkFilter = {
			filterType: 'network',
			paramName: '',
			modelJson: `[${this.selectedGroupId?.toString()}]`
		};

		this._selectedNetwork.next({ filter: this.networkFilter, network: this.selectedGroupId });
	}

	getNetworksWithFlag(npiParameter: string) {
		return this.networkApi.getNetworksWithNpi(npiParameter);
	}

	shouldShowAcoPeriodDropdown(programType: ProgramType, networkType: NETWORK_TYPE){
		return this.acoPeriodNetworkTypes.find(t => t.programType === programType)?.networkTypes.includes(networkType);
	}

	private select(firstGroup: Partial<Network>) {
		const options = new NetworkAcoOption();
		options.id = firstGroup.id.toString();
		options.name = firstGroup.title;
		options.isUserDefined = true;
		options.markets = firstGroup.markets;
		options.networkId = firstGroup.id;
		options.networkType = firstGroup.networkType;
		this.acoDashboardService.selectNetworkAco(options);
	}
}
