import { TotalCostOfCareType } from '../../shared/models/total-cost-of-care.model';
import { NpiGroupNpi } from '@appcore/models/npi-group-npi.model';

export class TotalCostSectionOptions {
	totalCostOfCareType: TotalCostOfCareType;
	npis: NpiGroupNpi[] = [];
	isRiskAdjusted = true;
	filter: string;
	timePeriod: string;
	activeLegendItems: any[];
	selectedPatientsLiveStatus: string;

	constructor(totalCost: Partial<TotalCostSectionOptions>) {
		if (!totalCost.totalCostOfCareType) 
			throw Error('must provide facility type so comparison dialog works');
		
		Object.assign(this, totalCost);
	}
}
