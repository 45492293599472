// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.breakout, table.breakout th, table.breakout td {
  border: 1px solid black;
  padding: 2px;
}

table.breakout {
  width: 100%;
}

.mainHeader {
  background-color: lightblue;
}

input {
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dev-info-dialog/dev-info-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["table.breakout, table.breakout th, table.breakout td {\n    border: 1px solid black;\n    padding: 2px;\n}\n\ntable.breakout {\n    width: 100%;\n}\n\n.mainHeader {\n    background-color: lightblue;\n}\n\ninput { \n    font-size: .8rem; \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
