// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.other-label-width {
  width: 205px;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

.star-rating-container {
  white-space: nowrap;
}

.presentation-button {
  border-radius: 0.25rem;
  border: solid 1px #f6f6f6;
  background-color: #43b02a;
  color: white;
  margin: 0 0 0 15px;
  padding: 2px 15px;
}`, "",{"version":3,"sources":["webpack://./appcore/components/aco-demographic-card/aco-demographic-card.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,yBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".other-label-width {\n    width: 205px;\n}\n\na, a:visited, a:hover, a:active {\n    color: inherit;\n}\n\n.star-rating-container {\n    white-space: nowrap;\n}\n\n.presentation-button {\n    border-radius: .25rem;\n    border: solid 1px #f6f6f6;\n    background-color: #43b02a;\n    color: white;\n    margin: 0 0 0 15px;\n    padding: 2px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
