import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import {
	NpiTypeConfig
} from '@appcore/models/npi/npi-type-config.model';

@Component({
	selector: 'mosaic-npi-type-option',
	templateUrl: './npi-type-option.component.html',
	styleUrls: ['./npi-type-option.component.scss']
})
export class NpiTypeOptionComponent {
	@Input() npiTypeOption: NpiTypeConfig;
	@Input() selectable = true;
	
	@Output() selectedNpiType: Observable<NpiTypeConfig>;
	
	private _selectedNpiType: EventEmitter<NpiTypeConfig> = new EventEmitter<NpiTypeConfig>();

	selectNpiType() {
		this.selectedNpiType = this._selectedNpiType.asObservable();
		this._selectedNpiType.emit(this.npiTypeOption);
	}

}
