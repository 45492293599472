import { AcoDashboardService } from './../../../shared/services/aco-dashboard.service';
import { Component, OnInit } from '@angular/core';

import { Tile } from 'src/app/shared/models/tile.model';
import { AcoTopMetricsService } from '../../../shared/services/aco-top-metrics.service';
import { AcoService } from '../../../shared/services/aco.service';
import { PAGE } from 'src/app/shared/enums/page.enum';
import { takeUntil } from 'rxjs/operators';
import { ProgramType } from 'src/app/shared/enums/aco-period-type.enum';
import { AcoPeriodService } from 'src/app/shared/services/aco-period.service';
import { Title } from '@angular/platform-browser';
import { NetworkSelectionService } from 'src/app/shared/services/network-selection.service';
import { AlignType } from 'src/app/shared/enums/aco-period-alignment-type.enum';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';

@Component({
	selector: 'trella-aco-top-metrics',
	templateUrl: './aco-top-metrics.component.html',
	styleUrls: ['./aco-top-metrics.component.scss']
})
export class AcoTopMetricsComponent extends ComponentWithSubscription implements OnInit {
	tiles: Tile[] = [];
	isUserAcoWithoutPhysicians = false;
	loading = false;

	acoId?: string;
	acoPeriodId: number;
	networkId: number;
	programType = ProgramType.Dce;
	alignType = AlignType.None;
	targetTooltipText = '';

	showAcoPeriodDrowpdown = false;


	constructor(private acoDashboardService: AcoDashboardService,
	 private acoTopMetricsService: AcoTopMetricsService,
	 private acoService: AcoService,
	 private userFeedbackService: UserFeedbackService,
	 private acoPeriodService: AcoPeriodService,
	 private titleService: Title,
	 private networkSelectionService: NetworkSelectionService) {
		super();
	}

	ngOnInit() {
		this.titleService.setTitle('Strategy - Home');
		this.acoDashboardService.beforeSelectAco.pipe(takeUntil(this.ngUnsubscribe)).subscribe(loading => {
			this.loading = loading;
		});

		this.acoDashboardService.selectedNetworkAco.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedNetwork=>{
			if (selectedNetwork?.acoId) {
				const acoPeriodId = 6;
				this.acoTopMetricsService.getTilesByAco(selectedNetwork.acoId, acoPeriodId, PAGE.ACO_DASHBOARD).subscribe();
				this.isUserAcoWithoutPhysicians = false;
			}
			if (selectedNetwork?.networkId) {
				this.acoService.hasPhysicians(selectedNetwork.networkId).
					subscribe(hasPhysicians => this.isUserAcoWithoutPhysicians = !hasPhysicians);
				this.networkId = selectedNetwork.networkId;
				this.showAcoPeriodDrowpdown = this.networkSelectionService.shouldShowAcoPeriodDropdown(this.programType, selectedNetwork.networkType);
				
				if(this.showAcoPeriodDrowpdown)
				{
					this.acoPeriodService.selectedAcoPeriod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedAcoPeriod => {
						if(selectedAcoPeriod) {
							this.acoPeriodId = selectedAcoPeriod;
							this.getNewTiles();
						}
					});
				}
				else {
					this.acoPeriodService.getDefaultAcoPeriod(this.programType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(acoPeriod => {
						this.acoPeriodId = acoPeriod.id;
						this.getNewTiles();
					});
				}
			}
		});

		this.acoTopMetricsService.userTilesFetched.pipe(takeUntil(this.ngUnsubscribe)).subscribe(t => {
			if (t) 
				this.loading = false;
			

			this.tiles = t;
		},
		error => {
			this.tiles = [];
			this.loading = false;
			this.userFeedbackService.showUnexpectedError();
		});
	}

	getNewTiles() {
		this.loading = true;
		if (this.networkId && this.acoPeriodId) 
			this.acoTopMetricsService.getTilesByNetwork(this.networkId, this.acoPeriodId, PAGE.ACO_DASHBOARD).subscribe();
		
	}
}
