// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
  min-width: 325px;
}`, "",{"version":3,"sources":["webpack://./appcore/components/filter-selection-dropdown/filter-selection-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ","sourcesContent":["select {\n    min-width: 325px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
