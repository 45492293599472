import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationService } from '../../shared/services/navigation.service';
import { ColumnSelectEvent } from '../../shared/models/column-select-event.model';
import { NetworkResponse } from '../../api/network.api';
import { AcoService } from '../../shared/services/aco.service';
import { takeUntil } from 'rxjs/operators';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { Filter } from '@appcore/models/filter.model';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { Utils } from '@appcore/helpers/Utils';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { NpiType } from '@appcore/enums/npi-type.enum';
import { OnChangeAfterReady } from '@appcore/helpers/decorators/onchange-decorator';
import { SearchItem } from '@appcore/models/search/search-item.model';
import { ConfigType } from '@appcore/enums/config-type.enum';
import { GridColumn } from '@appcore/models/grid-column.model';
import { FILTER_CATEGORY, JOURNEY_ROOT_PATH } from '@appcore/constants/constants';
import { GRID_COMMAND } from '@appcore/enums/grid-command.enum';

@Component({
	selector: 'mosaic-journey',
	templateUrl: './journey.component.html',
	styleUrls: ['./journey.component.scss']
})
export class JourneyComponent extends ComponentWithSubscription implements OnInit, AfterViewInit {
	@Input()
	isAco = false;

	@OnChangeAfterReady<any>(function (value) {
		// requires this.ready
		this.onNpiChange();
	})
	@Input()
	selectedNpi: SearchItem;
	@Input()
	npiLeakage: string;

	@OnChangeAfterReady<any>(function (value) {
		this.onFilterChange();
	})
	@Input()
	selectedNetwork: NetworkResponse;

	@OnChangeAfterReady<any>(function (value) {
		// requires this.ready
		this.onFilterChange();
	})
	@Input()
	selectedFilters: Filter[];

	@Output() stateChange: EventEmitter<any> = new EventEmitter();

	filters;
	pathFilters;
	detailFilters;
	hasMultipleFilters = false;
	ready = false;

	chartName = 'Journey'; // For Sunburst Chart
	sunburstChartType = ConfigType.Sunburst;
	reportPathName = 'Journey_path'; // For Path grid (on right side)
	reportDetailName = 'Journey_destinations'; // For destinations grid (below sunburst)
	followUpMonthOptions = ['12', '6', '3'];
	selectedFilter: string;
	selectedTimePeriod: string;
	pathList = '';
	destinationGridList: any;
	DefaultList = '-';
	selectedName = '';
	infoDisplay1 = '';
	infoDisplay2 = '';
	updateChartDataSubject = new Subject<GridColumn[]>();
	updatePathDataSubject = new Subject<GridColumn[]>();
	updateDetailsDataSubject = new Subject<GridColumn[]>();
	resetPaging$ = new Subject<void>();

	private _latestFullClaimsYear = '';

    constructor(private navigationService: NavigationService, private acoService: AcoService, private userFeedbackService: UserFeedbackService) {
		super();
	}

	get quarterRange() {
		return this.isAco?
			`${this._latestFullClaimsYear} Q1 - ${this._latestFullClaimsYear} Q4` :
			Utils.getYearQuarterStringFromQm('QM4') + '-' + Utils.getYearQuarterStringFromQm('QM1');
	}

	ngOnInit() {
		// Default for PATH GRID
		this.pathList = JOURNEY_ROOT_PATH;
		this.filters = this.getFilters();
		this.setPathGridFilters();

		// Default for DESTINATION GRID
		this.destinationGridList = this.DefaultList;
		this.setDetailGridFilters();
		this.getLatestFullClaimsYear();

		this.ready = true;
	}

	ngAfterViewInit() {
		this.updateChartDataSubject.next(null); // Trigger Sunburst Chart
		this.updatePathDataSubject.next(null); // Trigger Path Grid
		this.updateDetailsDataSubject.next(null); // Trigger Destination Grid
	}

	getSelectedNpiName() {
		if (!this.selectedNpi) 
			return null;
		
		const npi = this.selectedNpi as any;
		return npi.npiName || npi.display;
	}

	getLatestFullClaimsYear() {
		if (!this.isAco)
		{
			this.setFilterDisplayed();
			return;
		}

		this.acoService.getLatestFullClaimsYear().subscribe(
			response => {},
			error => this.userFeedbackService.showUnexpectedError());

		this.acoService.latestFullClaimsYear.
			pipe(takeUntil(this.ngUnsubscribe)).
			subscribe(year => {
				this._latestFullClaimsYear = year;
				this.setFilterDisplayed();
				}
			);
	}

	selectedNpiCityState() {
		if (this.selectedNpi && this.selectedNpi.city && this.selectedNpi.state && this.selectedNpi.zipcode) 
			return `${this.selectedNpi.city}, ${this.selectedNpi.state} ${this.selectedNpi.zipcode}`;
		

		// TODO: Legacy code to support journey tab local storage prior to the Feb. release 3.0. We we probably delete after the March or April release.
		if (this.selectedNpi && (this.selectedNpi as any).npiName) 
			return (this.selectedNpi as any).npiName;
		
		return '';
	}

	onNpiChange() {
		this.pathList = JOURNEY_ROOT_PATH;
		this.destinationGridList = this.DefaultList;

		this.setDetailGridFilters();
		this.onFilterChange();
		this.updateChartDataSubject.next(null);
		this.updatePathDataSubject.next(null);
		this.updateDetailsDataSubject.next(null);
	}

	onFilterChange() {
		this.filters = this.getFilters();
		this.destinationGridList = this.DefaultList; // turn off Destination Grid
		this.setPathGridFilters();
		this.setDetailGridFilters();
		this.updateChartDataSubject.next(null);
		this.updatePathDataSubject.next(null);
		this.updateDetailsDataSubject.next(null);
		this.setFilterDisplayed();
	}

	setFilterDisplayed() {
		// Any_ACO Filter
		if (
			this.filters.some(
				x => x.paramName && x.paramName === 'category1HC' && x.modelJson && x.modelJson === FILTER_CATEGORY.ACO_FLAG.value
			)
		) {
			this.infoDisplay1 =
				'The center of the chart represents inpatient stays at the selected NPI that are assigned to an ACO at time of discharge for the following reporting period: ' +
				this.quarterRange +
				'.';
			this.infoDisplay2 = 'Subsequent rings are up to 12 months from the date of discharge.';
			return;
		}
		// ACO ID Filter
		if (
			this.filters.some(
				x => x.paramName && x.paramName === 'category1HC' && x.modelJson && x.modelJson === FILTER_CATEGORY.ACO_ID.value
			)
		) {
			this.infoDisplay1 =
				'The center of the chart represents inpatient stays at the selected NPI that are assigned to an ACO at time of discharge for the following reporting period: ' +
				this.quarterRange +
				'.';
			this.infoDisplay2 =
				'Subsequent segments are also filtered by ACO assignment at time of discharge, up to 12 months from original Inpatient discharge.';
			return;
		}
		// Default Info Message
		this.infoDisplay1 =
			'The center of the chart represents inpatient stays at the selected NPI for the following reporting period: ' +
			this.quarterRange +
			'.';
			this.infoDisplay2 = `Subsequent rings are up to 12 months from the date of discharge. The initial discharge one-year reporting period ending in ${Utils.getYearQuarterStringFromQm('QM1')} allows for a complete view of hospital discharge activity and follow-up events through ${Utils.getYearQuarterStringFromQm('QM0')} with meaningful volume. Time period filters can be used to adjust reference period requirements.`;
	}

	onClickSunburst(pointOptions: any) {
		this.pathList = pointOptions.patientPath;
		this.destinationGridList = pointOptions.patientPath;
		this.setPathGridFilters();
		this.setDetailGridFilters();
		this.updatePathDataSubject.next(null);
		this.updateDetailsDataSubject.next(null);
		this.resetPaging$.next(null);
	}

	handlePathGridEvent(parentEvt: ColumnSelectEvent) {
		const evt = parentEvt.event;
		// Assume first row of 'selected' rows is correct one - since we turned on mode=single
		if (evt && evt.command === GRID_COMMAND.select) {
			const selectedRow = evt.sourceEvent && evt.sourceEvent.selectedRows && evt.sourceEvent.selectedRows[0];
			if (selectedRow) {
				this.destinationGridList = selectedRow.dataItem.patientPath.value;
				this.setDetailGridFilters();
			}
			this.updateDetailsDataSubject.next(null);
		}
	}

	destinationSelected(event: ColumnSelectEvent) {
		if (event.event.command === GRID_COMMAND.view) {
			const npiType = this.getSelectedProvider();
			const npi = event.event.dataItem.npi.value;
			this.navigationService.navigateToAnalyzeByNpiOrMedicalEntityType(npiType, npi);
		}
	}

	getFilters() {
		let sunburstFilters: Filter[] = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'npiHC',
				modelJson: this.selectedNpi.npi
			}
		];
		sunburstFilters = sunburstFilters.concat(this.selectedFilters);

		return sunburstFilters;
	}

	setPathGridFilters() {
		let pathNewFilters: Filter[] = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'npiHC',
				modelJson: this.selectedNpi.npi
			},
			{
				filterType: FILTER_TYPE.PATH_LIST,
				paramName: 'pos',
				modelJson: this.pathList
			},
			{
				filterType: FILTER_TYPE.PATTERN_LIST,
				modelJson: this.pathList
			}
		];

		pathNewFilters = this.includeSelectedNetwork(pathNewFilters);
		pathNewFilters = pathNewFilters.concat(this.selectedFilters);
		this.pathFilters = pathNewFilters;
	}

	setDetailGridFilters() {
		let patternFilter: Filter[] = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'npiHC',
				modelJson: this.selectedNpi.npi
			},
			{
				filterType: FILTER_TYPE.DYNAMIC,
				paramName: 'pattern',
				modelJson: this.getCarePath()
			}
		];

		patternFilter = this.includeSelectedNetwork(patternFilter);
		patternFilter = patternFilter.concat(this.selectedFilters);
		this.detailFilters = patternFilter;
		this.hasMultipleFilters = this.hasMultipleSelectedFilters(patternFilter);
	}

	getCarePath() {
		let carePath = 'INP_';
		if(this.destinationGridList.value === 0 || this.destinationGridList === 0) 
			carePath += '-';
		
		else 
			carePath += (this.destinationGridList.value || this.destinationGridList);
		
		const segments = carePath.split('_').length;

		switch (segments) {
			case 2:
				return `${carePath}_-_-`;
			case 3:
				return `${carePath}_-`;
			default:
				return carePath;
		}
	}

	getModelState(): any {
		return {
			filter: this.selectedFilter,
			npi: this.selectedNpi.npi,
			timePeriod: this.selectedTimePeriod
		};
	}

	onStateChange() {
		const state = this.getModelState();
		this.stateChange.emit(state);
	}

	navigateToAnalyze() {
		this.navigationService.navigateToAnalyzeByNpiOrMedicalEntityType(NpiType.hospital, this.selectedNpi.npi);
	}

    private getSelectedProvider(): NpiType {
		const split = this.destinationGridList.split('_');
		const pathType = split
			.reverse()
			.filter(s => !!s)
			.find(s => s !== '-');
		return this.getNpiTypeByPathString(pathType);
	}

	private getNpiTypeByPathString(pathString: string): NpiType {
		const upperCasePath = pathString && pathString.toUpperCase();
		switch (upperCasePath) {
			case 'INPATIENT':
			case 'OUTPEROBS':
			case 'INPER':
			case 'INP':
				return NpiType.hospital;
			case 'HHA':
			case 'RHHA':
				return NpiType.homeHealthAgency;
			case 'SNF':
				return NpiType.skilledNursingFacility;
			case 'HOS':
				return NpiType.hospice;
		}
		throw Error(`Cannot find npi type from ${pathString}`);
	}

    private hasMultipleSelectedFilters(filters: Filter[]) : boolean {
		const selected = filters.filter(
			filter =>
				filter.filterType === FILTER_TYPE.HARD_CODED &&
				(filter.paramName === 'group1HC' || filter.paramName === 'group2HC') &&
				(filter.modelJson === 'ALL' || filter.modelJson === '')
		);
		return !selected.length;
	}

	private includeSelectedNetwork(filters: Filter[]) {
		if (this.selectedNetwork) {
			return filters.concat(
				{
					filterType: 'Network',
					modelJson: `[${this.selectedNetwork.id}]`
				},
				{
					filterType: FILTER_TYPE.HARD_CODED,
					paramName: 'networkId',
					modelJson: `${this.selectedNetwork.id}`
				});
		}

		return filters;
	}
}
