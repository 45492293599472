import { OnInit, Component } from '@angular/core';
import { StrategizeCostService } from '../../shared/services/strategize-cost.service';
import { TotalCostSectionOptions } from '../../components/total-cost-of-care/total-cost-section-options';
import { TotalCostOfCareType } from '../../shared/models/total-cost-of-care.model';

@Component({ template: '' })
export class BaseTotalCostComponent implements OnInit {
	tccOptions: TotalCostSectionOptions;

	constructor(private strategizeCostService: StrategizeCostService) {}

	ngOnInit() {
		const totalCostOfCareType = this.getTotalCostOfCareTypeType();
		const appStorage = this.strategizeCostService.getConfig(totalCostOfCareType);
		this.tccOptions = { ...appStorage, totalCostOfCareType };
		this.init();
	}

	saveState(state: TotalCostSectionOptions) {
		this.strategizeCostService.setConfig(state);
	}

	getTotalCostOfCareTypeType(): TotalCostOfCareType {
		return null;
	}
	init() {}
}
