import { Component, OnInit } from '@angular/core';

import { Tile } from 'src/app/shared/models/tile.model';
import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { AcoTopMetricsService } from 'src/app/shared/services/aco-top-metrics.service';
import { ACO_DASHBOARD_PATH } from 'src/app/main/aco-dashboard/aco-dashboard.constants';
import { Subject } from 'rxjs';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { AcoTileService } from 'src/app/shared/services/aco-tile.service';
import { AcoService } from 'src/app/shared/services/aco.service';
import { TILE_DRILLDOWN_TYPE } from '../../shared/enums/tile-drilldown-type.enum';
import { PAGE } from 'src/app/shared/enums/page.enum';
import { PmpmSpending } from 'src/app/shared/models/pmpm-spending';
import { AcoPeriodService } from 'src/app/shared/services/aco-period.service';
import { takeUntil } from 'rxjs/operators';
import { AcoInfoService } from 'src/app/main/analyze/analyze-aco.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { Target } from '@appcore/models/target.model';
import { Filter } from '@appcore/models/filter.model';

@Component({
	selector: 'mosaic-aco-pmpm',
	templateUrl: './aco-pmpm.component.html',
	styleUrls: ['./aco-pmpm.component.scss']
})
export class AcoPmpmComponent extends ComponentWithSubscription implements OnInit {
	acoId: string;
	acoPeriodId: number;
	year: number;
	updateChartData = new Subject();
	updateGridData = new Subject();

	tile: Tile;
	target: Target;
	sums: PmpmSpending[] = [];

	chartName: string;
	gridName: string;
	primaryProviderType: string;

	chartLoading = true;
	tileLoading = true;
	isRiskAdjusted = false;

	acoChartName = 'aco_pmpm_yearly_trends_cms';
	acoGridName = 'aco_providers_cms';

	gridFilters: Filter[];
	chartFilters: Filter[];

	getAnalyzeUrl = this.navigationService.getAnalyzeUrl;

	constructor(
		public acoDashboardService: AcoDashboardService,
		private acoTopMetricsService: AcoTopMetricsService,
		private navigationService: NavigationService,
		private acoTileService: AcoTileService,
		private acoService: AcoService,
		private acoPeriodService: AcoPeriodService,
		private acoInfoService: AcoInfoService
	) {
		super();
	}

	ngOnInit() {
		this.acoPeriodId = this.acoPeriodService.getLastSelectedPeriod();
		this.acoPeriodService.selectedAcoPeriod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedAcoPeriod => {
			if (selectedAcoPeriod) {
				this.acoPeriodId = selectedAcoPeriod;
				this.acoTopMetricsService.getTilesByAco(this.acoId, this.acoPeriodId, PAGE.ACO_ANALYZE).subscribe();
				this.acoService.getCmsAcoPmpmSums(this.acoId).subscribe();
			}
		});

		this.acoInfoService.acoInfo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(info => {
			if (info) {
				this.acoId = info.id;
				this.acoTopMetricsService.getTilesByAco(this.acoId, this.acoPeriodId, PAGE.ACO_ANALYZE).subscribe();
				this.acoService.getCmsAcoPmpmSums(this.acoId).subscribe();
			}
		});
		this.acoTopMetricsService.cmsTilesFetched.pipe(takeUntil(this.ngUnsubscribe)).subscribe(t => this.handleTiles(t));

		this.acoTileService.riskAdjustedTileChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(t => {
			this.isRiskAdjusted = this.acoTileService.isRiskAdjusted(this.tile);
			this.onChange();
		});

		this.acoService.pmpmSpending.pipe(takeUntil(this.ngUnsubscribe)).subscribe(p => {
			if (!p) 
				return;
			

			const spendingWithTarget = this.isRiskAdjusted ? p.find(s => s.riskAdjustedTarget) : p.find(s => s.target);
			const target = this.isRiskAdjusted ? spendingWithTarget?.riskAdjustedTarget : spendingWithTarget?.target;
			this.sums = p;

			if (!target) {
				this.target = null;
				return;
			}
			this.target = {
				value: Number(target.replace(/[^0-9\.-]+/g, '')).toString(),
				title: target
			};
		});
	}

	onBackClick() {
		if (this.acoId) {
			this.navigationService.navigateToAcoAnalyze(this.acoId);
			return;
		}
		this.navigationService.navigateToDashboardChild([ACO_DASHBOARD_PATH.ACO_INSIGHTS]);
	}

	chartDataChanged() {
		this.chartLoading = false;
	}

	private onChange() {
		this.chartLoading = true;
		this.chartName = !this.isRiskAdjusted ? this.acoChartName : this.acoChartName + '_adj';
		this.gridName = this.acoGridName;
		this.primaryProviderType = 'aco';
		this.gridFilters = this.getAcoGridFilter();
		this.chartFilters = this.getAcoChartFilter();
		this.updateChartData.next(null);
		this.updateGridData.next(null);
	}

	private getAcoGridFilter() {
		this.acoPeriodId = this.acoPeriodService.getLastSelectedPeriod();
		const acoFilter: Filter[] = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: '@acoId',
				modelJson: String(this.acoId)
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: '@acoPeriodId',
				modelJson: String(this.acoPeriodId)
			}
		];
		return acoFilter;
	}

	private getAcoChartFilter() {
		const acoFilter: Filter[] = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: '@acoId',
				modelJson: String(this.acoId)
			}
		];
		return acoFilter;
	}

	private handleTiles(t: Tile[]) {
		if (!t) 
			return;
		
		this.tile = t.find(x => x.drillDownType === TILE_DRILLDOWN_TYPE.PMPM);
		this.isRiskAdjusted = this.acoTileService.isRiskAdjusted(this.tile);
		this.tileLoading = !this.tile;
		this.onChange();
	}
}
