export class EConfigInfo {
	columns: EColumnModel[];
	footerSql: string;
	configPath: string;
	sql: string;
	title: string;
}

export class EColumnModel {
	preHeader: string;
	columns: EColumnModel[];
	title: string;
	field: string;
	definition: string;
	hidden: boolean;
	transformers: ETransformer[];
}

export class ETransformer {
	type: string;
	precision: number;
}

export class EConfigSuggestion {
	configPath: string;
	columnSuggestions: EColumnSuggestion[];
}

export class EColumnSuggestion {
	title: string;
	field: string;
	definition: string;
}
