import { Pipe, PipeTransform } from '@angular/core';
import { NETWORK_TYPE, NETWORK_TYPE_STRING } from '../../models/network';

@Pipe({ name: 'networkType' })
export class NetworkTypePipe implements PipeTransform {
	transform(type: NETWORK_TYPE, isUserDefined: boolean): string {
		return isUserDefined ? this.getNetworkTypeString(type) : 'CMS ACO';
	}

	private getNetworkTypeString(type: NETWORK_TYPE): string {
		switch (type) {
			case NETWORK_TYPE.ACO_MSSP:
				return NETWORK_TYPE_STRING.ACO_MSSP;
			case NETWORK_TYPE.SYSTEM:
				return NETWORK_TYPE_STRING.SYSTEM;
			case NETWORK_TYPE.PHYSICIAN_GROUP:
				return NETWORK_TYPE_STRING.PHYSICIAN_GROUP;
			case NETWORK_TYPE.OWNED:
				return NETWORK_TYPE_STRING.OWNED;
			case NETWORK_TYPE.PARTNERS:
				return NETWORK_TYPE_STRING.PARTNERS;
			case NETWORK_TYPE.PREFERRED:
				return NETWORK_TYPE_STRING.PREFERRED;
			case NETWORK_TYPE.OTHER:
				return NETWORK_TYPE_STRING.OTHER;
			case NETWORK_TYPE.DCE_NEW_ENTRANT:
				return NETWORK_TYPE_STRING.DCE_NEW_ENTRANT;
			case NETWORK_TYPE.DCE_STANDARD:
				return NETWORK_TYPE_STRING.DCE_STANDARD;
			case NETWORK_TYPE.DCE_HIGH_NEEDS:
				return NETWORK_TYPE_STRING.DCE_HIGH_NEEDS;
			case NETWORK_TYPE.PROSPECTIVE_MSSP_ACO:
				return NETWORK_TYPE_STRING.PROSPECTIVE_MSSP_ACO;
			case NETWORK_TYPE.RETROSPECTIVE_MSSP_ACO:
				return NETWORK_TYPE_STRING.RETROSPECTIVE_MSSP_ACO;
			case NETWORK_TYPE.STANDARD_REACH_ACO:
				return NETWORK_TYPE_STRING.STANDARD_REACH_ACO;
			default:
				return null;
		}
	}
}
