import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { TotalCostOfCareType } from '../../shared/models/total-cost-of-care.model';

@Directive({ selector: '[showFilter]' })
export class TotalCostOfCareComponentDirective {
	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

	@Input() set showFilter(facilityType: string) {
		switch (facilityType) {
			case TotalCostOfCareType.INP:
			case TotalCostOfCareType.HHA:
			case TotalCostOfCareType.SNF:
				this.viewContainer.createEmbeddedView(this.templateRef);
				break;
			default:
				this.viewContainer.clear();
		}
	}
}
