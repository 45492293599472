import { Injectable } from '@angular/core';
import { IImpersonatedTrellaUser, TrellaUser } from '../models/user';
import { ApplicationCacheService } from './application-cache.service';
import { NavigationService } from './navigation.service';
import { NetworkAcoService } from './network-aco.service';

@Injectable({
	providedIn: 'root'
})
export class ImpersonationService {
	private _isImpersonationModalOpen = false;
	
	constructor(
		private appCache: ApplicationCacheService,
		private navigationService: NavigationService,
		private networkAcoService: NetworkAcoService
	) {}

	public canImpersonate(): boolean {
		return !!this.getCurrentUser().imitableUsers.length;
	}

	public closeImpersonationModal() {
		this._isImpersonationModalOpen = false;
		this.appCache.setImpersonatedUser(null);
		this.invalidateNetworkOptionsCache();
		this.navigationService.reload();
	}

	public getImitableList(): IImpersonatedTrellaUser[] {
		return [this.getCurrentUser(), ...this.getCurrentUser().imitableUsers];
	}

	public isImpersonationModalOpen() {
		return this._isImpersonationModalOpen;
	}

	public openImpersonationModal() {
		this._isImpersonationModalOpen = true;
	}

	public setImpersonatedUser(id: string) {
		const impersonatedUser = this.getCurrentUser().imitableUsers.find(f => f.id === id);
		this.appCache.setImpersonatedUser(impersonatedUser);
		this.invalidateNetworkOptionsCache();
		this.navigationService.reload();
	}

	private getCurrentUser(): TrellaUser {
		return this.appCache.user;
	}

	private invalidateNetworkOptionsCache() {
		this.networkAcoService.invalidateNetworkOptionsCache();
	}
}
