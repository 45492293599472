// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep trella-grid {
  max-width: 100% !important;
}

.text-disable {
  opacity: 0.75;
}`, "",{"version":3,"sources":["webpack://./src/app/components/grid/mosaic-grid.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACI,aAAA;AACJ","sourcesContent":["::ng-deep trella-grid  {\n  max-width: 100% !important;\n}\n\n.text-disable {\n    opacity: 0.75;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
