import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { NpiType } from '@appcore/enums/npi-type.enum';
import { Locality } from '@appcore/interfaces/locality.interface';

@Injectable({
	providedIn: 'root'
})
export class LocationApi {
	_controllerName = 'Location';
	
	constructor(private api: ApiService) {}

	getCountiesByStates(states: string[]): Observable<Locality[]> {
		return this.api.post(this._controllerName, 'countiesByStates', states);
	}

	getStatesByCompany(): Observable<string[]> {
		return this.api.get(this._controllerName, 'statesByCompany');
	}

	getStatesByProvider(npiType: NpiType): Observable<string[]> {
		return this.api.get(this._controllerName, `statesByProviderType/${npiType}`);
	}

	getStatesByUser(): Observable<string[]> {
		return this.api.get(this._controllerName, 'statesByUser');
	}
}
