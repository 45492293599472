// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insight-wrapper {
  min-width: 350px;
  min-height: 256px;
}

.insight-small-text {
  font-size: 1.2rem;
  min-height: 56px;
}

.info-panel {
  max-width: 500px;
}

.info-text {
  font-size: 1.2rem;
}

.info-wrapper {
  float: right;
  clear: both;
}

.insight-sub-text {
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
}

.insight-top-x-text {
  line-height: 1;
  font-size: 1.5rem;
}

.divider-container .ng-star-inserted:not(:last-child) {
  border-right: solid;
  border-right-color: #ced4da;
  border-right-width: 1px;
}

.insight-large-text {
  font-size: 4rem;
}

.insight-medium-text {
  font-size: 3rem;
}

.border-left {
  border-left: 1px solid;
}

::ng-deep kendo-popup {
  z-index: 2090 !important;
}`, "",{"version":3,"sources":["webpack://./appcore/components/chart/chart-types/shared-insight.scss","webpack://./appcore/components/chart/chart-types/insight/insight.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;ACCJ;;ADEA;EACI,iBAAA;EACA,gBAAA;ACCJ;;ADEA;EACI,gBAAA;ACCJ;;ADEA;EACI,iBAAA;ACCJ;;ADEA;EACI,YAAA;EACA,WAAA;ACCJ;;AAlBA;EACI,eAAA;EACA,2CAAA;AAqBJ;;AAlBA;EACI,cAAA;EACA,iBAAA;AAqBJ;;AAlBA;EACI,mBAAA;EACA,2BAAA;EACA,uBAAA;AAqBJ;;AAlBA;EACI,eAAA;AAqBJ;;AAlBA;EACI,eAAA;AAqBJ;;AAlBA;EACI,sBAAA;AAqBJ;;AAlBA;EACI,wBAAA;AAqBJ","sourcesContent":[".insight-wrapper {\n    min-width: 350px;\n    min-height: 256px; // same as an insight with a header that takes up 2 lines\n}\n\n.insight-small-text {\n    font-size: 1.2rem;\n    min-height: 56px;\n}\n\n.info-panel {\n    max-width: 500px;\n}\n\n.info-text {\n    font-size: 1.2rem;\n}\n\n.info-wrapper {\n    float: right;\n    clear: both;\n}\n","@use '../shared-insight';\n@import '../../../../styles/variables';\n\n.insight-sub-text {\n    font-size: 12px;\n    font-family: 'Roboto Condensed', sans-serif;\n}\n\n.insight-top-x-text {\n    line-height: 1;\n    font-size: 1.5rem;\n}\n\n.divider-container .ng-star-inserted:not(:last-child) {\n    border-right: solid;\n    border-right-color: #ced4da; //$exc-light;\n    border-right-width: 1px;\n}\n\n.insight-large-text {\n    font-size: 4rem;\n}\n\n.insight-medium-text {\n    font-size: 3rem;\n}\n\n.border-left {\n    border-left: 1px solid;\n}\n\n::ng-deep kendo-popup {\n    z-index: $exc-zindex-fg-9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
