import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { isEmpty } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from '../../../node_modules/rxjs/operators';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import { NavigationService } from '../shared/services/navigation.service';
import { EnvService } from  '@appcore/services/env.service';

export interface ExcelHttpResult {
	success: boolean;
	result: any;
	errorMsg: string;
}

export enum HttpVerb {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Patch = 'PATCH',
	Delete = 'DELETE'
}

export enum ResponseContentType {
	arraybuffer = 'arraybuffer',
	blob = 'blob',
	document = 'document',
	json = 'json',
	text = 'text'
}

export interface HttpOptions {
	skipStringify?: boolean;
	headers?: {
		[name: string]: any;
	};
	responseType?: ResponseContentType;
}

interface HttpClientOptions {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	params?:
		| HttpParams
		| {
				[param: string]: string | string[];
		  };
	reportProgress?: boolean;
	withCredentials?: boolean;
	responseType?: any;
}

@Injectable({
	providedIn: 'root'
})
export class HttpRequest {
	private _standardRequestHeaders = [
		{ key: 'Cache-Control', value: 'no-cache' },
		{ key: 'Pragma', value: 'no-cache' },
		{ key: 'Expires', value: 'Sat, 01 Jan 2000 00: 00: 00 GMT' },
		{ key: 'Content-Type', value: 'application/json' }
	];

	private _overrideRequestHeaders = [{ key: 'X-HTTP-Method-Override', value: 'GET' }];

	constructor(
		private appCache: ApplicationCacheService,
		private environmentService: EnvService,
		private http: HttpClient,
		private navigationService: NavigationService
	) {
		this.handleError = this.handleError.bind(this);
	}

	get<T = any>(url: string, getParams?: any, useHttpOverride?: boolean, doNotWrapObservable?: boolean): Observable<T> {
		if (useHttpOverride) {
			// We want to send this in as a POST request wth a body, but let the server know this is actually a get
			return this.getWithHttpOverride(url, getParams, doNotWrapObservable);
		}

		const headers = this.getHeaders();

		if (!getParams || isEmpty(getParams)) {
			return this.wrapObservable(
				this.http.get(url, {
					headers,
					withCredentials: true
				}),
				doNotWrapObservable
			);
		}

		let params: HttpParams = new HttpParams();
		for (const key in getParams) {
			if (getParams.hasOwnProperty(key)) 
				params = params.set(key, getParams[key]);
			
		}
		return this.wrapObservable(
			this.http.get(url, {
				headers,
				params,
				withCredentials: true
			}),
			doNotWrapObservable
		);
	}

	post<T = any>(url: string, postParams?: any, options?: HttpOptions, doNotWrapObservable?: boolean): Observable<T> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.post<T>(url, params, clientOptions), doNotWrapObservable);
	}

	patch(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.patch(url, params, clientOptions));
	}

	put(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.put(url, params, clientOptions));
	}

	delete(url: string, options?: HttpOptions): Observable<any> {
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.delete(url, clientOptions));
	}

	getUrl(baseUrl: string, route: string) {
		let url = this.environmentService.apiUrl;

		if (baseUrl) 
			url += `/${baseUrl}`;
		

		if (route) 
			url += `/${route}`;
		

		return url;
	}

	private getClientOptions(options?: HttpOptions): HttpClientOptions {
		const props: HttpClientOptions = {
			withCredentials: true
		};

		props.headers = this.getHeaders();

		if (options && options.responseType)
			props.responseType = ResponseContentType[options.responseType];


		return props;
	}

	private getHeaders(override = false): HttpHeaders {
		let headers = new HttpHeaders();

		let allHeaders = override ? this._standardRequestHeaders.concat(this._overrideRequestHeaders) : this._standardRequestHeaders;
		if (this.appCache.isImpersonating()) 
			allHeaders = allHeaders.concat([this.getImpersonationHeader()]);
		
		allHeaders.forEach(value => {
			headers = headers.set(value.key, value.value);
		});
		return headers;
	}

	private getImpersonationHeader(): { key; value } {
		const impersonatedUserId = this.appCache.getImpersonatedUser().id;
		return { key: 'Impersonation', value: impersonatedUserId.toString() };
	}

	private getWithHttpOverride(url: string, getParams?: any, doNotWrapObservable?: boolean) {
		const clientOptions = this.getClientOptions();
		clientOptions.headers = this.getHeaders(true);

		return this.wrapObservable(this.http.post(url, getParams, clientOptions), doNotWrapObservable);
	}

	private handleError(error: HttpErrorResponse) {
		if (error?.error?.detail) {
			// 401 is forbidden - navigate to login?
			if (error?.status === 401) {
				if (this.appCache.isImpersonating())
					return throwError(`${error.error.detail} - Impersonation Mode`);

				this.navigationService.navigateToLogin();
			}
			const problemDetails = typeof error.error.detail !== 'object' ? { detail: error.error.detail } : JSON.parse(error.error.detail);
			return throwError(problemDetails?.detail ?? 'An unexpected error has occurred.');
		}

		return throwError(error.message);
	}

	private wrapObservable(observable: Observable<any>, doNothing?: boolean): Observable<any> {
		return doNothing ? observable : observable.pipe(catchError(this.handleError));
	}
}
