import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTabNav } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE_KEY } from 'src/app/shared/shared.constants';
import { DashboardTabModel } from './aco-dashboard.tab.model';
import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';

@Component({
	selector: 'app-dashboard',
	templateUrl: './aco-dashboard.component.html',
	styleUrls: ['./aco-dashboard.component.scss']
})
export class AcoDashboardComponent extends ComponentWithSubscription {
	@ViewChild('tabs', { static: true }) tabGroup: MatTabNav;

	tabSections;
	storageKey = LOCAL_STORAGE_KEY.MOSAIC_ACO_DASHBOARD_TAB;

	constructor(private route: ActivatedRoute) {
		super();
		this.tabSections = DashboardTabModel;
	}
}
