import { ProviderIds } from '@appcore/models/npi/provider-ids.model';

export class NetworkDelta {
	npisToAdd: ProviderIds[];
	npisToRemove: string[];
	clearingAll: boolean;

	constructor() {
		this.npisToAdd = [];
		this.npisToRemove = [];
		this.clearingAll = false;
	}
}

