import { BaseMetric } from './base-metric';

export class MetricSelection {
	changedMetric: BaseMetric;
	currentMetrics: BaseMetric[];
	hasToggledTag: boolean;
	userSearchString: string;

	constructor(currentMetrics: BaseMetric[], userSearchString: string, changedMetric: BaseMetric = null, hasToggledTag: boolean = null) {
		this.changedMetric = changedMetric;
		this.currentMetrics = currentMetrics;
		this.hasToggledTag = hasToggledTag;
		this.userSearchString = userSearchString;
	}
}
