import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CrmLogoutComponent } from './crmLogout/crmlogout.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginGuard } from './shared/guards/login.guard';
import { ROUTE_CONSTANTS } from './shared/shared.constants';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const winAny: any = window;
const useLegacyLogin = winAny.__env && winAny.__env.cognito_only === false;

export const AppRootRoutes = [
	{ path: ROUTE_CONSTANTS.login.route, component: LoginComponent, canActivate: [LoginGuard] },
	{ path: ROUTE_CONSTANTS.login.route + '/:source', component: LoginComponent, canActivate: [AuthGuard] },
	{ path: ROUTE_CONSTANTS.logout.route, component: LogoutComponent },
	{ path: ROUTE_CONSTANTS.crmlogout.route, component: CrmLogoutComponent },
	{ path: ROUTE_CONSTANTS.landing.route, component: LandingComponent },
	{ path: ROUTE_CONSTANTS.unauthorized.route, component: UnauthorizedComponent },
	{ path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
	{ path: '**', component: PageNotFoundComponent }
];
@NgModule({
	imports: [RouterModule.forRoot(AppRootRoutes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
