// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
@import 'https://code.highcharts.com/css/highcharts.css';
*/
/* .sunburst-legend {
	width: 250px;
} */
.legend-row {
  /* TODO is this used any longer?  legend is in APP? */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
}

.legend-box {
  height: 25px;
  width: 25px;
}

.legend-text {
  margin: 0.5rem;
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./appcore/components/chart/chart-types/sunburst-chart/sunburst-chart.component.scss"],"names":[],"mappings":"AAAA;;CAAA;AAIA;;GAAA;AAIA;EACI,qDAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;AADJ;;AAIA;EACI,YAAA;EACA,WAAA;AADJ;;AAIA;EACI,cAAA;EACA,iBAAA;AADJ","sourcesContent":["/*\n@import 'https://code.highcharts.com/css/highcharts.css';\n*/\n\n/* .sunburst-legend {\n\twidth: 250px;\n} */\n\n.legend-row {\n    /* TODO is this used any longer?  legend is in APP? */\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin: 0.5rem;\n}\n\n.legend-box {\n    height: 25px;\n    width: 25px;\n}\n\n.legend-text {\n    margin: 0.5rem;\n    font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
