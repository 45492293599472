export * from './aco-dashboard.component';
export * from './aco-growth/aco-growth.component';
export * from './aco-quality/aco-quality.component';
export * from './aco-top-metrics/aco-top-metrics.component';
export * from '../../components/aco-tile-grid/aco-tile/aco-tile.component';
export * from '../../components/aco-pmpm/aco-pmpm.component';
export * from '../../components/aco-pmpm/aco-pmpm.component';

import { AcoDashboardComponent } from './aco-dashboard.component';
import { AcoGrowthComponent } from './aco-growth/aco-growth.component';
import { AcoQualityComponent } from './aco-quality/aco-quality.component';
import { AcoTopMetricsComponent } from './aco-top-metrics/aco-top-metrics.component';
import { AcoDetailsComponent } from './aco-details/aco-details.component';

export const ACO_DASHBOARD_COMPONENTS = [
	AcoDashboardComponent,
	AcoTopMetricsComponent,
	AcoGrowthComponent,
	AcoQualityComponent,
	AcoDetailsComponent
];

export const ACO_DASHBOARD_DIRECTIVES = [];
