import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NetworkApi } from 'src/app/api/network.api';
import { Growth } from '../models/growth/growth';
import { GrowthRequest } from '../models/growth/growth-request';
import { NetworkDelta } from '../models/growth/network-delta';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { ProviderIds } from '@appcore/models/npi/provider-ids.model';

@Injectable({
	providedIn: 'root'
})
export class ModelNetworkService {
	public readonly canSave: Observable<boolean>;
	public readonly growthMetrics: Observable<Growth>;
	public readonly isGrowthModelOpen: Observable<boolean>;
	public readonly openWarning: Observable<boolean>;
	public readonly closeWarning: Observable<boolean>;
	public readonly openResetWarning: Observable<boolean>;
	public readonly updateShowSelected: Observable<void>;
	public readonly isGrowthLoading: Observable<boolean>;

	private _canSave: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _growthMetrics: BehaviorSubject<Growth> = new BehaviorSubject(new Growth());
	private _isGrowthModelOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _openWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _closeWarning: Subject<boolean> = new Subject();
	private _openResetWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _updateShowSelected: Subject<void> = new Subject();
	private _isGrowthLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _selectedNetwork: number;
	private _growth: Growth = new Growth();
	private _npisToAdd: ProviderIds[] = [];
	private _npisToRemove: string[] = [];
	private _clearingAll = false;
	
	constructor(private networkApi: NetworkApi, private userFeedbackService: UserFeedbackService) {
		this.canSave = this._canSave.asObservable();
		this.growthMetrics = this._growthMetrics.asObservable();
		this.isGrowthModelOpen = this._isGrowthModelOpen.asObservable();
		this.openWarning = this._openWarning.asObservable();
		this.closeWarning = this._closeWarning.asObservable();
		this.openResetWarning = this._openResetWarning.asObservable();
		this.updateShowSelected = this._updateShowSelected.asObservable();
		this.isGrowthLoading = this._isGrowthLoading.asObservable();
	}

	public isGrowthOpen(): boolean {
		return this._isGrowthModelOpen.value;
	}

	public getSavedGrowthMetrics(growthRequest: GrowthRequest) {
		this._isGrowthLoading.next(true);
		this.networkApi.getGrowth(growthRequest).subscribe(response => {
			this._growth.saved = response;
			this._growthMetrics.next(this._growth);
			this._isGrowthLoading.next(false);
		},
		error => {
			this._isGrowthLoading.next(false);
			this.userFeedbackService.showUnexpectedError();
		});
	}

	public clearSavedGrowthMetrics() {
		this._growth = new Growth();
		this._npisToAdd = [];
		this._npisToRemove = [];
		this._clearingAll = false;
		this._canSave.next(false);
	}

	public toggleModeling() {
		this._isGrowthModelOpen.next(!this._isGrowthModelOpen.value);
	}

	public closeModeling() {
		this._isGrowthModelOpen.next(false);
	}

	public setNetwork(network: number) {
		this._selectedNetwork = network;
	}

	public getNetwork() {
		if (!this._isGrowthModelOpen.value) 
			return undefined;
		
		return this._selectedNetwork;
	}

	public addNpis(npis: ProviderIds[]) {
		npis.forEach(npi => {
			if (this._npisToRemove.some(s => s === npi.npiId)) 
				this._npisToRemove = this._npisToRemove.filter(f => f !== npi.npiId);
			 else if (!this._npisToAdd.some(s => s.npiId === npi.npiId)) 
				this._npisToAdd.push(npi);
			
		});
		this.getUnsavedGrowthMetrics();
		this.checkCanSave();
	}

	public removeNpis(npis: string[]) {
		npis.forEach(npiId => {
			if (this._npisToAdd.some(s => s.npiId === npiId)) 
				this._npisToAdd = this._npisToAdd.filter(f => f.npiId !== npiId);
			 else if (!this._npisToRemove.some(s => s === npiId)) 
				this._npisToRemove.push(npiId);
			
		});
		this.getUnsavedGrowthMetrics();
		this.checkCanSave();
	}

	public save() {
		const growthRequest = new GrowthRequest();
		growthRequest.networkDelta.npisToAdd = this._npisToAdd;
		growthRequest.networkDelta.npisToRemove = this._npisToRemove;
		growthRequest.networkDelta.clearingAll = this._clearingAll;
		growthRequest.networkId = this._selectedNetwork;
		this._isGrowthLoading.next(true);
		this.networkApi.saveGrowth(growthRequest).subscribe(
			response => {
				this._npisToAdd = [];
				this._npisToRemove = [];
				this._clearingAll = false;
				this._growth.unsaved = response;
				this._growth.saved = response;
				this._growthMetrics.next(this._growth);
				this.userFeedbackService.showSaveSucess('Model Network');
				this.checkCanSave();
				this.userFeedbackService.stopSpinner();
				this._isGrowthLoading.next(false);
			},
			error => {
				this._isGrowthLoading.next(false);
				if (error) 
					this.userFeedbackService.showWarning(error);
				 else 
					this.userFeedbackService.showUnexpectedError();
				
			}
		);
	}

	public setClearAll(clearingAll: boolean) {
		this._clearingAll = clearingAll;
		if (clearingAll) {
			this._npisToRemove = [];
			this._npisToAdd = [];
		}
		if (this._isGrowthModelOpen.value) {
			this.getUnsavedGrowthMetrics();
			this.checkCanSave();
		}
	}

	public reset() {
		this._growth.unsaved = this._growth.saved;
		this._npisToAdd = [];
		this._npisToRemove = [];
		this._clearingAll = false;
		this._growthMetrics.next(this._growth);
		this.checkCanSave();
	}

	public openPendingChangesWarning() {
		this._openWarning.next(true);
	}

	public canNavigateFromPendingChangesWarning(canNavigate: boolean) {
		this._closeWarning.next(canNavigate);
	}

	public openResetChangesWarning(shouldOpen: boolean) {
		this._openResetWarning.next(shouldOpen);
	}

	public applyDeltaToSelections(selections: string[]): string[] {
		let filteredSelections = selections;
		if (this._clearingAll) 
			filteredSelections = [];
		
		if (this._npisToAdd.length) {
			const npisToAdd = this._npisToAdd.map(m => m.npiId);
			filteredSelections = filteredSelections.concat(npisToAdd);
		}
		if (this._npisToRemove.length) 
			filteredSelections = filteredSelections.filter(s => !this._npisToRemove.includes(s));
		

		return filteredSelections;
	}

	public getSelectedCount(): number {
		return this._growth.unsaved?.providerCount ?? this._growth.saved?.providerCount ?? 0;
	}
	
	public getNetworkDelta(): NetworkDelta {
		const networkDelta = new NetworkDelta();
		networkDelta.npisToAdd = this._npisToAdd;
		networkDelta.npisToRemove = this._npisToRemove;
		networkDelta.clearingAll = this._clearingAll;
		return networkDelta;
	}
	
	private checkCanSave() {
		this._canSave.next(!!this._npisToAdd.length || !!this._npisToRemove.length || this._clearingAll);
	}

	private getUnsavedGrowthMetrics() {
		const growthRequest = new GrowthRequest();
		growthRequest.networkDelta.npisToAdd = this._npisToAdd;
		growthRequest.networkDelta.npisToRemove = this._npisToRemove;
		growthRequest.networkDelta.clearingAll = this._clearingAll;
		growthRequest.networkId = this._selectedNetwork;

		this._isGrowthLoading.next(true);
		this.networkApi.getGrowth(growthRequest).subscribe(response => {
			this._growth.unsaved = response;
			const providerCount = this._clearingAll
				? response.providerCount
				: this._growth.unsaved.providerCount + (this._npisToAdd.length - this._npisToRemove.length);
			this._growth.unsaved.providerCount = providerCount;
			this._growthMetrics.next(this._growth);
			this._updateShowSelected.next(null);
			this._isGrowthLoading.next(false);
		},
		error => {
			this._isGrowthLoading.next(false);
			this.userFeedbackService.showUnexpectedError();
		});
	}
}
