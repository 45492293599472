import { DisplayComponentType } from 'src/app/main/page-model/page-model';
import { DataDisplayComponent } from 'src/app/shared/models/display';

const ACO_PARTICIPATING_PROVIDERS_CHARTS: DataDisplayComponent[] = [

];

const ACO_PARTICIPATING_PROVIDERS_GRIDS: DataDisplayComponent[] = [
	{
		configNames: ['aco_provider_roster'],
		providerType: 'aco',
		type: DisplayComponentType.Grid
	}
];

export const ACO_PARTICIPATING_PROVIDERS = {
	title: 'Participating Providers',
	id: 'ParticipatingProviders',
	configuration: {
		TOOLBARS: [],
		CHART_VIEW: [...ACO_PARTICIPATING_PROVIDERS_CHARTS],
		GRID_VIEW: [...ACO_PARTICIPATING_PROVIDERS_GRIDS]
	}
};
