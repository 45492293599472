import { ROUTE_CONSTANTS } from './shared.constants';

import { NpiTypeConfigs } from './models/npi-type-configs';
import { MedicalEntityTypeConfigs } from './models/medical-entity-type-configs';
import { TotalCostOfCareType } from './models/total-cost-of-care.model';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	MedicalEntityType
} from '@appcore/enums/medical-entity-type.enum';
import { NpiTypeConfig } from '@appcore/models/npi/npi-type-config.model';
import { SearchType } from '@appcore/enums/search-type.enum';
import { MedicalEntityTypeConfig } from '@appcore/models/medical-entity-type-config.model';

export default class StrategyUtils {
	static getNpiTypeConfigByTccType(type: TotalCostOfCareType): NpiTypeConfig {
		switch (type) {
			case TotalCostOfCareType.PHYS:
			case TotalCostOfCareType.PHYS_MORT:
				return NpiTypeConfigs.physician;
			case TotalCostOfCareType.HHA:
				return NpiTypeConfigs.homehealth;
			case TotalCostOfCareType.INP:
				return NpiTypeConfigs.hospital;
			case TotalCostOfCareType.SNF:
				return NpiTypeConfigs.skilledNursing;
			default:
				return null;
		}
	}

	static getSearchTypeByTccType(type: TotalCostOfCareType): SearchType {
		switch (type) {
			case TotalCostOfCareType.PHYS:
			case TotalCostOfCareType.PHYS_MORT:
				return SearchType.PhysApp;
			case TotalCostOfCareType.HHA:
				return SearchType.Hha;
			case TotalCostOfCareType.INP:
				return SearchType.Hosp;
			case TotalCostOfCareType.SNF:
				return SearchType.Snf;
			default:
				return null;
		}
	}

	static getProviderTypeConfigBySearchType(type: SearchType): NpiTypeConfig | MedicalEntityTypeConfig {
		switch (type) {
			case SearchType.PhysApp:
			case SearchType.Othp:
			case SearchType.OthpMos:
				return NpiTypeConfigs.physician;
			case SearchType.Hha:
				return NpiTypeConfigs.homehealth;
			case SearchType.Hos:
				return NpiTypeConfigs.hospice;
			case SearchType.Snf:
				return NpiTypeConfigs.skilledNursing;
			case SearchType.Hosp:
			case SearchType.Othf:
				return NpiTypeConfigs.hospital;
			case SearchType.Aco:
				return MedicalEntityTypeConfigs.aco;
			default:
				return null;
		}
	}

	static getSearchTypeByNpiType(type: NpiType): SearchType {
		switch (type) {
			case NpiType.physician:
				return SearchType.PhysApp;
			case NpiType.otherPhysician:
				return SearchType.Othp;
			case NpiType.otherPhysicianMosaic:
				return SearchType.OthpMos;
			case NpiType.homeHealthAgency:
				return SearchType.Hha;
			case NpiType.hospice:
				return SearchType.Hos;
			case NpiType.skilledNursingFacility:
				return SearchType.Snf;
			case NpiType.hospital:
				return SearchType.Hosp;
			case NpiType.otherFacility:
				return SearchType.Othf;
			default:
				return null;
		}
	}

	static getProviderTypeBySearchType(type: SearchType): NpiType | MedicalEntityType {
		switch (type) {
			case SearchType.PhysApp:
				return NpiType.physician;
			case SearchType.Othp:
				return NpiType.otherPhysician;
			case SearchType.OthpMos:
				return NpiType.otherPhysicianMosaic;
			case SearchType.Hha:
				return NpiType.homeHealthAgency;
			case SearchType.Hos:
				return NpiType.hospice;
			case SearchType.Snf:
				return NpiType.skilledNursingFacility;
			case SearchType.Hosp:
				return NpiType.hospital;
			case SearchType.Othf:
				return NpiType.otherFacility;
			case SearchType.Aco:
				return MedicalEntityType.aco;
			default:
				return null;
		}
	}

	static getRouteAttrByNpiType(type: NpiType) {
		switch (type) {
			case NpiType.physician:
			case NpiType.otherPhysician:
			case NpiType.otherPhysicianMosaic:
				return ROUTE_CONSTANTS.physician;
			case NpiType.homeHealthAgency:
				return ROUTE_CONSTANTS.homehealth;
			case NpiType.hospice:
				return ROUTE_CONSTANTS.hospice;
			case NpiType.skilledNursingFacility:
				return ROUTE_CONSTANTS.skilledNursing;
			case NpiType.hospital:
			case NpiType.otherFacility:
				return ROUTE_CONSTANTS.hospital;
			default:
				return null;
		}
	}

	static getRouteAttrByMedicalEntityType(type: MedicalEntityType) {
		switch (type) {
			case MedicalEntityType.aco:
				return ROUTE_CONSTANTS.aco;
			default:
				return null;
		}
	}

	static getProviderTypeConfig(providerType: NpiType | MedicalEntityType): NpiTypeConfig | MedicalEntityTypeConfig {
		switch (providerType) {
			case NpiType.physician:
			case NpiType.otherPhysician:
			case NpiType.otherPhysicianMosaic:
				return NpiTypeConfigs.physician;
			case NpiType.physicianGroup:
				return NpiTypeConfigs.physiciangroup;
			case NpiType.homeHealthAgency:
				return NpiTypeConfigs.homehealth;
			case NpiType.hospice:
				return NpiTypeConfigs.hospice;
			case NpiType.skilledNursingFacility:
				return NpiTypeConfigs.skilledNursing;
			case NpiType.hospital:
			case NpiType.otherFacility:
				return NpiTypeConfigs.hospital;
			case MedicalEntityType.aco:
				return MedicalEntityTypeConfigs.aco;
			default:
				throw Error(`Cannot find NpiTypeConfig / MedicalEntityTypeConfig: ${providerType}`);
		}
	}

	static getMedicalEntityTypeConfig(type: NpiType | MedicalEntityType): NpiTypeConfig | MedicalEntityTypeConfig {
		switch (type) {
			case NpiType.physician:
				return NpiTypeConfigs.physician;
			case NpiType.homeHealthAgency:
				return NpiTypeConfigs.homehealth;
			case NpiType.hospice:
				return NpiTypeConfigs.hospice;
			case NpiType.skilledNursingFacility:
				return NpiTypeConfigs.skilledNursing;
			case NpiType.hospital:
				return NpiTypeConfigs.hospital;
			case MedicalEntityType.aco:
				return MedicalEntityTypeConfigs.aco;
			default:
				throw Error(`Cannot find config: ${type}`);
		}
	}

	static isNpiType(type) {
		return Object.values(NpiType).includes(type);
	}
}
