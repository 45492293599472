import { Component } from '@angular/core';

@Component({
	selector: '[mosaic-not-found]',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class MosaicNotFoundComponent {
	constructor() {}
}
