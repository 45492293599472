import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { NpiStatesRequest } from '../shared/models/npi-states-request';
import { NpisRequest } from '../shared/models/npis-request';
import { NpiSearchRequest } from '../shared/models/search/npi-search-request';
import { NpiSearchResponse } from '../shared/models/search/npi-search-response';
import { TccSearchResponse } from '../shared/models/search/tcc-search-response';
import { ApiService } from './api.service';
import { Npi } from '@appcore/models/npi/npi.model';
import { AcoNpi } from '@appcore/models/npi/aco-npi.model';

@Injectable({
	providedIn: 'root'
})
export class NpiApi {
	_controllerName = 'Npi';

	constructor(private api: ApiService) {}

	getNpiInfo(npi: string): Observable<Npi> {
		return this.api.get(this._controllerName, npi);
	}

	getNpiInfosByProviderTypeAndNpis(request: NpisRequest): Observable<Npi[]> {
		return this.api.post(this._controllerName, 'GetNpiInfosByProviderTypeAndNpis', request);
	}

	getNpiInfosByProviderTypeAndStates(request: NpiStatesRequest): Observable<Npi[]> {
		return this.api.post(this._controllerName, 'GetNpiInfosByProviderTypeAndStates', request);
	}

	getCmsAcoNpiInfo(aco: string): Observable<AcoNpi[]> {
		return this.api.get(this._controllerName, `cmsAco/${aco}`);
	}

	getUserAcoNpiInfo(aco: string): Observable<AcoNpi[]> {
		return this.api.get(this._controllerName, `userAco/${aco}`);
	}

	search(query: string): Observable<NpiSearchResponse[]> {
		return this.api.get<NpiSearchResponse[]>(this._controllerName, `search?search=${query}`, null, null, true);
	}

	searchTcc(params: NpiSearchRequest): Observable<TccSearchResponse[]> {
		const encodedParams = ApiService.encodeQueryParam(params);
		return this.api.get('Npi', `search-tcc?${encodedParams}`);
	}
}

