import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class SpecialtiesApi {
    _controllerName = 'Specialty';

    constructor(private api: ApiService) {}

    getTccSpecialties() : Observable<string[]> {
        return this.api.get(this._controllerName, 'total-cost-of-care');
    }
}