import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetchAllFilters, setAllFilters } from './explore.actions';
import { FilterRulesApi } from 'src/app/api/filter-rules.api';
import { NetworkApi, NetworkResponse } from 'src/app/api/network.api';
import { mergeMap, map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AcoApi } from '../../api/aco.api';
import {
	Selection
} from '@appcore/interfaces/selection.interface';
import {
	CheckedSelection
} from '@appcore/interfaces/checked-selection.interface';

@Injectable()
export class ExploreEffects {
	loadConfigs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchAllFilters),
			mergeMap(action =>
				forkJoin([
					this.filterRulesApi.getRules(action.filterTypes, action.providerType),
					this.networkApi.getNames(action.primaryProviderType),
					this.acoApi.getAcos()
				]).pipe(
					map(filters => {
						const networks = filters[1].map(this.getSelectableOption);
						const acos = filters[2];

						return setAllFilters({
							acos,
							...filters[0],
							networks,
							providerType: action.providerType
						});
					})
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private filterRulesApi: FilterRulesApi,
		private networkApi: NetworkApi,
		private acoApi: AcoApi
	) {}

	getSelectableOption(response: NetworkResponse): Selection {
		return {
			id: response.id,
			display: response.name
		};
	}

	getSelectableOptionForYears(response: number): Selection {
		const year = response.toString();
		return {
			id: year,
			display: year
		};
	}

	sortNetwork(a: CheckedSelection, b: CheckedSelection) {
		return a.display.localeCompare(b.display, 'en', { 'sensitivity': 'base' });
	}
}
