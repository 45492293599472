import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Tile } from '../shared/models/tile.model';
import { PAGE } from '../shared/enums/page.enum';
import { map } from 'rxjs/operators';
import { ReplaceValueMappingService } from '@appcore/services/replacement-value-mapping.service';


@Injectable({
	providedIn: 'root'
})
export class TileApi {
	_controllerName = 'Tile';

	constructor(private api: ApiService, private replacementValueService: ReplaceValueMappingService) { }

	/**
	 * Gets all tiles available to the user based on a CMS ACO
	 */
	getTilesByCmsAco(pageId: PAGE, acoId: string, acoPeriodId: number): Observable<Tile[]> {
		const route = `${this._controllerName}/GetTilesByCmsAco`;
		return this.api.get(route, pageId.toString(), { acoId, acoPeriodId }).pipe(
			map(tiles => {
				tiles = tiles.map(t => this.handleTextReplacements(t));
				return tiles;
			})
		);
	}

	/**
	 * Gets all tiles available to the user based on a user ACO
	 */
	getTilesByNetwork(pageId: PAGE, networkId: number, acoPeriodId: number): Observable<Tile[]> {
		const route = `${this._controllerName}/GetTilesByUserAco`;
		return this.api.get(route, `${acoPeriodId.toString()}/${pageId.toString()}`, {
			networkId
		});
	}

	private handleTextReplacements(tile: Tile): Tile {
		tile.infoText = this.replacementValueService.runReplacement(tile.infoText);
		return tile;
	}
}
