import { Injectable } from '@angular/core';
import { ApplicationCacheService } from './application-cache.service';
import { TotalCostSectionOptions } from '../../components/total-cost-of-care/total-cost-section-options';
import { TotalCostOfCareType } from '../models/total-cost-of-care.model';

@Injectable({
	providedIn: 'root'
})
export class StrategizeCostService {
	STORAGE_KEY = 'STRATEGIZE_CONFIG';
	constructor(private appCache: ApplicationCacheService) {}

	getConfig(facilityType: TotalCostOfCareType): TotalCostSectionOptions {
		const map = this.getMap();

		return map[this.getInnerKey(facilityType)];
	}

	setConfig(config: TotalCostSectionOptions) {
		const map = this.getMap();

		map[this.getInnerKey(config.totalCostOfCareType)] = config;
		localStorage.setItem(this.STORAGE_KEY, JSON.stringify(map));
	}

	private getMap(): StrategizeCostMap {
		return JSON.parse(localStorage.getItem(this.STORAGE_KEY)) || {};
	}

	private getInnerKey(facilityType: TotalCostOfCareType): string {
		return this.appCache.userSpecificKey(facilityType);
	}
}

interface StrategizeCostMap {
	[propName: string]: TotalCostSectionOptions;
}
