import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AcoApi } from 'src/app/api/aco.api';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import {
	FILTER_TYPE
} from '@appcore/enums/filter-type.enum';
import { NpiSearchResult } from '@appcore/models/npi/npi-search-result.model';
import { OnChangeAfterReady } from '@appcore/helpers/decorators/onchange-decorator';
import { ACO_FLAG_DEFAULT_VALUE, FILTER_CATEGORY } from '@appcore/constants/constants';

@Component({
	selector: 'mosaic-journey-aco-options',
	templateUrl: './journey-aco-options.component.html'
})
export class JourneyAcoOptionsComponent implements OnInit {
	@Input()
	filterAddressState: string;
	@OnChangeAfterReady<any>(function (value) {
		// requires this.ready
		this.onSelectedNpiChange();
	})
	@Input()
	selectedNpi: NpiSearchResult;
	@Output() filterOptionsEvent = new EventEmitter<string>();

	filterCategory = FILTER_CATEGORY;
	optionAcoAll = {
		display: 'Any ACO',
		value: ACO_FLAG_DEFAULT_VALUE,
		category: { value: FILTER_CATEGORY.ACO_FLAG.value }
	};
	optionList = [];
	selectedFilter: any = this.optionAcoAll;
	filters;
	ready = false;

	constructor(private acoApi: AcoApi, private userFeedbackService: UserFeedbackService) {}

	ngOnInit(): void {
		this.selectedFilter = this.optionAcoAll;
		this.filters = this.getFilters();
		this.getAcoList();
		this.ready = true;
	}

	onSelectedNpiChange() {
		this.getAcoList();
	}

	getAcoList() {
		this.acoApi.getAcoOptions(this.selectedNpi.npi, this.filterAddressState).subscribe(info => {
			this.optionList = info.map(x => ({
				display: `${x.id}: ${x.name} (${x.patientCount} Patients)`,
				value: x.id,
				category: FILTER_CATEGORY.ACO_ID
			}));
			// Verify that the selectedFilter is included in the optionList
			// If not, we need to warn the user and default back to Any ACO
			if (!this.isSelectedAnyAco() && !info.some(x => x.id === this.selectedFilter.value)) {
				this.userFeedbackService.showWarning(
					`Selected ACO does not operate in ${this.filterAddressState}. Selection has been reset to Any ACO.`
				);
				this.selectedFilter = this.optionAcoAll;
				this.onFilterChange();
			}
		});
	}

	acoFilterCompare(rec1: any, rec2: any): boolean {
		return rec1 && rec2 && rec1.category.value === rec2.category.value && rec1.value === rec2.value;
	}

	isSelectedAnyAco() {
		return this.selectedFilter.category.value === FILTER_CATEGORY.ACO_FLAG.value;
	}

	onFilterChange() {
		this.filters = this.getFilters();
		this.filterOptionsEvent.emit(this.filters);
	}

	getFilters() {
		const categoryFilters = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'category1HC',
				modelJson: this.selectedFilter.category.value
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'category2HC',
				modelJson: ''
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'group1HC',
				modelJson: this.selectedFilter.value || this.selectedFilter.id
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'group2HC',
				modelJson: ''
			},
			{
				filterType: FILTER_TYPE.CARE_PATH_TYPE,
				modelJson: 'aco'
			}
		];

		return categoryFilters;
	}
}
