// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-none {
  color: #AAA;
  font-style: italic;
}

#networkFilter option:disabled {
  color: #AAA;
  background: #EEE;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-network/select-network.component.scss"],"names":[],"mappings":"AAAA;EAAe,WAAA;EAAY,kBAAA;AAG3B;;AADA;EACI,WAAA;EACA,gBAAA;AAIJ","sourcesContent":[".option-none { color:#AAA; font-style: italic; }\n\n#networkFilter option:disabled {\n    color: #AAA;\n    background: #EEE;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
