import { Component, OnInit } from '@angular/core';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';


@Component({
	selector: 'trella-aco-quality',
	templateUrl: './aco-quality.component.html',
	styleUrls: ['./aco-quality.component.scss']
})
export class AcoQualityComponent extends ComponentWithSubscription {
	constructor() {
		super();
	}
}
