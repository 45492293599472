import { Component, OnInit } from '@angular/core';

import { IImpersonatedTrellaUser, TrellaUser } from 'src/app/shared/models/user';
import { ApplicationCacheService } from 'src/app/shared/services/application-cache.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ImpersonationService } from 'src/app/shared/services/impersonation.service';
import {
	CheckedSelection
} from '@appcore/interfaces/checked-selection.interface';

@Component({
	selector: 'mosaic-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
	impersonatorIcon: string;
	impersonatorIconColor: string;
	isOpen = false;
	listOptions: CheckedSelection[] = [];

	constructor(
		private authenticationService: AuthenticationService,
		private appCache: ApplicationCacheService,
		private impersonationService: ImpersonationService
	) {}

	get canImpersonate(): boolean {
		return this.impersonationService.canImpersonate();
	}

	get impersonatedUser(): string {
		return this.formatDisplayName(this.appCache.getImpersonatedUser());
	}

	get isImpersonating(): boolean {
		return this.appCache.isImpersonating();
	}

	get isImpersonationModalOpen(): boolean {
		return this.impersonationService.isImpersonationModalOpen();
	}

	ngOnInit() {
		if (this.isImpersonating) {
			this.setImpersonatorIcon();
			this.setImpersonatorIconColor();
		}
		if (this.impersonationService.isImpersonationModalOpen()) 
			this.listOptions = this.getImitableList();
		
	}

	toggle() {
		this.isOpen = !this.isOpen;
	}

	handleSelectionsChange(selection: CheckedSelection) {
		this.impersonationService.setImpersonatedUser(selection.id);
	}

	closeImpersonationModal() {
		this.impersonationService.closeImpersonationModal();
		this.listOptions.forEach(e => (e.checked = false));
	}

	openImpersonationModal() {
		this.listOptions = this.getImitableList();
		this.impersonationService.openImpersonationModal();
	}

	logout() {
		this.authenticationService.logout();
	}

	private formatDisplayNameWithEmail(user: TrellaUser | IImpersonatedTrellaUser) {
		return `${this.formatDisplayName(user)} (${user.companyName ?? ''})`;
	}

	private formatDisplayName(user: TrellaUser | IImpersonatedTrellaUser) {
		return `${user.firstName} ${user.lastName}`;
	}

	private setImpersonatorIcon() {
		const icons = ['fa-user-secret', 'fa-user-astronaut', 'fa-user-cowboy', 'fa-user-alien'];
		this.impersonatorIcon = icons[Math.floor(Math.random() * icons.length)];
	}

	private setImpersonatorIconColor() {
		const colors = ['user-color-1', 'user-color-2', 'user-color-3'];
		this.impersonatorIconColor = colors[Math.floor(Math.random() * colors.length)];
	}

	private getImitableList(): CheckedSelection[] {
		const users = this.impersonationService.getImitableList();
		return users.map(x => {
			const selection: CheckedSelection = {
				id: x.id,
				display: this.formatDisplayNameWithEmail(x),
				checked: this.appCache.getImpersonatedUser().id === x.id
			};
			return selection;
		});
	}
}
