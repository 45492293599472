import { Injectable } from '@angular/core';
import { IImpersonatedTrellaUser, TrellaUser } from '../models/user';
import { LOCAL_STORAGE_KEY, PORTAL_TYPE_MOSAIC } from '../shared.constants';
import { Locality } from '@appcore/interfaces/locality.interface';
import { CLASSIFICATION_TYPE } from '@appcore/enums/classification_type.enum';
import { PAGE_TYPE } from '@appcore/enums/page-type.enum';

@Injectable({
	providedIn: 'root'
})
export class ApplicationCacheService {
	portal: PORTAL_TYPE_MOSAIC;
	page: PAGE_TYPE;
	user: TrellaUser = null;
	defaultClassificationType: CLASSIFICATION_TYPE;
	private _impersonatedUser: IImpersonatedTrellaUser;

	constructor() {
		this.portal = PORTAL_TYPE_MOSAIC.HHA;
	}

	get userId(): string {
		return this.user && this.user.id;
	}

	public setUser(user: TrellaUser) {
		this.user = user;
	}

	public userSpecificKey(key: string, user = this.userId): string {
		if (!key) 
			return undefined;
		
		return `${user}_${key}`;
	}

	public setReturnUrl(url: string) {
		if (url) 
			localStorage.setItem(LOCAL_STORAGE_KEY.RETURN_URL, url);
		 else 
			localStorage.removeItem(LOCAL_STORAGE_KEY.RETURN_URL);
		
	}

	public getReturnUrl() {
		return localStorage.getItem(LOCAL_STORAGE_KEY.RETURN_URL);
	}

	public setImpersonatedUser(user: IImpersonatedTrellaUser) {
		this._impersonatedUser = user;
	}

	public getImpersonatedUser(): IImpersonatedTrellaUser {
		return this._impersonatedUser ?? this.user;
	}

	public isImpersonating(): boolean {
		return !!this._impersonatedUser;
	}

	public getMyLocation(): Locality[] {
		return this.user.myLocations;
	}

	public setMyLocation(location: Locality[]): void {
		this.user.myLocations = location;
	}
}
