export class SharedConfigurationFields {
    permissionView: string;
    permissionExport: string;
    permissionRemove: string;
    showExpandDialog: boolean;
    addToClipboard: boolean;
    allowEButton: boolean;
    className: string;
    hide: boolean;
}
