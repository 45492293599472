import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { VIEW_TYPE, VIEW_TYPE_STRING } from '../../enums/view-type.enum';
@Pipe({
  name: 'viewType'
})
@Injectable({providedIn: 'root'})
export class ViewTypePipe implements PipeTransform {

  transform(type: VIEW_TYPE): string {
    return this.getViewTypeString(type);
  }

  private getViewTypeString(type: VIEW_TYPE): string {
    switch (type) {
			case VIEW_TYPE.Dce:
				return VIEW_TYPE_STRING.Dce;
			case VIEW_TYPE.DiagnosticRelatedGroups:
				return VIEW_TYPE_STRING.DiagnosticRelatedGroups;
			case VIEW_TYPE.IndependentPac:
				return VIEW_TYPE_STRING.IndependentPac;
			case VIEW_TYPE.MsspAco:
				return VIEW_TYPE_STRING.MsspAco;
			case VIEW_TYPE.MsspAndDceOrganization:
				return VIEW_TYPE_STRING.MsspAndDceOrganization;
			case VIEW_TYPE.ReadmissionsAndDischarges:
				return VIEW_TYPE_STRING.ReadmissionsAndDischarges;
			default:
				return null;
		}
	}

}
