import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { NpiTypeConfigs } from 'src/app/shared/models/npi-type-configs';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	NpiTypeConfig
} from '@appcore/models/npi/npi-type-config.model';

@Component({
	selector: 'mosaic-npi-type-selector',
	templateUrl: './npi-type-selector.component.html',
	styleUrls: ['./npi-type-selector.component.scss']
})
export class NpiTypeSelectorComponent extends ComponentWithSubscription implements OnInit {
	@Input() npiTypeOptions: NpiTypeConfig[];
	
	@Output() selectedNpiType: Observable<NpiTypeConfig>;
	
	currentSelectedNpiType: NpiTypeConfig;
	showNpiTypeSelector: boolean;
	defaultOption = NpiTypeConfigs.physician;
	
	private _selectedNpiType: EventEmitter<NpiTypeConfig> = new EventEmitter<NpiTypeConfig>();

	constructor() {
		super();
		this.selectedNpiType = this._selectedNpiType.asObservable();
	}

	ngOnInit(): void {
		this.currentSelectedNpiType = this.npiTypeOptions.find(x => x === this.defaultOption);
	}

	toggleNpiSelector() {
		this.showNpiTypeSelector = !this.showNpiTypeSelector;
	}

	handleSelectNpiTypeEvent(npiType: NpiTypeConfig) {
		this.showNpiTypeSelector = false;
		this.currentSelectedNpiType = this.npiTypeOptions.find(x => x === npiType);
		this._selectedNpiType.emit(npiType);
	}
}
