import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgramType } from '../enums/aco-period-type.enum';
import { NetworkApi } from 'src/app/api/network.api';
import { AlignType } from '../enums/aco-period-alignment-type.enum';

@Injectable({
	providedIn: 'root'
})
export class AcoPeriodService {
	public readonly selectedAcoPeriod: Observable<number>;
	
	private _selectedAcoPeriod: BehaviorSubject<number> = new BehaviorSubject(0);
	
	constructor(private networkApi: NetworkApi) {
		this.selectedAcoPeriod = this._selectedAcoPeriod.asObservable();
	}

	setSelectedAcoPeriod(period: number)
	{
		this._selectedAcoPeriod.next(period);
	}

	selectedAcoPeriodHasValue(): boolean {
		return this._selectedAcoPeriod.value !== undefined && this._selectedAcoPeriod.value !== null && this._selectedAcoPeriod.value > 0;
	}

	getLastSelectedPeriod(): number {
		return this._selectedAcoPeriod.value;
	}

	getDefaultAcoPeriod(programType: ProgramType, alignType: AlignType = AlignType.None) {
		return this.networkApi.getDefaultAcoPeriod(programType).pipe(tap(acoPeriod => {
			this.setSelectedAcoPeriod(acoPeriod.id);
		}));
	}
}

