import { Injectable } from '@angular/core';
import { GridInfoRequestHandler } from 'src/app/components/grid/grid-info-request-handler';
import { GridInfo } from '@appcore/models/grid-info.model';
import { GridExport } from '@appcore/models/grid/grid-export.model';

@Injectable({
	providedIn: 'root'
})
export class MosaicGridExportService {
	gridInfoRequestHandlers: GridInfoRequestHandler[] = [];

	register(handler: GridInfoRequestHandler) {
		this.gridInfoRequestHandlers.push(handler);
	}

	unregister(handler: GridInfoRequestHandler){
		const existingHandler = this.gridInfoRequestHandlers.find(existing => existing === handler);
		if(existingHandler)
			this.gridInfoRequestHandlers = this.gridInfoRequestHandlers.filter(existing => existing !== existingHandler);
		 else 
			console.warn('could not find handler to unregister');
		
	}

	getExportData(gridInfo: GridInfo) : Promise<GridExport> {
		const requestHandler = this.gridInfoRequestHandlers.find(handler => handler.canHandle(gridInfo));
		return requestHandler?.handleGridInfoRequest(gridInfo) ?? new Promise<GridExport>((resolve, reject) => {
			resolve(GridExport.withNoData(gridInfo));
		});
	}
}
