import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CUSTOM_LIST_TYPE } from '../shared/enums/custom-list-type.enum';
import { CustomList } from '../shared/models/custom-list';
import { CustomListGroup } from '../shared/models/custom-list-group';
import { CustomListItem } from '../shared/models/custom-list-item';
import { ApiService } from './api.service';
import { NpiType } from '@appcore/enums/npi-type.enum';

@Injectable({
	providedIn: 'root'
})
export class CustomListApi {
	_controllerName = 'CustomList';

	constructor(private api: ApiService) {}

	createCustomList(list: CustomList): Observable<number> {
		return this.api.post(this._controllerName, '', list);
	}

	getCustomLists(customListType: CUSTOM_LIST_TYPE): Observable<CustomList[]> {
		return this.api.get(this._controllerName, `${customListType}`);
	}

	getCustomListItems(customListId: number, npiType: NpiType): Observable<CustomListItem[]> {
		if (!npiType) 
			return this.api.get(this._controllerName, `items/${customListId}`);
		
		return this.api.get(this._controllerName, `items/${customListId}?providerType=${npiType}`);
	}

	updateCustomListItems(group: CustomListGroup): Observable<void> {
		return this.api.put(this._controllerName, 'items', group);
	}
}
