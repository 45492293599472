// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flight-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/npi-input-verify/npi-input-verify.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,cAAA;AACF","sourcesContent":[".flight-feedback {\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 80%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
