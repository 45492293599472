
import { NpiInfo } from './npi';
import { Network } from './npi-group';
import { PROVIDER_TYPE } from '../shared.constants';
import { UserContract } from '@appcore/models/directory/user/user-contract.model';
import { ProductName } from '@appcore/enums/product-name.enum';
import { Locality } from '@appcore/interfaces/locality.interface';

export interface IImpersonatedTrellaUser {
	id: string;
	companyId: string;
	companyName: string;
	email: string;
	firstName: string;
	lastName: string;
}

export class TrellaUser extends UserContract {
	favorites: UserFavorite[] = [];
	imitableUsers: IImpersonatedTrellaUser[] = [];
	loadDate: string; // TODO: probably shouldn't be on this object
	markets: string[];
	myLocations: Locality[] = [];
	networks: Network[] = [];
	permissions: any[];
	productNames: ProductName[] = [];
	replacementValues: { [key: string]: string };

	constructor(obj: TrellaUser | SimpleTrellaUser) {
		super();
		Object.assign(this, obj);
	}
}

export class CurrentUserResponse {
	user: TrellaUser;
	loadDate: string;

	constructor(response: any) {
		this.user = new TrellaUser(response);
	}
}

export class UserFavorite {
	lookupInfo: NpiInfo;
	constructor(public npi: string, public providerType: PROVIDER_TYPE) {}
}

/**
 * @deprecated The class should not be used
 */
class SimpleTrellaUser {
	id: string;
	companyId: string;
	companyName: string;
	email: string;
	firstName: string;
	lastName: string;

	constructor(obj: SimpleTrellaUser) {
		Object.assign(this, obj);
	}
}
