// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  display: inline-block;
}

.hover-content {
  display: inline-block;
}

.underline {
  border-bottom: 1px dashed #86a9ec;
}`, "",{"version":3,"sources":["webpack://./appcore/components/hover-tooltip/hover-tooltip.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,iCAAA;AACJ","sourcesContent":[".tooltip {\n    display: inline-block;\n}\n\n.hover-content {\n    display: inline-block;\n}\n\n.underline {\n    border-bottom: 1px dashed #86a9ec; //$aco color\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
