// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  max-width: 500px;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .login-container {
    width: 100%;
  }
}

.logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.input-group .form-control {
  height: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAEA;EACC,gBAAA;EACA,WAAA;EACG,kBAAA;AADJ;AAGI;EALJ;IAMQ,WAAA;EAAN;AACF;;AAGA;EACC,cAAA;EACA,kBAAA;EACA,iBAAA;AAAD;;AAGA;EACC,uBAAA;AAAD","sourcesContent":["@use 'appcore/styles/variables' as variables;\n\n.login-container {\n\tmax-width: 500px;\n\twidth: 100%;\n    text-align: center;\n\n    @media (max-width:768px) {\n        width: 100%;\n    }\n}\n\n.logo {\n\tdisplay: block;\n\tmargin-right: auto;\n\tmargin-left: auto;\n}\n\n.input-group .form-control {\n\theight: 40px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
