import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TotalCostSectionOptions } from './total-cost-section-options';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../shared/services/navigation.service';
import { Subject } from 'rxjs';
import { TotalCostOfCareType } from '../../shared/models/total-cost-of-care.model';
import { NpiApi } from '../../api/npi.api';
import { TccSearchResponse } from '../../shared/models/search/tcc-search-response';
import { NpiSearchRequest } from '../../shared/models/search/npi-search-request';
import { DiagnosticCategoryService } from '../../shared/services/diagnostic-category.service';
import { SpecialtiesService } from 'src/app/shared/services/specialties.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { Filter } from '@appcore/models/filter.model';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { ComparisonsDialogComponent } from '@appcore/components/comparisons-dialog/comparisons-dialog.component';
import { SearchItem } from '@appcore/models/search/search-item.model';
import {
	ACUITY_OPTIONS,
	AGE_OPTIONS,
	BPCI_OPTIONS,
	PATIENTS_LIVE_OPTIONS,
	RPG_OPTIONS,
	TCC_ALL_OPTION
} from '@appcore/constants/constants';

@Component({
	selector: 'mosaic-total-cost-of-care',
	templateUrl: './total-cost-of-care.component.html',
	styleUrls: ['./total-cost-of-care.component.scss']
})
export class TotalCostOfCareComponent extends ComponentWithSubscription implements OnInit, AfterViewInit {
	@ViewChild(ComparisonsDialogComponent) comparisonDialog;
	
	@Input() options: TotalCostSectionOptions;
	
	@Output() stateChange: EventEmitter<TotalCostSectionOptions> = new EventEmitter();
	
	updateDataSubject$ = new Subject();
	reportName: string;
	primaryProviderType: string;
	onReportNameChange: (value: string) => void;
	searchResults: SearchItem[];
	selectedNpis;
	filters;
	activeLegendItems = [];
	chartName: string; // For Chart #1
	acuityOptions = ACUITY_OPTIONS;
	ageOptions = AGE_OPTIONS;
	diagnosticCategories;
	rpgOptions = RPG_OPTIONS;
	bpciOptions = BPCI_OPTIONS;
	followUpMonthOptions = [];
	selectedAllOption: string = TCC_ALL_OPTION;
	selectedFilter: string;
	selectedTimePeriod: string;
	isRiskAdjusted: boolean;
	physSpecialties: string[];
	selectedPhysSpecialty: string;
	patientsLiveOptions = PATIENTS_LIVE_OPTIONS;
	selectedPatientsLiveStatus: string;
	maxComparisons = 7;

	getAnalyzeUrl = this.navigationService.getAnalyzeUrl;

	constructor(private npiApi: NpiApi, private navigationService: NavigationService, private cdr: ChangeDetectorRef,
				private diagnosticCategoryService: DiagnosticCategoryService, private specialtiesService: SpecialtiesService) {
		super();
	}

	ngOnInit() {
		this.options = new TotalCostSectionOptions(this.options);
		this.getfollowUpMonthOptions();
		this.selectedFilter = this.options.filter || this.selectedAllOption;
		this.selectedTimePeriod = this.options.timePeriod || this.followUpMonthOptions[0];
		this.isRiskAdjusted = this.options.isRiskAdjusted;
		this.selectedNpis = this.options.npis || [];
		this.activeLegendItems = this.options.activeLegendItems || [];
		this.selectedPatientsLiveStatus = (this.options.selectedPatientsLiveStatus || this.patientsLiveOptions[0].value).toLowerCase();

		this.reportName = this.getReportName();
		this.primaryProviderType = this.getPrimaryProviderType();
		this.filters = this.getFilters();
		this.chartName = this.getChartName();
		this.retrieveDiagnosticCategories();
		this.loadSpecialties();
	}

	ngAfterViewInit() {
		this.updateDataSubject$.next(null);

		if (!this.options.npis.length) 
			setTimeout(() => this.comparisonDialog.open()); // prevents the value has changed error
		
	}

	onRiskAdjustToggleChange() {
		this.onChange();
	}

	onFilterChange() {
		this.onChange();
	}

	onChange() {
		this.filters = this.getFilters();
		this.reportName = this.getReportName();
		this.chartName = this.getChartName();

		this.onStateChange();
		this.onReportNameChange(this.reportName);
		this.updateDataSubject$.next(null);
	}

	getReportName(): string {
		if (this.isPhysMort()) 
			return `tcc_${TotalCostOfCareType.PHYS_MORT}`;
		
		return `tcc_${this.options.totalCostOfCareType}`;
	}

	getPrimaryProviderType(): string {
		if (this.isPhysMort() || this.reportName.startsWith('tcc_phys')) 
			return 'physician';
		

		if (this.reportName.startsWith('tcc_hha'))
			return 'hha';
		

		if (this.reportName.startsWith('tcc_inp')) 
			return 'hospital';
		

		if (this.reportName.startsWith('tcc_snf')) 
			return 'snf';
		
	}

	getfollowUpMonthOptions() {
		switch (this.options.totalCostOfCareType) {
			case TotalCostOfCareType.INP:
			case TotalCostOfCareType.SNF:
			case TotalCostOfCareType.HHA:
				this.followUpMonthOptions = ['12', '6', '3'];
				break;
			default:
				this.followUpMonthOptions = ['12', '6'];
		}
	}

	getChartName(): string {
		const postpend = this.isRiskAdjusted ? '_adj' : '';
		const mort = this.isPhysMort() ? '_mort' : '';

		switch (this.options.totalCostOfCareType) {
			case TotalCostOfCareType.INP:
				return `tcc_inp${postpend}`;
			case TotalCostOfCareType.HHA:
				return `tcc_hha${postpend}`;
			case TotalCostOfCareType.SNF:
				return `tcc_snf${postpend}`;
			case TotalCostOfCareType.PHYS:
				return `tcc_phys${mort + postpend}`;
		}
	}

	getFilterGroup(): string {
		if (this.isFilterMDC()) 
			return 'mdc';
		 else if (this.isFilterAcuity()) 
			return 'acuity';
		 else if (this.isFilterAge()) 
			return 'age_range';
		 else if (this.isFilterBpci()) 
			return 'bpci';
		 else if (this.isFilterRpg()) 
			return 'rpg';
		
		return TCC_ALL_OPTION;
	}

	getFilterType(): string {
		if (this.isFilterMDC()) 
			return 'drg';
		

		// acuity / age / ALL can use the age filter because they all look at filter_group
		return 'age';
	}

	isFilterMDC() {
		return this.diagnosticCategories && this.diagnosticCategories.some(x => x.id === this.selectedFilter);
	}

	isFilterAcuity() {
		return this.acuityOptions.map(x => x.value).includes(this.selectedFilter);
	}

	isFilterAge() {
		return this.ageOptions.map(x => x.value).includes(this.selectedFilter);
	}

	isFilterBpci() {
		return this.bpciOptions.map(x => x.display).includes(this.selectedFilter);
	}

	isFilterRpg() {
		return this.rpgOptions.map(x => x.value).includes(this.selectedFilter);
	}

	open() {
		this.comparisonDialog.open();
		this.selectedPhysSpecialty = '';
	}

	saveComparisons(npis) {
		this.selectedNpis = npis;
		this.filters = this.getFilters();
		this.onStateChange();
		this.updateDataSubject$.next(null);
	}

	getFilters() {
		const filters = [
			this.getNpiFilter(),
			this.getCategoryFilter(),
			this.getSelectedFilter(),
			this.getTimePeriodFilter(),
			this.getRiskAdjustedFilter(),
			this.getFacilityTypeFilter()
		];

		return filters;
	}

	getFacilityTypeFilter(): Filter {
		let json = this.options.totalCostOfCareType;
		if (this.isPhysMort()) 
			json = TotalCostOfCareType.PHYS_MORT;
		
		const facilityTypeFilter = {
			filterType: FILTER_TYPE.DYNAMIC,
			modelJson: json,
			paramName: 'facility_type'
		};
		return facilityTypeFilter;
	}

	getRiskAdjustedFilter(): Filter {
		const riskAdjusedFilter = {
			filterType: FILTER_TYPE.RISK_ADJUSTED,
			modelJson: JSON.stringify(this.isRiskAdjusted)
		};
		return riskAdjusedFilter;
	}

	getNpiFilter() {
		const npiFilter = {
			filterType: 'NpiList',
			modelJson: JSON.stringify(this.selectedNpis.map(x => x.npi))
		};
		return npiFilter;
	}

	getCategoryFilter() {
		const categoryFilter = {
			filterType: 'category',
			modelJson: this.getFilterGroup()
		};

		return categoryFilter;
	}

	getSelectedFilter() {
		const timePeriodFilter = {
			filterType: 'months',
			modelJson: this.selectedTimePeriod
		};

		return timePeriodFilter;
	}

	getTimePeriodFilter() {
		const categoryFilter = {
			filterType: this.getFilterType(),
			modelJson: this.selectedFilter
		};

		return categoryFilter;
	}

	getSelectedPhysSpecialty(specialty: string) {
		this.selectedPhysSpecialty = specialty;
	}

	search(searchString: string) {
		const npis = this.comparisonDialog.getSelectedNpis();
		const selectedPhysSpecialty = this.selectedPhysSpecialty;
		const npiSearch: NpiSearchRequest = {
			searchString,
			totalCostOfCareType: this.options.totalCostOfCareType,
			npis: npis.map(x => x.npi),
			selectedPhysSpecialty
		};

		this.npiApi
			.searchTcc(npiSearch)
			.pipe(finalize(() => this.comparisonDialog.stopSpinner()))
			.subscribe((response: TccSearchResponse[]) => {
				this.searchResults = response.map(x => TccSearchResponse.toSearchItem({...x, type: this.options.totalCostOfCareType}));
			});
	}

	activeLegendItemsChange(items: []) {
		this.activeLegendItems = items;
		this.onStateChange();
	}

	refreshData() {
		this.cdr.detectChanges();
	}

	getModelState(): TotalCostSectionOptions {
		return {
			filter: this.selectedFilter,
			npis: this.selectedNpis,
			isRiskAdjusted: this.isRiskAdjusted,
			totalCostOfCareType: this.options.totalCostOfCareType,
			timePeriod: this.selectedTimePeriod,
			activeLegendItems: this.activeLegendItems,
			selectedPatientsLiveStatus: this.selectedPatientsLiveStatus
		};
	}

	onStateChange() {
		const state = this.getModelState();
		this.stateChange.emit(state);
	}

	showRpgBpci() {
		return this.options.totalCostOfCareType === TotalCostOfCareType.SNF || this.options.totalCostOfCareType === TotalCostOfCareType.INP;
	}

	showPatientTypeDropDown() {
		return this.options.totalCostOfCareType === TotalCostOfCareType.PHYS;
	}

	isPhysMort(): boolean {
		return (
			this.options.totalCostOfCareType === TotalCostOfCareType.PHYS &&
			this.selectedPatientsLiveStatus === TotalCostOfCareType.PHYS_MORT
		);
	}

	private loadSpecialties(): void {
		this.specialtiesService.loadSpecialties().subscribe(
			() => {},
			() => {}
		);

		this.specialtiesService.specialties.
		pipe(takeUntil(this.ngUnsubscribe)).
		subscribe(specialties => this.physSpecialties = specialties);
	}

	private retrieveDiagnosticCategories(): void {
		this.diagnosticCategoryService.diagnosticCategories.subscribe(selections=>{
			this.diagnosticCategories = selections;
			this.filters = this.getFilters();
		});
	}
}
