import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	MedicalEntityType
} from '@appcore/enums/medical-entity-type.enum';
import { FilterRules } from '@appcore/interfaces/filter-rules.interface';


@Injectable({
	providedIn: 'root'
})
export class FilterRulesApi {
	_controllerName = 'FilterRules';

	constructor(private api: ApiService, protected http: HttpClient) {}

	getRules(filterTypes: string[], providerType: NpiType | MedicalEntityType): Observable<FilterRules> {
		const encodedParams = ApiService.makeList(filterTypes, 'filterTypes');
		const url = `${this._controllerName}/rules?${encodedParams}&providerType=${providerType}`;
		return this.api.get(url);
	}
}
