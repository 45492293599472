import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TileApi } from '../../api/tile.api';
import { PAGE } from '../enums/page.enum';
import { Tile } from '../models/tile.model';

@Injectable({
	providedIn: 'root'
})
export class AcoTopMetricsService {
	public readonly userTilesFetched: Observable<Tile[]>;
	public readonly cmsTilesFetched: Observable<Tile[]>;
	
	protected ngUnsubscribe: Subject<void> = new Subject<void>();
	
	private _userTilesFetched: Subject<Tile[]> = new Subject();
	private _cmsTilesFetched: Subject<Tile[]> = new Subject();
	
	constructor(private tileApi: TileApi) {
		this.userTilesFetched = this._userTilesFetched.asObservable();
		this.cmsTilesFetched = this._cmsTilesFetched.asObservable();
	}
	
	getTilesByAco(acoId: string, acoPeriodId: number, page: PAGE): Observable<Tile[]> {
		return this.tileApi.getTilesByCmsAco(page, acoId, acoPeriodId).pipe(
			takeUntil(this.ngUnsubscribe),
			map(tiles => {
				this._cmsTilesFetched.next(tiles);
				return tiles;
			})
		);
	}

	getTilesByNetwork(networkId: number, acoPeriodId: number, page: PAGE): Observable<Tile[]> {
		return this.tileApi.getTilesByNetwork(page, networkId, acoPeriodId).pipe(
			takeUntil(this.ngUnsubscribe),
			map(tiles => {
				this._userTilesFetched.next(tiles);
				return tiles;
			})
		);
	}

	clearTiles(): Tile[] {
		this._userTilesFetched.next([]);
		this._cmsTilesFetched.next([]);
		return [];
	}
}
