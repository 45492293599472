import { Component, Input, OnInit } from '@angular/core';

import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { AcoService } from 'src/app/shared/services/aco.service';
import { PmpmSpending } from 'src/app/shared/models/pmpm-spending';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';

@Component({
	selector: 'aco-pmpm-sums',
	templateUrl: './aco-pmpm-sums.component.html',
	styleUrls: ['./aco-pmpm-sums.component.scss']
})
export class AcoPmpmSumsComponent extends ComponentWithSubscription {
	@Input() isRiskAdjusted = false;
	@Input() sums: PmpmSpending[] = [];
	@Input() isCms = false;
	@Input() acoPeriodId: number;

    targetTooltipText = 'This represents the PMPM Target for the Performance Year selected. Source: CMS (https://www.cms.gov/Research-Statistics-Data-and-Systems/Downloadable-Public-Use-Files/SSPACO)';
	
    constructor(public acoDashboardService: AcoDashboardService,
		private acoService: AcoService) {
		super();
	}

	getSavings(spending: PmpmSpending) {
		const target = this.isRiskAdjusted? spending.riskAdjustedTarget : spending.target;
		if (target) 
			return this.isRiskAdjusted? spending.riskAdjustedTotalSavings : spending.totalSavings;
		

		return 'No PMPM Target - cannot be calculated.';
	}

	getSpending(spending: PmpmSpending) {
		return this.isRiskAdjusted? spending.riskAdjustedTotalSpending : spending.totalSpending;
	}

	isSavingsPositive(spending: PmpmSpending) {
		return !(this.isRiskAdjusted? spending.isRiskAdjustedSavingsNegative : spending.isSavingsNegative);
	}

	getYear(year: number){
		if(this.isCms)
			return year;
		
		switch(this.acoPeriodId) { 
			case 25: { 
				return 'IP - DoS 2019';
			} 
			case 26: { 
				return 'IP - DoS 2020';
			} 
			case 15: { 
				return 'PY1 2021 - DoS 2020';
			 } 
			default: { 
			   return year;
			} 
		 } 
	}

	showSavings(spending: PmpmSpending): boolean {
		return !!((this.isRiskAdjusted && spending.riskAdjustedTotalSavings) ||
		 (!this.isRiskAdjusted && spending.totalSavings));
	}
}
