// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.other-label-width {
  width: 150px;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.star-rating-container {
  white-space: nowrap;
}

.presentation-button {
  border-radius: 0.25rem;
  border: solid 1px #f6f6f6;
  background-color: #43b02a;
  color: white;
  margin: 0 0 0 15px;
  padding: 2px 15px;
}

.cmr-icon-default {
  border: 1px solid #5b6770;
  border-radius: 6px;
  display: inline-flex;
  flex-direction: row;
  padding-right: 0.5rem;
}

.cmr-icon-default img {
  margin-right: 0.25rem;
  width: 1.8rem;
}

.cmr-icon-default span {
  color: #5b6770;
  font-family: "Open Sans semibold", sans-serif;
  text-decoration: underline;
}

.assignments {
  max-height: 6vh;
  overflow: auto;
}

.disable_icon {
  pointer-events: none !important;
  cursor: default !important;
}

.button-holder {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./appcore/components/demographic-card/demographic-card.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;;;;EAII,cAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,yBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,aAAA;AACJ;;AAEA;EACI,cAAA;EACA,6CAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;EACA,cAAA;AACJ;;AAEA;EACI,+BAAA;EACA,0BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".other-label-width {\n    width: 150px;\n}\n\na,\na:visited,\na:hover,\na:active {\n    color: inherit;\n}\n\n.star-rating-container {\n    white-space: nowrap;\n}\n\n.presentation-button {\n    border-radius: 0.25rem;\n    border: solid 1px #f6f6f6;\n    background-color: #43b02a;\n    color: white;\n    margin: 0 0 0 15px;\n    padding: 2px 15px;\n}\n\n.cmr-icon-default {\n    border: 1px solid #5b6770;\n    border-radius: 6px;\n    display: inline-flex;\n    flex-direction: row;\n    padding-right: 0.5rem;\n}\n\n.cmr-icon-default img {\n    margin-right: 0.25rem;\n    width: 1.8rem;\n}\n\n.cmr-icon-default span {\n    color: #5b6770;\n    font-family: 'Open Sans semibold', sans-serif;\n    text-decoration: underline;\n}\n\n.assignments {\n    max-height: 6vh;\n    overflow: auto;\n}\n\n.disable_icon {\n    pointer-events: none !important;\n    cursor: default !important;\n}\n\n.button-holder {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
