// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  			ol li {
  				list-style: decimal;
  				padding-top: 10px;
  				margin-left: 20px;
  			}
  		`, "",{"version":3,"sources":["webpack://./appcore/components/data-eula/data-eula.component.ts"],"names":[],"mappings":";KACK;MACC,mBAAmB;MACnB,iBAAiB;MACjB,iBAAiB;KAClB","sourcesContent":["\n  \t\t\tol li {\n  \t\t\t\tlist-style: decimal;\n  \t\t\t\tpadding-top: 10px;\n  \t\t\t\tmargin-left: 20px;\n  \t\t\t}\n  \t\t"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
