export enum VIEW_TYPE_STRING {
    Dce = 'DCE',
    MsspAco = 'MSSP ACO',
    MsspAndDceOrganization = 'MSSP and DCE organization',
    IndependentPac = 'Independent PAC',
    ReadmissionsAndDischarges = 'Readmissions and Discharges',
    DiagnosticRelatedGroups = 'Diagnostic Related Groups'
}

export enum VIEW_TYPE {
    Dce = 'Dce',
    MsspAco = 'MsspAco',
    MsspAndDceOrganization = 'MsspAndDceOrganization',
    IndependentPac = 'IndependentPac',
    ReadmissionsAndDischarges = 'ReadmissionsAndDischarges',
    DiagnosticRelatedGroups = 'DiagnosticRelatedGroups'
}
