import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { HighchartsChartModule } from 'highcharts-angular';
import { BaseTotalCostComponent } from '../main/base-total-cost/base-total-cost.component';
import { ChartComponent } from './chart/chart.component';
import { MosaicGridComponent } from './grid/mosaic-grid.component';
import { JourneyComponent } from './journey/journey.component';
import { NpiInputVerifyComponent } from './npi-input-verify/npi-input-verify.component';
import { SelectNetworkComponent } from './select-network/select-network.component';
import { TotalCostOfCareComponent } from './total-cost-of-care/total-cost-of-care.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AcoPmpmComponent, AcoTileComponent } from '../main/aco-dashboard';
import { AddToNetworkModalComponent } from '../main/explore/add-to-network-modal/add-to-network-modal.component';
import { AbstractValueAccessor } from '../shared/AbstractValueAccessor';
import { GoodOrBadDirective } from '../shared/directives/good-or-bad.directive';
import { PipeModule } from '../shared/pipes/pipes.module';
import { AcoPeriodSelector } from './aco-period-selector/aco-period-selector.component';
import { AcoPmpmSumsComponent } from './aco-pmpm/aco-pmpm-sums/aco-pmpm-sums.component';
import { AcoTileGridComponent } from './aco-tile-grid/aco-tile-grid.component';
import { TileChartTooltipDirective } from './aco-tile-grid/aco-tile/tile-chart-tooltip.directive';
import { AssessTileComponent } from './assess-tile/assess-tile.component';
import { DemographicCardComponent } from './demographic-card/demographic-card.component';
import { DevInfoDialogComponent } from './dev-info-dialog/dev-info-dialog.component';
import { ExpandDialogComponent } from './expand-dialog/expand-dialog.component';
import { FilterableListBoxComponent } from './filterable-list-box/filterable-list-box.component';
import { FloatingRecentAnalyzeListComponent } from './floating-recent-analyze-list/floating-recent-analyze-list.component';
import { JourneyAcoOptionsComponent } from './journey/journey-aco-options/journey-aco-options.component';
import { JourneyKeyComponent } from './journey/journey-key/journey-key.component';
import { JourneyOptionsComponent } from './journey/journey-options/journey-options.component';
import { BaseMetricsComponent } from './metrics/metrics.component';
import { NetworkDropdownComponent } from './network-dropdown/network-dropdown.component';
import { NetworkSelectorComponent } from './network-selector/network-selector.component';
import { MosaicNotFoundComponent } from './not-found/not-found.component';
import { NpiTypeOptionComponent } from './npi-type-option/npi-type-option.component';
import { NpiTypeSelectorComponent } from './npi-type-selector/npi-type-selector.component';
import { FilterNetworksPipe } from './pipes/filter-networks.pipe';
import { TotalCostOfCareComponentDirective } from './total-cost-of-care/total-cost-of-care.directive';
import { UserNetworkPmpmComponent } from './user-network-pmpm/user-network-pmpm.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { RegionalComparisonsComponent } from './regional-comparisons/regional-comparisons.component';
import { UpdateCustomListComponent } from './update-custom-list/update-custom-list.component';
import { CreateCustomListComponent } from './create-custom-list/create-custom-list.component';
import { ComponentsModule } from '@appcore/components/components.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatExpansionModule,
		MatChipsModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatNativeDateModule,
		MatRadioModule,
		MatProgressBarModule,
		MatTabsModule,
		MatCardModule,
		ChartsModule,
		DialogsModule,
		DropDownListModule,
		DropDownsModule,
		PopupModule,
		TooltipModule,
		ExcelModule,
		GridModule,
		ComponentsModule,
		HighchartsChartModule,
		RouterModule,
		InputsModule,
		ScrollingModule,
		PipeModule
	],
	declarations: [
		AcoPeriodSelector,
		AcoPmpmComponent,
		AcoPmpmSumsComponent,
		AcoTileComponent,
		AcoTileGridComponent,
		AssessTileComponent,
		BaseMetricsComponent,
		UpdateCustomListComponent,
		CreateCustomListComponent,
		RegionalComparisonsComponent,
		DevInfoDialogComponent,
		MosaicGridComponent,
		ChartComponent,
		TotalCostOfCareComponent,
		JourneyComponent,
		JourneyKeyComponent,
		JourneyOptionsComponent,
		JourneyAcoOptionsComponent,
		SelectNetworkComponent,
		NpiInputVerifyComponent,
		TotalCostOfCareComponentDirective,
		TileChartTooltipDirective,
		DemographicCardComponent,
		MosaicNotFoundComponent,
		ExpandDialogComponent,
		AddToNetworkModalComponent,
		NetworkDropdownComponent,
		UserNetworkPmpmComponent,
		UserSettingsComponent,

		// abstract / base classes
		AbstractValueAccessor,
		BaseTotalCostComponent,
		NetworkSelectorComponent,
		NpiTypeSelectorComponent,
		NpiTypeOptionComponent,
		FilterableListBoxComponent,
		FilterNetworksPipe,
		FloatingRecentAnalyzeListComponent,
		GoodOrBadDirective
	],
	exports: [
		AcoPeriodSelector,
		AcoPmpmComponent,
		AcoPmpmSumsComponent,
		AcoTileComponent,
		AcoTileGridComponent,
		AssessTileComponent,
		BaseMetricsComponent,
		CommonModule,
		UpdateCustomListComponent,
		CreateCustomListComponent,
		RegionalComparisonsComponent,
		FormsModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatExpansionModule,
		MatChipsModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatNativeDateModule,
		MatRadioModule,
		MatProgressBarModule,
		MatTabsModule,
		MatCardModule,
		ChartsModule,
		DialogsModule,
		DropDownListModule,
		DropDownsModule,
		PopupModule,
		TooltipModule,
		TileChartTooltipDirective,
		MosaicGridComponent,
		ChartComponent,
		NetworkDropdownComponent,
		ComponentsModule,
		TotalCostOfCareComponent,
		JourneyComponent,
		JourneyKeyComponent,
		JourneyOptionsComponent,
		JourneyAcoOptionsComponent,
		SelectNetworkComponent,
		UserSettingsComponent,
		UserNetworkPmpmComponent,
		HighchartsChartModule,
		NpiInputVerifyComponent,
		RouterModule,
		DemographicCardComponent,
		MosaicNotFoundComponent,
		ExpandDialogComponent,
		NetworkSelectorComponent,
		NpiTypeSelectorComponent,
		NpiTypeOptionComponent,
		FilterNetworksPipe,
		FloatingRecentAnalyzeListComponent,
		GoodOrBadDirective,
		PipeModule
	]
})
export class MosaicComponentsModule {}
