import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { NetworkApi } from 'src/app/api/network.api';
import { AlignType } from 'src/app/shared/enums/aco-period-alignment-type.enum';
import { ProgramType } from 'src/app/shared/enums/aco-period-type.enum';
import { AcoPeriodService } from 'src/app/shared/services/aco-period.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	CheckedSelection
} from '@appcore/interfaces/checked-selection.interface';

@Component({
	selector: 'trella-aco-period-selector',
	templateUrl: './aco-period-selector.component.html',
	styleUrls: ['./aco-period-selector.component.scss']
})
export class AcoPeriodSelector extends ComponentWithSubscription implements OnInit {
	@Input() programType: ProgramType;
	@Input() alignType: AlignType = AlignType.None;

	acoPeriodSelections: CheckedSelection[] = [];
	selectedAcoPeriod: CheckedSelection;
	showPeriodSelector = false;

	constructor(private ref: ChangeDetectorRef, private acoPeriodService: AcoPeriodService,
	 private networkApi: NetworkApi) {
		super();
	}

	ngOnInit() {
		this.acoPeriodService.selectedAcoPeriod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedPeriod => {
			if(this.acoPeriodSelections.length) 
				this.checkSelectedAcoPeriodOption(selectedPeriod);
			
		});

		this.networkApi.getActiveAcoPeriodSelections(this.programType, this.alignType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(selections => {
			const lastSelected = this.acoPeriodService.getLastSelectedPeriod();
			const lastSelectedIsInActiveSelections = selections.some(s => s.id.toString().toLocaleLowerCase() === lastSelected.toString().toLocaleLowerCase());

			this.acoPeriodSelections = selections.map(s => ({
				display: s.display,
				id: s.id,
				checked: false
			}));
			
			if(!this.acoPeriodService.selectedAcoPeriodHasValue() || !lastSelectedIsInActiveSelections)
			
				this.acoPeriodService.setSelectedAcoPeriod(+selections[0].id);
			
			else 
				this.acoPeriodService.setSelectedAcoPeriod(lastSelected);
			
		});
	}

	togglePeriodSelector() {
		this.showPeriodSelector = !this.showPeriodSelector;
	}

	onSelectedAcoPeriodChange(selection: CheckedSelection) {
		this.acoPeriodService.setSelectedAcoPeriod(+selection.id);
	}

	private checkSelectedAcoPeriodOption(acoPeriodId: number) {
		this.selectedAcoPeriod = this.acoPeriodSelections.find(s => +s.id === acoPeriodId);
		if (this.selectedAcoPeriod && !this.selectedAcoPeriod.checked) {
			this.selectedAcoPeriod.checked = true;
			this.ref.detectChanges();
		}
	}
}
