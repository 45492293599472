import { Component, OnInit, EventEmitter, Output, AfterViewChecked, ChangeDetectorRef } from '@angular/core';

import { NavigationService } from 'src/app/shared/services/navigation.service';
import { RecentAnalyzeListService } from 'src/app/shared/services/recent-analyze-list.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { NpiFloatingListModel } from '@appcore/models/npi/npi-floating-list.model';


@Component({
	selector: 'mosaic-floating-recent-analyze-list',
	templateUrl: './floating-recent-analyze-list.component.html',
	styleUrls: ['./floating-recent-analyze-list.component.scss']
})


export class FloatingRecentAnalyzeListComponent extends ComponentWithSubscription implements OnInit, AfterViewChecked {
	@Output() npiListExpanded = new EventEmitter();

	recentItems: NpiFloatingListModel[] = [];
	expanded;
	hidden;

	constructor(
		private navigationService: NavigationService,
		private recentAnalyzeListService: RecentAnalyzeListService,
		private changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit() {
		this.recentAnalyzeListService.floatListExpanded.subscribe(x => this.expanded = x);
		this.recentAnalyzeListService.floatListHidden.subscribe(x => this.hidden = x);
		this.recentAnalyzeListService.recentItems.subscribe(x => this.recentItems = x);
	}

	ngAfterViewChecked(){
		this.changeDetectorRef.detectChanges();
	  }

	navigate(item){
		return this.navigationService.navigateToAnalyze(item.type, item.id);
	}


	remove(item) {
		this.recentAnalyzeListService.removeRecent(item);
	}

	toggleExpand() {
		this.recentAnalyzeListService.toggleFloatListExpand();
	}



}
