import { MedicalEntityTypeConfigs } from './models/medical-entity-type-configs';
import { NpiTypeConfigs } from './models/npi-type-configs';
import { NpiType } from '@appcore/enums/npi-type.enum';

export const ERROR_MESSAGES = {
	default: 'Sorry, an unexpected error occurred while attempting to contact the server. Please try again.',
	processingLogin: 'Processing...',
	processingRequesting: 'Requesting access to Trella...',
	loginFailed: 'Login attempt failed. We apologize for the inconvenience - please contact the Trella Health Help Desk.',
	authenticationFailed: 'Authentication failed. We apologize for the inconvenience - please contact the Trella Health Help Desk.',
	userNotFound: 'Oops! It looks like you don\'t have access to Marketscape Strategy. Contact your Trella Health administrator.'
};

export const FAVORITE_ICON = 'i.favorite-icon-default';

export enum PORTAL_TYPE_MOSAIC {
	MOSAIC = 'mosaic',
	ADMIN = 'admin',
	HHA = 'hha',
	HOS = 'hos',
	SNF = 'snf'
}

export enum ANALYZE_PAGE_VIEW_TYPE {
	GRID = 'grid',
	CHART = 'chart'
}

export interface ProviderTypeConfig {
	enum?: NpiType;
	routeString?: string;
	label: string;
	labelSingular?: string;
	icon?: string;
	colorClass?: string;
	bgClass?: string;
}

export const ROUTE_CONSTANTS = {
	analyze: {
		route: 'analyze',
		label: 'Analyze',
		icon: '',
		svg: 'assets/img/icons/chart_column_light.svg',
		svgActive: 'assets/img/icons/chart_column_light.svg'
	},
	analyzeMS: {
		route: 'analyze-ms',
		label: 'Analyze',
		icon: 'fas fa-chart-bar'
	},
	assess: {
		route: 'assessment',
		label: 'Market Assessment',
		icon: 'fal fa-magnifying-glass-chart'
	},
	dashboard: {
		route: 'dashboard',
		label: 'Home',
		icon: '',
		svg: 'assets/img/icons/house_chimney_light.svg',
		svgActive: 'assets/img/icons/house_chimney_light.svg'
	},
	engage: {
		route: 'engage',
		label: 'Engage',
		icon: 'fas fa-comments-alt'
	},
	explore: {
		route: 'explore',
		label: 'Explore',
		icon: '',
		svg: 'assets/img/icons/binoculars_light.svg',
		svgActive: 'assets/img/icons/binoculars_light.svg'
	},
	explore1: {
		route: 'explore1',
		label: 'Explore1',
		icon: 'fas fa-binoculars'
	},
	idRouteParam: 'id',
	landing: {
		route: 'landing',
		label: 'Landing',
		icon: ''
	},
	login: {
		route: 'login',
		label: 'Login',
		icon: ''
	},
	logout: {
		route: 'logout',
		label: 'Logout',
		icon: ''
	},
	crmlogout: {
		route: 'crmlogout',
		label: 'crmlogout',
		icon: ''
	},
	networks: {
		route: 'networks',
		label: 'My Networks',
		icon: 'fal fa-chart-network'
	},
	newRouteParam: 'new',
	passwordReset: {
		route: 'password-reset',
		label: 'password-reset',
		icon: ''
	},
	returnUrlLabel: 'returnUrl',
	strategize: {
		route: 'strategize',
		label: 'Strategize',
		icon: '',
		svg: 'assets/img/icons/strategize_light.svg',
		svgActive: 'assets/img/icons/strategize_light.svg'
	},
	sso: {
		tokenGetLabel: 'sso',
		portalGetLabel: 'portal',
		redirectRouteLabel: 'redirect'
	},
	strategizeCost: {
		route: 'strategize/cost',
		label: 'Patient Cost',
		icon: 'fas fa-chart-network'
	},
	aco: {
		route: MedicalEntityTypeConfigs.aco.routeString,
		label: MedicalEntityTypeConfigs.aco.label,
		icon: MedicalEntityTypeConfigs.aco.icon
	},
	homehealth: {
		route: NpiTypeConfigs.homehealth.routeString,
		label: NpiTypeConfigs.homehealth.label,
		icon: NpiTypeConfigs.homehealth.icon
	},
	hospice: {
		route: NpiTypeConfigs.hospice.routeString,
		label: NpiTypeConfigs.hospice.label,
		icon: NpiTypeConfigs.hospice.icon
	},
	hospital: {
		route: NpiTypeConfigs.hospital.routeString,
		label: NpiTypeConfigs.hospital.label,
		icon: NpiTypeConfigs.hospital.icon
	},
	physicianGroup: {
		route: NpiTypeConfigs.physiciangroup.routeString,
		label: NpiTypeConfigs.physiciangroup.label,
		icon: NpiTypeConfigs.physiciangroup.icon
	},
	physician: {
		route: NpiTypeConfigs.physician.routeString,
		label: NpiTypeConfigs.physician.label,
		icon: NpiTypeConfigs.physician.icon
	},
	skilledNursing: {
		route: NpiTypeConfigs.skilledNursing.routeString,
		label: NpiTypeConfigs.skilledNursing.label,
		icon: NpiTypeConfigs.skilledNursing.icon
	},
	unauthorized: {
		route: 'unauthorized',
		label: 'Unauthorized',
		icon: ''
	}
};

// tslint:disable-next-line: camelcase
export const LOCAL_STORAGE_KEY = {
	JWT: 'excel_health_access_token',
	ACO_YEAR: 'trella_health_aco_year',
	ANALYZE_PAGE: 'excel_health_analyze',
	ANALYZE_PAGE_VIEW: 'excel_analyze_page_view_chart_view',
	ANALYZE_PAGE_RISK_ADJUSTED_VIEW: 'trella_analyze_page_risk_adj_chart_view',
	ASSESS_TAB: 'assess_tab',
	STRATEGIZE_TAB: 'strategize_tab',
	STRATEGIZE_COST_TAB: 'strategize_cost_tab',
	JOURNEY_TAB: 'journey_tab',
	JOURNEY_ACO_TAB: 'journey_aco_tab',
	EXPLORE_TAB: 'explore_tab',
	EXPLORE_VIEW: 'explore_view',
	EXPLORE_SAVED_VIEW: 'explore_saved_view',
	SCORE_CARD_TABS: 'recent_score_cards_v2',
	MOSAIC_ACO_DASHBOARD_TAB: 'trella_mosaic_aco_dashboard_tab',
	MOSAIC_NETWORKS_TAB: 'trella_mosaic_networks_tab',
	MOSAIC_NETWORKS_VIEW_NETWORKS_NPIGROUP: 'trella_mosaic_networks_view_networks_npigroup',
	MOSAIC_NETWORKS_VIEW_NETWORKS_PROVIDER: 'trella_mosaic_networks_view_networks_provider',
	SHOW_SELECTED: 'show_selected',
	ANALYZE_DISPLAY: 'analyze_display',
	SSO_SOURCE: 'trella_sso_source',
	SSO_AUTH: 'trella_sso_auth',
	SSO_CLIENTID: 'trella_sso_clientid',
	RETURN_URL: 'trella_mosaic_returnurl'
};

export type RESET_LIST_KEYS =
	  'RELEASE_2020_08_26'
	| 'RELEASE_2020_09_30'
	| 'RELEASE_2020_12_02'
	| 'RELEASE_2021_03_31'
	| 'RELEASE_2021_05_26'
	| 'RELEASE_2022_03_30';

export const RESET_LISTS: { [key in RESET_LIST_KEYS]: ResetKeyItem } = {
	// eslint-disable-next-line camelcase
	RELEASE_2020_08_26: {
		straightKeys: [LOCAL_STORAGE_KEY.EXPLORE_VIEW],
		userIdPrependedKeys: [LOCAL_STORAGE_KEY.EXPLORE_SAVED_VIEW]
	},
	RELEASE_2020_09_30: {
		straightKeys: [LOCAL_STORAGE_KEY.SHOW_SELECTED],
		userIdPrependedKeys: []
	},
	RELEASE_2020_12_02: {
		straightKeys: [LOCAL_STORAGE_KEY.EXPLORE_VIEW],
		userIdPrependedKeys: [LOCAL_STORAGE_KEY.EXPLORE_SAVED_VIEW]
	},
	RELEASE_2021_03_31: {
		straightKeys: [LOCAL_STORAGE_KEY.EXPLORE_VIEW],
		userIdPrependedKeys: [LOCAL_STORAGE_KEY.EXPLORE_SAVED_VIEW]
	},
	RELEASE_2021_05_26: {
		straightKeys: [LOCAL_STORAGE_KEY.EXPLORE_VIEW],
		userIdPrependedKeys: [LOCAL_STORAGE_KEY.EXPLORE_SAVED_VIEW]
	},
	RELEASE_2022_03_30: {
		straightKeys: [LOCAL_STORAGE_KEY.EXPLORE_VIEW],
		userIdPrependedKeys: [LOCAL_STORAGE_KEY.EXPLORE_SAVED_VIEW]
	}
};

export interface ResetKeyItem {
	straightKeys?: string[];
	userIdPrependedKeys?: string[];
}

export enum PROVIDER_TYPE {
	physician = 'physician',
	facility = 'facility',
	hospice = 'hospice',
	homehealth = 'homehealth',
	skilledNursing = 'skilledNursing',
	noProvider = 'noProvider',
	hospital = 'hospital',
	inpatient = 'inpatient'
}

export const DEFAULT_CHART_HEIGHT = 450;

export interface JWT_DECODE {
	userNo: number;
	uID: string;
	username: string;
	customerID: number;
	isManager: string;
	email: string;
}

export enum RAW_VALUE {
	negativeOne = '-1',
	negativeTwo = '-2',
	negativeThree = '-3',
	negativeFour = '-4',
	zero = '0'
}

export enum AVERAGE {
	Above = 'Above',
	Below = 'Below',
	None = 'None',
	Equal = 'Equal'
}

export enum ComparisonColors {
	TextAbove = 'text-danger',
	TextBelow = 'text-success',
	TextEqual = 'average-transparent',
	NoColor = '',
	BackGroundAbove = 'bg-danger',
	BackGroundBelow = 'bg-success',
	BackGroundNeutral = 'bg-primary'
}

export enum SHARE_LEVELS {
	PRIVATE = 1,
	COMPANY = 2,
	CUSTOM = 3
}

export const MAX_NUMBER_OF_STARS = 5;

export const ALLOWED_RATING_ARRAY = [PROVIDER_TYPE.facility, PROVIDER_TYPE.homehealth];

// Note: The list below excludes FACILITY because that is the default for INP, OUTP, etc.
export const PROVIDER_TYPE_MAP = {
	HHA: ROUTE_CONSTANTS.homehealth.route,
	HOS: ROUTE_CONSTANTS.hospice.route,
	PHYS: ROUTE_CONSTANTS.physician.route
};

export const STATES_AND_ABBREVIATIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];

export enum MODAL_ACTION {
	NEW = 'new',
	EDIT = 'edit'
}

export const SNOWFLAKE_MAX_TIMEOUT = 100931731;
