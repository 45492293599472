import { Injectable } from '@angular/core';
import { set as _set, get as _get } from 'lodash';
import { LOCAL_STORAGE_KEY } from '../shared.constants';
import { Router } from '@angular/router';
import { Selected } from '../models/selected';
import { Filter } from '@appcore/models/filter.model';

@Injectable({
	providedIn: 'root'
})
export class ShowSelectedService {
	constructor(private router: Router) {}

	getSelected(filters: Filter[], reportName: string): Selected {
		const storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SHOW_SELECTED));
		const result = new Selected();
		if (!storage) {
			result.showSelected = false;
			result.selectedRows = [];
			return result;
		}
		const storedValues = _get(storage, this.getShowSelectedPath(filters, reportName), {});
		result.showSelected = _get(storedValues, 'toggle', false);
		result.selectedRows = _get(storedValues, 'selectedNpis', []);
		return result;
	}

	setShowSelectedStorage(key: string, value: any, filters: Filter[], reportName: string) {
		if(!reportName)
			return;
		
		const storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SHOW_SELECTED)) || {};
		const fullPath = [...this.getShowSelectedPath(filters, reportName), key];
		_set(storage, fullPath, value);
		localStorage.setItem(LOCAL_STORAGE_KEY.SHOW_SELECTED, JSON.stringify(storage));
	}

	removeSelected(selectionsToRemove: string[], filters: Filter[], reportName: string) {
		const selection = this.getSelected(filters, reportName);
		selection.selectedRows = selection.selectedRows.filter(sr => !selectionsToRemove.includes(sr));

		this.setShowSelectedStorage('selectedNpis', selection.selectedRows, filters, reportName);
	}

	private getShowSelectedPath(filters: Filter[], reportName: string) {
		const route = this.router.url
			.split('/')
			.filter(x => x !== '/')
			.slice(1)
			.join('_');
		const gridID = (filters && filters[0] && 'NPIGROUP_'.concat(filters[0].modelJson)) || 'ALL';
		return [route, gridID, reportName];
	}
}
