import { Injectable } from '@angular/core';
import { Tile } from '../models/tile.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AcoTileService {
	public readonly riskAdjustedTileChange: Observable<void>;
	riskAdjustedTiles: Tile[] = [];
	
	private _riskAdjustedTileChange: Subject<void> = new Subject();
	
	constructor() {
		this.riskAdjustedTileChange = this._riskAdjustedTileChange.asObservable();
	}

	isDoubleHeight(tile: Tile): boolean {
		return tile?.height === 2;
	}

	isRiskAdjusted(tile: Tile): boolean {
		return this.riskAdjustedTiles.some(x => this.tilesAreEqual(x, tile));
	}

	isSingleHeight(tile: Tile): boolean {
		return tile?.height === 1;
	}

	setRiskAdjusted(tile) {
		if (!tile?.hasRiskAdjustment) 
			return;
		
		const riskAdjTile = this.riskAdjustedTiles.find(x => this.tilesAreEqual(x, tile));
		if (!riskAdjTile) {
			this.riskAdjustedTiles.push(tile);
			this._riskAdjustedTileChange.next(null);
			return;
		}
		this.riskAdjustedTiles = this.riskAdjustedTiles.filter(x => !this.tilesAreEqual(x, tile));
		this._riskAdjustedTileChange.next(null);
	}

	private tilesAreEqual(t1: Tile, t2: Tile): boolean {
		return (t1.acoId === t2.acoId || t1.networkId === t2.networkId) && t1.drillDownType === t2.drillDownType;
	}
}
