import { SimpleAco } from './simple-aco';
import { NetworkResponse } from '../../api/network.api';
import { NETWORK_TYPE } from './network';

export class NetworkAcoOption {
	id:string;
	name: string;
	isUserDefined: boolean;
	markets: string[];
	/**
	 * @deprecated - Only user network options are valid
	 */
	acoId?: string;
	networkId?: number;
	networkType?: NETWORK_TYPE;

	constructor() {
		this.name = 'Select a Network';
		this.isUserDefined = true;
		this.markets = [];
	}

	static fromAco(aco: SimpleAco) {
		const response = new NetworkAcoOption();
		response.id = aco.id;
		response.name = aco.name;
		response.isUserDefined = false;
		response.markets = aco.markets;
		response.acoId = aco.id;

		return response;
	}

	static fromNetwork(network: NetworkResponse) {
		const response = new NetworkAcoOption();
		response.id = network.id.toString();
		response.name = network.name;
		response.isUserDefined = true;
		response.markets = network.markets;
		response.networkId = network.id;
		response.networkType = network.networkType;

		return response;
	}
}
