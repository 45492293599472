import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReportDataRequest } from './config.api';
import { ApiService } from './api.service';
import { Metric } from '../shared/models/metric';
import { FormattedResult } from '@appcore/interfaces/formatted-result.interface';

@Injectable({
	providedIn: 'root'
})
export class GridDataApi {
	_controllerName = 'GridData';
	
	constructor(private api: ApiService) {}

	post(configName: string, params: ReportDataRequest): Observable<ConfigDataResponse> {
		return this.api.post<ConfigDataResponse>(this._controllerName, configName, params, null, true);
	}
}

export class ConfigDataResponse {
	additionalMetrics: Metric[];
	data: FormattedResult[];
	footer: FormattedResult;
	totalRows: number;
}
