
import { DisplayComponentType } from '../../../page-model/page-model';
import { DataDisplayComponent } from '../../../../shared/models/display';
import {
	FILTER_TYPE
} from '@appcore/enums/filter-type.enum';

const ACO_DESTINATIONS_GRIDS: DataDisplayComponent[] = [
	{
		configNames: [
			'aco_destinations_hospitals_total',
			'aco_destinations_hospitals_inf',
			'aco_destinations_hospitals_outp',
			'aco_destinations_hospitals_er_obs'],
		providerType: 'aco',
		type: DisplayComponentType.Grid,
		staticFilters: [
			{ filterType: FILTER_TYPE.HARD_CODED, modelJson: 'HOSPITAL', paramName: '@destinationType' }
		]
	},
	{
		configNames: ['aco_destinations_skilled_nursings'],
		filters: [
			{
				filter: { filterType: FILTER_TYPE.HARD_CODED, modelJson: '', paramName: '@rowFilter' },
				key: 'AcoDestinationsSNFDestinationTypeGrouper',
				values: [
					{
						key: 'All',
						value: 'Total'
					},
					{
						key: 'Inpatient to SNF',
						value: 'INP'
					},
					{
						key: 'Community to SNF',
						value: 'Com'
					}
				]
			},
		],
		providerType: 'aco',
		type: DisplayComponentType.Grid,
		staticFilters: [
			{ filterType: FILTER_TYPE.HARD_CODED, modelJson: 'SNF', paramName: '@destinationType' }
		]
	},
	{
		configNames: ['aco_destinations_home_healths'],
		filters: [
			{
				filter: { filterType: FILTER_TYPE.HARD_CODED, modelJson: '', paramName: '@rowFilter' },
				key: 'AcoDestinationsHHADestinationTypeGrouper',
				values: [
					{
						key: 'All',
						value: 'Total'
					},
					{
						key: 'Inpatient to HHA',
						value: 'INP'
					},
					{
						key: 'Inpatient to SNF to HHA',
						value: 'INP_SNF'
					},
					{
						key: 'Community to HHA',
						value: 'Com'
					}
				]
			},
		],
		providerType: 'aco',
		type: DisplayComponentType.Grid,
		staticFilters: [
			{ filterType: FILTER_TYPE.HARD_CODED, modelJson: 'HHA', paramName: '@destinationType' }
		]
	},
	{
		configNames: ['aco_destinations_hospices'],
		filters: [
			{
				filter: { filterType: FILTER_TYPE.HARD_CODED, modelJson: '', paramName: '@rowFilter' },
				key: 'AcoDestinationsHosDestinationTypeGrouper',
				values: [
					{
						key: 'All',
						value: 'Total'
					},
					{
						key: 'Inpatient to Hospice',
						value: 'INP'
					},
					{
						key: 'Community to Hospice',
						value: 'Com'
					}
				]
			},
		],
		providerType: 'aco',
		type: DisplayComponentType.Grid,
		staticFilters: [
			{ filterType: FILTER_TYPE.HARD_CODED, modelJson: 'HOSPICE', paramName: '@destinationType' }
		]
	}
];

export const ACO_DESTINATIONS = {
	title: 'Destinations',
	id: 'Destinations',
	configuration: {
		TOOLBARS: [],
		CHART_VIEW: [...ACO_DESTINATIONS_GRIDS],
		GRID_VIEW: [...ACO_DESTINATIONS_GRIDS],
		showPeriodDrowpdown: true
	}
};
