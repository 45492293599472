/* eslint-disable guard-for-in */
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { ConfigApi, ReportDataRequest } from '../../api/config.api';
import { Subject, Observable, of, forkJoin } from 'rxjs';
import { tap, delay, switchMap, debounceTime, takeUntil } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { set as _set, get as _get } from 'lodash';
import { ColumnSelectEvent } from '../../shared/models/column-select-event.model';
import { AddToNetworkModalComponent } from '../../main/explore/add-to-network-modal/add-to-network-modal.component';
import { GridDataApi, ConfigDataResponse } from '../../api/gridData.api';
import { Metric } from '../../shared/models/metric';
import { DevInfoDialogComponent } from '../dev-info-dialog/dev-info-dialog.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { GridUpdateOptions } from '../../shared/models/grid-update-options';
import { ShowSelectedService } from '../../shared/services/show-selected.service';
import { GridInfoRequestHandler } from './grid-info-request-handler';
import { MosaicGridExportService } from 'src/app/shared/services/mosaic-grid-export.service';
import { ModelNetworkService } from 'src/app/shared/services/model-network.service';
import { SNOWFLAKE_MAX_TIMEOUT } from 'src/app/shared/shared.constants';
import { AcoPeriodService } from 'src/app/shared/services/aco-period.service';
import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { NetworkFilter } from 'src/app/shared/models/network';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	Filter
} from '@appcore/models/filter.model';
import { Utils } from '@appcore/helpers/Utils';
import {
	FormattedData
} from '@appcore/interfaces/formatted-data.interface';
import { GridInfo } from '@appcore/models/grid-info.model';
import { QueryOptions } from '../../shared/models/query-options';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { NpiType } from '@appcore/enums/npi-type.enum';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { FilterSelection } from '@appcore/models/filter-selection.model';
import { GridColumn } from '@appcore/models/grid-column.model';
import { AlertDialogComponent } from '@appcore/components/alert-dialog/alert-dialog.component';
import { ConfigType } from '@appcore/enums/config-type.enum';
import { GridSortEvent } from '@appcore/models/grid/grid-sort-event.model';
import { FilterSelectionService } from '@appcore/services/filter-selection.service';
import { GridExportService } from '@appcore/services/grid-export.service';
import { AlertDialogService } from '@appcore/services/alert-dialog.service';
import { GridSortService } from '@appcore/services/grid-sort.service';
import { GridService } from '@appcore/services/grid.service';
import { GRID_COMMAND } from '@appcore/enums/grid-command.enum';
import { subscribeToKey } from '@appcore/models/keyed';
import { GridExport } from '@appcore/models/grid/grid-export.model';
import { DialogButtonType } from '@appcore/enums/dialog-button-type.enum';
import { FormattedResult } from '@appcore/interfaces/formatted-result.interface';
import { GridColumnType } from '@appcore/enums/grid-column-type.enum';
import { ProviderIds } from '@appcore/models/npi/provider-ids.model';
import { ConfigColumnModel } from '@appcore/interfaces/config-column-model.interface';
import { DetailRowType } from '@appcore/enums/detail-row-type.enum';

@Component({
	selector: 'mosaic-grid',
	templateUrl: './mosaic-grid.component.html',
	styleUrls: ['./mosaic-grid.component.scss']
})
export class MosaicGridComponent extends ComponentWithSubscription implements OnInit, OnDestroy, GridInfoRequestHandler {
	@Input() disabled = false;
	@Input() filterChangeFunc: (value: Filter[]) => void;
	@Input() filterOptions: FilterSelection[];
	@Input() filters: Filter[];
	@Input() hasShowSelectedToggle = false;
	@Input() key = Utils.generateGuid();
	@Input() networkFilter: NetworkFilter;
	@Input() orderedColumnNames: string[] = [];
	@Input() originalColumns: GridColumn[] = [];
	@Input() primaryProviderType: string;
	@Input() reportNameFunc: (reportName: string) => void;
	@Input() reportNames: string[];
	@Input() resetPaging$ = new Subject();
	@Input() resetSelected$ = new Subject();
	@Input() rowLinkFactory: (key: Record<string,FormattedData>, info: GridInfo) => string;
	@Input() showSelected = false;
	@Input() searchPlaceholderText = 'Search Name, NPI, Location or Specialty';
	@Input() updateData?: Subject<GridUpdateOptions> = new Subject<GridUpdateOptions>();
	@Input() visibleColumns: GridColumn[] = [];

	@Output() filterChangeFuncChange = new EventEmitter();
	@Output() gridEvent: EventEmitter<ColumnSelectEvent> = new EventEmitter();
	@Output() originalColumnsChange = new EventEmitter();
	@Output() reportNameFuncChange = new EventEmitter();
	@Output() selectedChange = new Subject();

	@ViewChild(DevInfoDialogComponent, { static: false }) devInfoDialogComponent: DevInfoDialogComponent;
	@ViewChild(AddToNetworkModalComponent) addToNetworkModalComponent: AddToNetworkModalComponent;
    @ViewChild(AlertDialogComponent) alertDialog: AlertDialogComponent;

	configType = ConfigType.Grid;
	gridInfos: GridInfo[] = [];
	selectedRows: string[] = [];
	loading = false;
	ondataChange = new Subject<GridInfo>();
	selectedFilters: Map<string, Filter> = new Map<string, Filter>();
	selectedGridInfo: GridInfo;

	private customColumns: number[] = [];
	private searchTextFilter: any = null;

	private _sortEvent?: GridSortEvent;
	private _queryOptions: QueryOptions = new QueryOptions();

	constructor(
		private configApi: ConfigApi,
		private gridDataApi: GridDataApi,
		private userFeedbackService: UserFeedbackService,
		private authenticationService: AuthenticationService,
		private filterSelection: FilterSelectionService,
		private showSelectedService: ShowSelectedService,
		private gridExportService: GridExportService,
		private mosaicGridExportService: MosaicGridExportService,
		private alertDialogService: AlertDialogService,
		private gridSortService: GridSortService,
		private gridService: GridService,
		private modelNetworkService: ModelNetworkService,
		private acoPeriodService: AcoPeriodService,
		private acoDashboardService: AcoDashboardService
	) {
		super();
	}

	ngOnInit() {
		this.filterChangeFunc = (value: Filter[]) => this.setFilters(value);
		this.filterChangeFuncChange.emit(this.filterChangeFunc);

		of(null)
			.pipe(delay(0))
			.subscribe(() => {
				this.reportNameFunc = (value: string) => (this.reportNames = [value]);
				this.reportNameFuncChange.emit(this.reportNameFunc);
			});

		this.modelNetworkService.updateShowSelected.subscribe(() => {
			this.updateShowSelected();
		});

		this.gridService.addToNetworkRequested.pipe(takeUntil(this.ngUnsubscribe)).subscribe(request => {
			if (request?.key === this.key) 
				this.addToNetwork(request.value);
			
		});

		this.subscribe(this.gridService.commandIssued, this.key, event => {
			this.gridEvent.emit({
				event,
				npiType: this.selectedGridInfo.npiType as NpiType,
				npi: event.dataItem[this.selectedGridInfo.npiField] && event.dataItem[this.selectedGridInfo.npiField].value
			});
			if (event.command === GRID_COMMAND.select) {
				this.showSelectedService.setShowSelectedStorage(
					'selectedNpis',
					event.dataItem,
					this.filters,
					this.selectedGridInfo.reportName
				);
				this.updateShowSelected();
			}
		});


		this.subscribe(this.gridService.childSubscriptionSet, this.key, childKey => {

			this.getGridDetailConfig().subscribe(
				config => {
					this.gridService.setChildGridInfo(this.key, new GridInfo(config, this.getDetailReportName()));
				}
			);

			this.subscribe(this.gridService.detailsRequested, childKey, dataItem => {

				this.subscribe(this.gridService.queryOptionsChanged, childKey, childQueryOptions => {
					this.getDetailData(childKey, dataItem, childQueryOptions);
				});
				
				this.getDetailData(childKey, dataItem);
			});
		});

		this.subscribe(this.gridService.refreshRequested, this.key, info => {
			this.selectedGridInfo = info;
			this.originalColumns = info.originalColumns;
			this.updateData.next(null);
		});

		this.subscribe(this.gridService.searchRequested, this.key, searchText => {
			this.searchTextFilter = this.getSearchTextFilter(searchText);
			this.resetGridToFirstPage();
			this.updateData.next(null);
		});

		this.subscribe(this.gridService.showSelectedToggled, this.key, value => this.showSelectedToggled(value));

		subscribeToKey(this.gridService.eButtonClicked.pipe(takeUntil(this.ngUnsubscribe)), this.key, () => {
			this.devInfoDialogComponent.open();
		});

		this.subscribe(this.gridService.selectionsChanged, this.key, selections => {
			this.selectedRows = selections;
			this.selectedChange.next(selections);
		});

		this.updateData
			.pipe(
				takeUntil(this.ngUnsubscribe),
				debounceTime(1000), // TODO: This should probably be configurable so that some grids can refresh instantly
				tap((options: GridUpdateOptions) => {
					const customColumns = options?.customColumns;
					this.customColumns = customColumns || this.customColumns || [];
					if (options?.sortEvent) 
						this._sortEvent = options.sortEvent;
					
				}),
				switchMap(() => {
					if (!this.haveConfigsChanged()) 
						return of([]);
					

					return forkJoin(this.reportNames.map(name => this.configApi.getGridConfig(name, this.primaryProviderType)));
				}),
				tap(configs => {
					if (!(configs && this.haveConfigsChanged())) {
						this.applySort();
						return;
					}

					const newInfos = this.reportNames.map(name => {
						const index = this.reportNames.indexOf(name);
						const config = configs[index];
						return new GridInfo(config, name);
					});
					this.gridService.allowEButton(this.key, this.authenticationService.hasEButtonPermission());
					this.selectedGridInfo = newInfos[0];
					this._queryOptions.take = this.selectedGridInfo.take || this._queryOptions.take;
					this.originalColumns = this.selectedGridInfo.originalColumns;
					this.originalColumnsChange.emit(this.originalColumns);
					this.gridInfos = newInfos;
					this.applySort();
					this.selectedGridInfo.columns = this.sortColumns(this.selectedGridInfo.columns);
					this.setHiddenProperty();
					this.ondataChange.next(this.selectedGridInfo);
					this.loading = true;
				}),
				switchMap(() => this.getGridData())
			)
			.subscribe(
				data => {
					this.setData(data);
					this.loading = false;
				},
				err => {
					console.error(err);
					if (err === 'Timeout') {
						this.showTimeoutErrorMessage();
						this.gridSortService.sort(this.key, []);
					} else 
						this.showErrorMessage();
					
					this.loading = false;
				}
			);

		this.resetPaging$.subscribe(_ => {
			this.resetGridToFirstPage();
			const focusEls = document.getElementsByClassName('k-link');
			Array.from(focusEls).forEach(el => {
				(el as HTMLElement).blur();
			});
		});

		this.resetSelected$.subscribe(_ => {
			this.gridService.clearSelected(this.key);
		});

		this.filterSelection.selectedFilter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if (data && this.filterOptions && this.filterOptions.find(s => s.key === data.key)) {
				this.selectedFilters.set(data.key, data.filter);
				this.updateData.next(null);
			}
		});

		this.subscribe(this.gridService.queryOptionsChanged, this.key, event => {
			this._queryOptions = event;
			this.handleSortEvent({ sortDescriptors: event.sort });
		});

		this.subscribe(this.gridService.clearAllSelected, this.key, clear => {
			if (this.modelNetworkService.isGrowthOpen()) 
				this.modelNetworkService.setClearAll(clear || false);
			
			this.selectedRows = [];
			this.showSelectedService.setShowSelectedStorage('selectedNpis', [], this.filters, this.selectedGridInfo?.reportName);
			this.updateShowSelected();
		});

		this.subscribe(this.gridSortService.sortRequested, this.key, event => {
			this.handleSortEvent(event);
		});

		this.mosaicGridExportService.register(this);
		this.gridExportService.configure((gridInfo: GridInfo) => this.mosaicGridExportService.getExportData(gridInfo));
	}

	applySort() {
		if (this._sortEvent) {
			this._queryOptions.sort = this._sortEvent.sortDescriptors;
			this.gridSortService.sort(this.key, this._queryOptions.sort);
		}
	}

	handleSortEvent(e: GridSortEvent) {
		if (this.gridInfos?.length) {
			this._queryOptions.sort = e.sortDescriptors;
			this._sortEvent = e;
		}
	}

	canHandle(gridInfo: GridInfo) {
		return this.selectedGridInfo?.reportName === gridInfo.reportName;
	}

	handleGridInfoRequest(gridInfo: GridInfo): Promise<GridExport> {
		const message = this.showSelected
			? 'All selected records across all pages will be exported.'
			: 'Up to 5,000 records will be exported.';
		this.alertDialog.open(`Exporting ${gridInfo.title}`, message, [{ buttonType: DialogButtonType.Cancel }], true, true);

		const request = this.getDataRequest();
		request.skipAllCounts = true;
		request.overrideTimeout = SNOWFLAKE_MAX_TIMEOUT;
		request.gridFilters.take = 5000;
		request.type = ConfigType.Grid;

		return new Promise<GridExport>((resolve, reject) => {
			this.gridDataApi.post(gridInfo.reportName, request as ReportDataRequest).subscribe(
				response => {
					this.alertDialog.close(DialogButtonType.Ok);
					const data: GridExport = { gridInfo, result: this.extractGridData(response) };
					resolve(data);
				},
				error => {
					reject(error);
				}
			);

			this.alertDialogService.closed.subscribe(result => {
				if (result && result.dialog === this.alertDialog && result.result === DialogButtonType.Cancel) 
					resolve(GridExport.withNoData(gridInfo));
				
			});
		});
	}

	showErrorMessage() {
		this.userFeedbackService.showUnexpectedError();
	}

	showTimeoutErrorMessage() {
		this.userFeedbackService.showError('The selected list was too large. Please add filters or reduce the number of selected metrics.');
	}

	setFilters(value: Filter[]) {
		this.filters = value;
	}

	isColumnInData(column: GridColumn, data: FormattedResult[]) {
		// will return true if there is no data.
		return !data.length || (data.length && data[0].hasOwnProperty(column.field));
	}

	// TODO: rewrite to be pure function
	sortAdditionalColumns(customGridColumns: GridColumn[]): GridColumn[] {
		const tempColumns: GridColumn[] = Utils.deepClone(customGridColumns);
		this.customColumns = this.customColumns.filter(col => tempColumns.some(tc => tc.uniq === col));

		this.customColumns.forEach(col => {
			const cloned = Utils.deepClone(tempColumns.find(x => x.uniq === col));
			if (cloned) 
				customGridColumns[this.customColumns.findIndex(x => x === col)] = cloned;
			
		});
		return customGridColumns;
	}

	filterColumnsWithNoData(columns: GridColumn[], data: FormattedResult[]): GridColumn[] {
		if (!data || !data.length) 
			return columns;
		

		const copy = [...columns];
		for (const i in copy) {
			if (this.isColumnInData(copy[i], data)) 
				continue; // the column is in the data, no need to remove it
			
			if (copy[i].columns && copy[i].columns.length) 
				copy[i].columns = this.filterColumnsWithNoData(copy[i].columns, data);
			

			if (copy[i].columns && !copy[i].columns.length) 
				columns = columns.filter(c => c !== copy[i]); // filter out column
			 else 
				continue;
			

			if (!this.isColumnInData(copy[i], data)) 
				columns = columns.filter(c => c !== copy[i]); // filter out column
			
		}
		return columns;
	}

	resetGridToFirstPage() {
		this._queryOptions.skip = 0; // Reset to grid page 0
	}

	// TODO: Move all this show selected logic into a service
	getSearchTextFilter(searchText: string) {
		if (!searchText || !searchText.length) 
			return null;
		
		const quotedPhrase = /"(.*?)"/g;
		const searchPhrase = (searchText.match(quotedPhrase) || []).map(x => x.replace(/["]/g, '').trim());
		const searchWords = searchText
			.replace(quotedPhrase, '')
			.trim()
			.split(' ')
			.map(x => x.trim());

		const searchParms = searchPhrase.concat(searchWords);

		const flattenedColumns = this.getFlattenedColumns();
		const searchable = flattenedColumns.filter(x => x.filterable && x.columnType !== GridColumnType.preheader).map(x => x.field);

		const filterDescriptor = searchParms.map(word =>
			searchable.map(field => ({
				field,
				operator: 'contains',
				value: word
			}))
		);

		const orFilters = filterDescriptor.map(filters => ({
			logic: 'or',
			filters
		}));

		const newFilter = {
			logic: 'and',
			filters: orFilters
		};

		return newFilter;
	}

	getFlattenedColumns(): GridColumn[] {
		return this.selectedGridInfo.columns.reduce(
			(accumulator, currentColumn) => accumulator.concat(currentColumn?.columns?.length ? currentColumn?.columns : currentColumn),
			[]
		);
	}

	showSelectedToggled(checked: boolean) {
		this.showSelected = checked;
		this.showSelectedService.setShowSelectedStorage('toggle', checked, this.filters, this.selectedGridInfo.reportName);
		this.updateData.next(null);
		this.resetGridToFirstPage();
	}

	updateShowSelected() {
		const selected = this.showSelectedService.getSelected(this.filters, this.selectedGridInfo?.reportName);
		this.showSelected = selected.showSelected;

		const selectedCount = this.modelNetworkService.isGrowthOpen()
			? this.modelNetworkService.getSelectedCount()
			: selected.selectedRows?.length ?? 0;

		this.gridService.requestSelection(this.key, selected.selectedRows);
		this.gridService.requestSelectionCount(this.key, selectedCount);
		this.selectedChange.next(selected.selectedRows);
	}

	addToNetwork(npis: ProviderIds[]) {
		this.addToNetworkModalComponent.npis = npis;
		this.addToNetworkModalComponent.open();
	}

    ngOnDestroy() {
		this.mosaicGridExportService.unregister(this);
	}

    private haveConfigsChanged(): boolean {
		const hasConfigs = !!this.gridInfos;
		const hasReportNames = !!this.reportNames;

		if (!hasConfigs) 
			return hasReportNames;
		

		if (!hasReportNames) 
			return true;
		

		if (this.gridInfos.length !== this.reportNames.length) 
			return true;
		

		return this.reportNames.some(name => !this.gridInfos.some(info => info.reportName === name));
	}

	private getGridData(): Observable<ConfigDataResponse> {
		this.updateShowSelected();

		const request = this.getDataRequest();

		this.loading = true;

		return this.gridDataApi.post(this.selectedGridInfo.reportName, request as ReportDataRequest);
	}

	private getGridDetailConfig(): Observable<any>  {
		return this.configApi.getGridConfig(this.getDetailReportName(), this.primaryProviderType);
	}

	private getGridDetailData(dataItem: any, queryOptions: QueryOptions): Observable<ConfigDataResponse> {
		const request = this.getDataDetailRequest(queryOptions);
		request.sqlFilters = this.populateDetailSqlFilters(request.sqlFilters, dataItem);
		return this.gridDataApi.post(this.getDetailReportName(), request as ReportDataRequest);
	}

	private getDataRequest(): Partial<ReportDataRequest> {
		const externalFilters: Filter[] = this.getFiltersCopy();
		const selectedFilters: Filter[] = this.getSelectedFilters();
		const allFilters = externalFilters.concat(selectedFilters);

		const selected = this.showSelectedService.getSelected(this.filters, this.selectedGridInfo.reportName);
		this.selectedRows = selected.selectedRows;
		this.showSelected = selected.showSelected;

		const showSelectedFilter = this.getShowSelectedFilter();
		if (showSelectedFilter) 
			allFilters.push(showSelectedFilter);
		

		const request: Partial<ReportDataRequest> = {
			sqlFilters: allFilters,
			additionalMetrics: this.customColumns,
			gridFilters: { ...this._queryOptions, filter: this.searchTextFilter },
			queryType: this.primaryProviderType,
			type: ConfigType.Grid
		};
		return request;
	}

	private getDataDetailRequest(queryOptions: QueryOptions): Partial<ReportDataRequest> {
		const allFilters: Filter[] = [];

		const selected = this.showSelectedService.getSelected([], this.selectedGridInfo.reportName);
		this.selectedRows = selected.selectedRows;
		this.showSelected = selected.showSelected;

		const showSelectedFilter = this.getShowSelectedFilter();
		if (showSelectedFilter) 
			allFilters.push(showSelectedFilter);
		

		const request: Partial<ReportDataRequest> = {
			sqlFilters: allFilters,
			gridFilters: { ...queryOptions },
			queryType: this.primaryProviderType,
			type: ConfigType.Grid
		};
		return request;
	}

	private setData(response: ConfigDataResponse): void {
		const gridData: GridDataResult = this.extractGridData(response);
		if (!gridData) 
			return;
		

		this.gridService.setData(this.key, gridData);
		this.gridService.setFooter(this.key, response.footer);

		if (this.modelNetworkService.isGrowthOpen()) {
			const growthSelections = gridData.data.filter(f => f.ISINMODEL?.value === '1').map(m => m.npi.value);

			const growthSelectionDelta = this.modelNetworkService.applyDeltaToSelections(growthSelections);

			this.showSelectedService.setShowSelectedStorage(
				'selectedNpis',
				growthSelectionDelta,
				this.filters,
				this.selectedGridInfo.reportName
			);
			this.updateShowSelected();
		}

		const customGridColumns =
			response.additionalMetrics &&
			this.sortAdditionalColumns(response.additionalMetrics.map(this.getGridColumnFromAdditionalMetric));

		const originalColumnsWithData = this.filterColumnsWithNoData([...this.originalColumns], response.data);
		this.selectedGridInfo.columns = this.sortColumns([...originalColumnsWithData, ...customGridColumns]);
		this.setHiddenProperty();

		this.ondataChange.next(this.selectedGridInfo);
	}

	private setDataDetail(childKey: string, response: ConfigDataResponse): void {
		const gridData: GridDataResult = this.extractGridData(response);
		if (!gridData) 
			return;
		

		this.gridService.setData(childKey, gridData);
	}

	private setHiddenProperty(): void {
		if (!(this.visibleColumns && this.visibleColumns.length))
			return;
		

		this.selectedGridInfo.columns.forEach(column => {
			const changedColumn = this.visibleColumns.find(c => c.field === column.field);
			if (changedColumn && changedColumn.field) 
				column.hidden = changedColumn.hidden;
			
		});
	}

	private extractGridData(response: ConfigDataResponse): GridDataResult {
		if (!response) 
			return null;
		

		return {
			data: response.data as any,
			total: response.totalRows
		};
	}

	private getDetailData(childKey: string, dataItem: any, childQueryOptions?: QueryOptions){
		if(!childQueryOptions)
			childQueryOptions = new QueryOptions;
		

		this.gridService.setLoading(childKey, true);
		this.getGridDetailData(dataItem, childQueryOptions).subscribe(
			data => {
				this.setDataDetail(childKey, data);
			},
			err => {
				console.error(err);
				if (err === 'Timeout') {
					this.showTimeoutErrorMessage();
					this.gridSortService.sort(childKey, []);
				} else 
					this.showErrorMessage();
				
			},
			() => {
				this.gridService.setLoading(childKey, false);
			}
		);
	}

	private getFiltersCopy() {
		return this.filters ? [...this.filters] : [];
	}

	private getSelectedFilters(): Filter[] {
		this.updateFilterOptionsFromSelectedFilters();
		const filters = this.getFiltersFromFilterOptions();
		return filters;
	}

	private updateFilterOptionsFromSelectedFilters() {
		if (this.selectedFilters) {
			Array.from(this.selectedFilters.keys()).forEach(key => {
				const selectedFilter: Filter = this.selectedFilters.get(key);
				const existingOption = this.filterOptions.find(f => f.key === key);
				if (existingOption) 
					existingOption.filter = selectedFilter;
				
			});
		}
	}

	private getFiltersFromFilterOptions(): Filter[] {
		if (this.filterOptions && this.filterOptions.length) {
			const mapped = this.filterOptions.map(filterOption => {
				const filter = filterOption.filter;

				// AR - This is kinda whack - It looks like we are using local storage as our method to pass data around. The filter key on the FilterSelection MUST be unique.
				const value = filterOption.values.find(v => v.key === localStorage.getItem(filterOption.key)) || filterOption.values[0];
				if (value) 
					filter.modelJson = value.value;
				
				return filter;
			});
			return mapped;
		}

		return [];
	}

	private getShowSelectedFilter() {
		return this.showSelected
			? this.modelNetworkService.isGrowthOpen()
				? {
						modelJson: JSON.stringify(this.modelNetworkService.getNetworkDelta()),
						filterType: FILTER_TYPE.GROWTH_SHOW_SELECTED
				  }
				: {
						modelJson: JSON.stringify(this.selectedRows),
						filterType: FILTER_TYPE.NPI_LIST
				  }
			: null;
	}

	private getGridColumnFromAdditionalMetric(metric: Metric): GridColumn {
		const columnConfig: Partial<ConfigColumnModel> = {
			title: metric.title,
			field: metric.attributeName,
			width: 175,
			definition: metric.definition,
			uniq: metric.uniq
		};
		return new GridColumn(columnConfig);
	}

	private getDetailReportName() {
		return `${this.selectedGridInfo.reportName}_detail`;
	}

	private sortColumns(columns: GridColumn[]): GridColumn[] {
		if (!this.orderedColumnNames.length) 
			return columns;
		
		const sorted: GridColumn[] = [];
		this.orderedColumnNames.map(n => {
			// TODO: Eventually we need to move the removing of column header brackets to the backend so that we no longer need to use the parseGridColumnHeader function
			const foundColumnIndex = columns.findIndex(c => c.title.toLocaleLowerCase() === Utils.getParsedGridColumnHeader(n).toLocaleLowerCase());
			if (foundColumnIndex > -1) 
				sorted.push(columns.splice(foundColumnIndex, 1)[0]);
			
		});
		return sorted.concat(columns);
	}

	private populateDetailSqlFilters(sqlFilters:any, dataItem: any): any {
		switch (this.selectedGridInfo.detailRowType) {
			case DetailRowType.PhysicianGroup:
				sqlFilters.push({
					filterType: 'PhysicianGroup',
					paramName: '',
					modelJson: `[${dataItem[this.selectedGridInfo.npiField].value}]`
				});
				break;
			case DetailRowType.StateCounty:
				const networkId = this.acoDashboardService.getLastViewedNetworkAco();
				const acoPeriodId = this.acoPeriodService.getLastSelectedPeriod();
				if (!networkId || !acoPeriodId)  
					return; 
				sqlFilters.push({
					filterType: 'Network',
					paramName: '',
					modelJson: `[${networkId}]`
				}, 
				{
					filterType: 'HardCoded',
					paramName: 'acoPeriodId',
					modelJson: acoPeriodId
				}, 
				{
					filterType: 'HardCoded',
					paramName: 'detailState',
					modelJson: dataItem.state.value
				}, 
				{
					filterType: 'HardCoded',
					paramName: 'detailCounty',
					modelJson: dataItem.county.value
				});
				break;
			default:
				break;
		}
		return sqlFilters;
	}
}
