import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpecialtiesApi } from 'src/app/api/specialties.api';

@Injectable({
	providedIn: 'root'
})
export class SpecialtiesService {
	public readonly specialties: Observable<string[]>;
    
    private _specialties: BehaviorSubject<string[]> = new BehaviorSubject(null);
    
    constructor(private specialtiesApi: SpecialtiesApi) {
        this.specialties = this._specialties.asObservable();
    }

    loadSpecialties() {
        return this.specialtiesApi.getTccSpecialties().pipe(
            tap(specialties => this._specialties.next(specialties))
        );
    }
}