import { createReducer, on } from '@ngrx/store';
import { selectNetworkAco } from './aco-dashboard.actions';
import { AcoDashboardState } from './aco-dashboard.state';

export const initialState: AcoDashboardState = new AcoDashboardState();

const _acoDashboardReducer = createReducer(
	initialState,
	on(selectNetworkAco, (state, option) => ({ ...state, selectedNetworkAco: option }))
);

export const acoDashboardReducer = (state, action) => _acoDashboardReducer(state, action);
