import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TrellaFormat } from 'src/app/shared/methods/trella-format';


@Directive({
	selector: '[tile-chart-tooltip]'
})
export class TileChartTooltipDirective implements OnInit {
	@Input() from: number;
	@Input() to: number;
	
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnInit() {
		const difference = this.from - this.to;
		const dollars = TrellaFormat.wholeDollars(difference);
		const percentage = ((difference / this.to)*100).toFixed(2);
		const iconName = difference > 0 ? 'fa-caret-up' : 'fa-caret-down';
		const iconColor = difference > 0 ? 'red' : 'green';

		const icon = this.renderer.createElement('i');
		const text = this.renderer.createText(` ${dollars} / ${percentage}%`);
		this.renderer.addClass(icon, 'fas');
		this.renderer.addClass(icon, 'fa-2x');
		this.renderer.addClass(icon, iconName);
		this.renderer.addClass(icon, 'align-middle');
		this.renderer.addClass(icon, iconColor);
		this.renderer.appendChild(this.el.nativeElement, icon);
		this.renderer.appendChild(this.el.nativeElement, text);
	}
}
