import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { NpiApi } from 'src/app/api/npi.api';
import { AbstractValueAccessor, MakeProvider } from 'src/app/shared/AbstractValueAccessor';
import { Npi } from '@appcore/models/npi/npi.model';

@Component({
	selector: 'mosaic-npi-input-verify',
	templateUrl: './npi-input-verify.component.html',
	styleUrls: ['./npi-input-verify.component.scss'],
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	providers: [MakeProvider(NpiInputVerifyComponent)]
})
export class NpiInputVerifyComponent extends AbstractValueAccessor implements OnInit {
	inputChange = new Subject<any>();
	npiCheckInFlight = false;
	npiVerified = false;

	constructor(private lookupApi: NpiApi) {
		super();
	}

	get showValidNpi() {
		return !this.npiCheckInFlight && this.npiVerified;
	}

	get showInValidNpi() {
		return !this.npiCheckInFlight && !this.npiVerified;
	}

	get npi(): string {
		return this.value;
	}
	set npi(val: string) {
		this.value = val;
	}

	ngOnInit() {
		this.inputChange.pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000)).subscribe(x => {
			this.verifyNpi();
		});

		setTimeout(() => {
			if (this.npi) 
				this.verifyNpi();
			
		});
	}

	onNpiChange() {
		this.inputChange.next(null);
	}

	verifyNpi() {
		if (!this.npi) {
			this.npiVerified = false;
			return false;
		}

		this.npiCheckInFlight = true;
		this.lookupApi
			.getNpiInfo(this.npi)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.npiCheckInFlight = false))
			)
			.subscribe(
				async (info: Npi) => {
					this.npiVerified = !!info;
				},
				x => {
					this.npiVerified = false;
				}
			);
	}
}
