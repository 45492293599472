import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AlignType } from 'src/app/shared/enums/aco-period-alignment-type.enum';
import { ProgramType } from 'src/app/shared/enums/aco-period-type.enum';
import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { NetworkSelectionService } from 'src/app/shared/services/network-selection.service';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	CheckedSelection
} from '@appcore/interfaces/checked-selection.interface';
import { CheckedSelectionGroup } from '@appcore/interfaces/checked-selection-group.interface';

@Component({
	selector: 'trella-aco-details',
	templateUrl: './aco-details.component.html',
	styleUrls: ['./aco-details.component.scss']
})
export class AcoDetailsComponent extends ComponentWithSubscription implements OnInit {
	FILTER_TYPE = FILTER_TYPE;
	counties: CheckedSelection[] = [];
	programType = ProgramType.Dce;
	alignType = AlignType.None;
	showAcoPeriodDrowpdown = false;
	showCounties = false;

	constructor(public acoDashboardService: AcoDashboardService,
		private networkSelectionService: NetworkSelectionService) {
		super();
	}

	ngOnInit() {
		this.acoDashboardService.selectedNetworkAco.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedNetwork => {
			if(selectedNetwork) 
				this.showAcoPeriodDrowpdown = this.networkSelectionService.shouldShowAcoPeriodDropdown(this.programType, selectedNetwork.networkType);
			
		});

		this.acoDashboardService.networkPhysicianCountiesLoaded.pipe(takeUntil(this.ngUnsubscribe)).subscribe(counties => {
			this.counties = counties;
			if (counties){
				this.handleCountiesChanged({filterType: FILTER_TYPE.PHYSICIAN_COUNTIES, selections: []});
				this.showCounties = counties.length && this.showAcoPeriodDrowpdown;
			}
		});
	}

	handleCountiesChanged(selections: CheckedSelectionGroup) {
		this.acoDashboardService.selectCounties(selections.selections.filter(s => s.checked));
	}
}
