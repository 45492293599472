import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { SpecialColors } from '@appcore/constants/constants';

@Directive({
	selector: '[good-or-bad]'
})
export class GoodOrBadDirective implements OnChanges {
	@Input('good-or-bad') isGood: boolean;
	
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isGood) {
			const color = this.isGood? SpecialColors.GoodPercentile : SpecialColors.BadPercentile;
			this.renderer.setStyle(this.el.nativeElement, 'color', color);
		}
	}
}
