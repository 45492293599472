import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
	providedIn: 'root'
})
export class DiagnosticCateogryApi {
	_controllerName = 'DiagnosticCategory';

	constructor(private api: ApiService) {}

	/**
	 * Gets the list of available diagnostic categories
	 */
	getDiagnosticCategories(): Observable<Selection[]> {
		return this.api.get(this._controllerName);
	}

}
