import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';

import { LoginResponse } from '../shared/models/authentication';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NavigationService } from '../shared/services/navigation.service';
import { ROUTE_CONSTANTS } from '../shared/shared.constants';
import { SSOService } from '../shared/services/sso.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ComponentWithSubscription implements OnInit {
	email;
	message;
	returnUrl: string;
	loading = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private navigationService: NavigationService,
		private authenticationService: AuthenticationService,
		private ssoService: SSOService,
		private userFeedbackService: UserFeedbackService
	) {
		super();
	}


	ngOnInit() {
		this.returnUrl = this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.returnUrlLabel];
		this.message = '';

		// If source is already specified, we do not need to stop at this page. Forward to actual login
		const source = this.ssoService.determineSource(this.activatedRoute.snapshot);
		if (source) {
			this.authenticationService.getCognitoClientBySource(source)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(cognitoClient => {
					if (cognitoClient) 
						this.navigationService.navigateToSSO(cognitoClient);
					
					else 
						this.navigationService.navigateToLogin();
					
				});
		}
	}

	login() {
		this.message = '';

		if (!this.email) {
			this.message = 'Email is required';
			return;
		}

		this.loading = true;

		this.userFeedbackService.startSpinner();
		/* Here we navigate regardless of email found or not. If not found we navigate to default Trella login */
		this.authenticationService.getCognitoClientByEmail(this.email)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				cognitoClient => {
					this.navigationService.navigateToSSO(cognitoClient);
					this.userFeedbackService.stopSpinner();
				},
				() => {
					this.loading = false;
					this.onInvalidLogin();
				});
	}

	onInvalidLogin() {
		this.message = 'Incorrect email or password';
	}
}
