// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metric {
  font-size: 72px;
  font-weight: 100;
  line-height: 0.8;
}

.metricSubtitle {
  font-size: 14px;
  font-weight: 700;
}

.county {
  background-color: #f4f4f4;
}

.averages {
  height: 24px;
  width: 200px;
  font-size: 12px;
  font-weight: 700;
}

::ng-deep .k-chart-tooltip {
  background-color: white !important;
  color: black !important;
  font-weight: bold;
  text-align: center;
}

::ng-deep i.red {
  color: #d5839a !important;
}

::ng-deep i.green {
  color: #8ac591 !important;
}

.doubleHeightTitle {
  font-weight: 600;
  font-size: 24px;
  text-align: left;
}

.doubleHeightMetric {
  flex: 1;
  font-size: 56px;
  text-align: right;
  font-weight: 300;
}

.chart__benchmark__tooltip {
  left: 23.1rem;
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/app/components/aco-tile-grid/aco-tile/aco-tile.component.scss"],"names":[],"mappings":"AAEA;EACC,eAAA;EACA,gBAAA;EACA,gBAAA;AADD;;AAIA;EACC,eAAA;EACA,gBAAA;AADD;;AAIA;EACC,yBAAA;AADD;;AAIA;EACC,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AADD;;AAIA;EACC,kCAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;AADD;;AAIA;EACC,yBAAA;AADD;;AAIA;EACC,yBAAA;AADD;;AAIA;EACC,gBAAA;EACA,eAAA;EACA,gBAAA;AADD;;AAIA;EACC,OAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADD;;AAIA;EACC,aAAA;EACA,kBAAA;AADD","sourcesContent":["@use 'appcore/styles/variables' as variables;\n\n.metric {\n\tfont-size: 72px;\n\tfont-weight: 100;\n\tline-height: 0.8;\n}\n\n.metricSubtitle {\n\tfont-size: 14px;\n\tfont-weight: 700;\n}\n\n.county {\n\tbackground-color: #f4f4f4;\n}\n\n.averages {\n\theight: 24px;\n\twidth: 200px;\n\tfont-size: 12px;\n\tfont-weight: 700;\n}\n\n::ng-deep .k-chart-tooltip {\n\tbackground-color: white !important;\n\tcolor: black !important;\n\tfont-weight: bold;\n\ttext-align: center;\n}\n\n::ng-deep i.red {\n\tcolor: #d5839a !important;\n}\n\n::ng-deep i.green {\n\tcolor: #8ac591 !important;\n}\n\n.doubleHeightTitle {\n\tfont-weight: 600;\n\tfont-size: 24px;\n\ttext-align: left;\n}\n\n.doubleHeightMetric {\n\tflex: 1;\n\tfont-size: 56px;\n\ttext-align: right;\n\tfont-weight: 300;\n}\n\n.chart__benchmark__tooltip {\n\tleft: 23.1rem;\n\tposition: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
