import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LOCAL_STORAGE_KEY } from './shared/shared.constants';
import { EnvServiceProvider } from './shared/services/env.service.provider';

import { Amplify } from 'aws-amplify';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { rootReducer, ROOT_REDUCER, metaReducers } from './store/rootReducer';
import { rootEffects } from './store/rootEffects';
import { MosaicComponentsModule } from './components/mosaic-components.module';
import { AuthenticationService } from './shared/services/authentication.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { authInterceptorProviders } from './api/auth-interceptor';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {CrmLogoutComponent} from './crmLogout/crmlogout.component';

// Configure Amplify resources
// eslint-disable-next-line @typescript-eslint/dot-notation
const cfg = window['__env'].awsconfig;
const clientId = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
if (clientId) {
	// eslint-disable-next-line camelcase
	cfg.aws_user_pools_web_client_id = clientId;
	cfg.oauth.cognitoClientId = clientId;
	const authurl = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_AUTH);
	if (authurl) 
		cfg.oauth.domain = authurl;
	
}
Amplify.configure(cfg);
@NgModule({
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ChartsModule,
		ReactiveFormsModule,
		DialogsModule,
		DropDownsModule,
		StoreModule.forRoot(ROOT_REDUCER, { metaReducers }),
		EffectsModule.forRoot(rootEffects),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production
		}),
		MosaicComponentsModule,
		DragDropModule
	],
	declarations: [AppComponent, LoginComponent, LandingComponent, LogoutComponent, PageNotFoundComponent, UnauthorizedComponent, CrmLogoutComponent],
	providers: [EnvServiceProvider, authInterceptorProviders, { provide: ROOT_REDUCER, useValue: rootReducer }],
	bootstrap: [AppComponent]
})
export class AppModule {}
