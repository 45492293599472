import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkAcoOption } from '../../shared/models/network-aco-option';
import StrategyUtils from '../../shared/Utils';

@Component({
	selector: 'mosaic-filterable-list-box',
	templateUrl: './filterable-list-box.component.html',
	styleUrls: ['./filterable-list-box.component.scss']
})
export class FilterableListBoxComponent {
	@Input() options: NetworkAcoOption[];
	@Output() selectOption: Observable<NetworkAcoOption>;
	
	userSearchString = '';
	height = '400px';
	
	private _selectOption: EventEmitter<NetworkAcoOption> = new EventEmitter<NetworkAcoOption>();
	

	constructor() {
		this.selectOption = this._selectOption.asObservable();
	}
}
