import { InjectionToken } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { acoDashboardReducer } from '../main/aco-dashboard/aco-dashboard.reducer';
import { AcoDashboardState } from '../main/aco-dashboard/aco-dashboard.state';
import { exploreReducer, exploreState } from '../main/explore/explore.reducer';

export interface AppState {
	acoDashboardState: AcoDashboardState;
	exploreState: exploreState;
}

export const rootReducer: ActionReducerMap<AppState> = {
	acoDashboardState: acoDashboardReducer,
	exploreState: exploreReducer
};

export const ROOT_REDUCER = new InjectionToken<any>('Root Reducer');

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({ keys: ['acoDashboardState'], rehydrate: true })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
