// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aco-details-element {
  height: 3.2em !important;
}

::ng-deep .aco-period-selector-holder, ::ng-deep #aco-period-selector {
  height: 100% !important;
}

.county-averages-filter {
  height: 100%;
}

::ng-deep .county-averages-filter > .btn.text-input {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/main/aco-dashboard/aco-details/aco-details.component.scss"],"names":[],"mappings":"AAEA;EACI,wBAAA;AADJ;;AAIA;EACI,uBAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AADJ","sourcesContent":["@use 'appcore/styles/variables' as variables;\n\n.aco-details-element {\n    height: 3.2em !important;\n}\n\n::ng-deep .aco-period-selector-holder, ::ng-deep #aco-period-selector {\n    height: 100% !important;\n}\n\n.county-averages-filter {\n    height: 100%;\n}\n\n::ng-deep .county-averages-filter > .btn.text-input {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    font-size: 1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
