import { PORTAL_TYPE_MOSAIC, PROVIDER_TYPE } from 'src/app/shared/shared.constants';


export enum DisplayComponentType {
	Chart = 0,
	Grid = 1,
	Toolbar = 2,
	Insight = 3,
	Sunburst = 4
}

export enum ToolbarToolType {
	NetworkDropdown = 0
}

export interface PageSectionModel {
	title?: string;
	icon?: string;
	collections: Array<ComponentCollection | GridCollection | ChartCollection>;
}

export interface TabSectionModel {
	title: string;
	collections: Array<PageSectionModel>;
}

export interface NavTabSectionModel {
	id: string;
	title: string;
	component?: any; // TODO: this must be a component
	disabled?: boolean;
	loadChildren?: any; // Should only specify loadChildren or component, but not both
	tooltipText?: string;
	// sections: Array<PageSectionModel>;
}

export interface ComponentCollection {
	title?: string;
	type: DisplayComponentType;
}

export interface GridCollection extends ComponentCollection {
	components: Array<GridComponentModel | string>;
	defaultRowProviderTypeFn?: (p: PORTAL_TYPE_MOSAIC) => PROVIDER_TYPE;
	defaultGridAbilities?: any; // TODO: should be GridAbilities, not any
}

export interface GridComponentModel {
	configName: string;
	rowProviderTypeFn?: (p: PORTAL_TYPE_MOSAIC) => PROVIDER_TYPE;
	gridAbilities?: any; // TODO: should be GridAbilities, not any
}

export interface ChartCollection extends ComponentCollection {
	components: Array<string>;
	moveChartDown?: boolean;
	height?: number;
}
