import { MedicalEntityTypeConfig } from '@appcore/models/medical-entity-type-config.model';

export type MedicalEntityTypeKeys = 'aco';

export  const MedicalEntityTypeConfigs: { [key in MedicalEntityTypeKeys]: MedicalEntityTypeConfig } = {
	aco: {
		routeString: 'aco',
		label: 'ACOs',
		labelSingular: 'ACO',
		icon: 'fas fa-hospital-user',
		colorClass: 'text-aco',
		bgClass: 'bg-aco'
	}
};
