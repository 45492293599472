// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./appcore/components/chart/chart-types/bar-chart/bar-chart.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[":host {\n    display: block;\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
