import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TrellaUser } from '../shared/models/user';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import { RESET_LISTS } from '../shared/shared.constants';
import { ApiService } from './api.service';
import { Utils } from '@appcore/helpers/Utils';

@Injectable({
	providedIn: 'root'
})
export class UserApi {
	_controllerName = 'User';
	
	constructor(private api: ApiService, private appCache: ApplicationCacheService) {}

	getCurrent(): Observable<TrellaUser> {
		return this.api.get(this._controllerName, 'Current').pipe(
			tap((result: TrellaUser) => {
				this.setUtilsLoadDate(result);
				this.resetStorage(result.id);
			})
		);
	}

	resetStorage(userId: string) {
		// loop through reset keys
		for (const resetList of Object.keys(RESET_LISTS)) {
			// if a reset key is not set, reset the local storages for that key
			if (localStorage.getItem(resetList) !== '1') {
				const keysToClear = RESET_LISTS[resetList];
				for (const storageKey of keysToClear.straightKeys) 
					localStorage.removeItem(storageKey);
				
				for (const storageKey of keysToClear.userIdPrependedKeys) {
					const userStorageKey = this.appCache.userSpecificKey(storageKey, userId);
					localStorage.removeItem(userStorageKey);
				}
				localStorage.setItem(resetList, '1');
			}
		}
	}

	private setUtilsLoadDate(result: any) {
		// any is TrellaUser
		Utils.loadDate = result.loadDate;
	}
}
