import { ChartConfig } from './chart-config.model';
import { InsightConfigJson } from '../interfaces/insight-config-json.interface';

export class InsightOptions extends ChartConfig {
    constructor(config: InsightConfigJson, reportName: string) {
        super(config, reportName);

        Object.assign(this, config);
    }

    insightDisplayType: string;
    isHigherBetter: boolean;
    isNeutral: boolean;
    riskCategory?: string;
    sourceNpiName?: string;
    tooltip?: string;
}
