import { Injectable } from '@angular/core';
import { NetworkSelectionService } from './network-selection.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { NetworkAcoOption } from '../models/network-aco-option';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

// TODO: Get things from networkselection service as well as aco service and combine the results.
export class NetworkAcoService {
	public readonly networkAcoOptions: Observable<NetworkAcoOption[]>;
	
	private _networkAcoOptions: BehaviorSubject<NetworkAcoOption[]> = new BehaviorSubject([]);
	
	constructor(private networkSelectionService: NetworkSelectionService) {
		this.networkAcoOptions = this._networkAcoOptions.asObservable();
	}


	getOptions(): Observable<NetworkAcoOption[]> {
		return this.networkSelectionService.getNetworks().pipe(
			map(networks => {
				const results = networks.map(network => NetworkAcoOption.fromNetwork(network));
				this._networkAcoOptions.next(results);
				return results;
			})
		);
	}

	invalidateNetworkOptionsCache()
	{
		this._networkAcoOptions = new BehaviorSubject([]);
	}
}
