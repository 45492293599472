import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LOCAL_STORAGE_KEY } from '../shared.constants';


@Injectable({
	providedIn: 'root'
})
export class SSOService {
	sourceParam = 'source';

	public determineSource(route: ActivatedRouteSnapshot): string {
		// Source can be specified in the login PATH, in the URL, or can be stored in a cookie
		const ssoSource = route.paramMap.get(this.sourceParam) || route.queryParamMap.get(this.sourceParam) || this.getSourceFromStorage();

		return ssoSource;
	}

	public storeSSOauth(url: string) {
		const urlparts = this.splitUrl(url);
		const urlAuth = urlparts[1] ? urlparts[1] : '';
		const clientId = urlparts[2] ? urlparts[2] : '';
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_AUTH, urlAuth);
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_CLIENTID, clientId);
	}

	clearSSOSource() {
		localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
		localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
		localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_AUTH);
	}

	getSourceFromStorage() {
		return localStorage.getItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
	}

	splitUrl(url: string): string[] {
		return url.match(/https:\/\/([-_A-Za-z0-9\.]*).*client_id=([-_A-Za-z0-9]*)&?/);
	}

	storeSSOSource(sourceToken: string) {
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_SOURCE, sourceToken);
	}

}
