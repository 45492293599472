import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NetworkResponse } from 'src/app/api/network.api';
import { FILTER_CATEGORY } from '@appcore/constants/constants';

@Component({
	selector: 'mosaic-select-network',
	templateUrl: './select-network.component.html',
	styleUrls: ['./select-network.component.scss']
})
export class SelectNetworkComponent {
	@Input() networkOptions: NetworkResponse[];
	@Output() filterOptionsEvent = new EventEmitter<string>();

	filterCategory = FILTER_CATEGORY;
	optionNone = {
		id: -1,
		name: 'None'
	};
	selectedFilter: any = this.optionNone;
	filters;

	onFilterChange() {
		this.filterOptionsEvent.emit(this.selectedFilter);
	}
}
