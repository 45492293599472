// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insight-wrapper {
  min-width: 350px;
  min-height: 256px;
}

.insight-small-text {
  font-size: 1.2rem;
  min-height: 56px;
}

.info-panel {
  max-width: 500px;
}

.info-text {
  font-size: 1.2rem;
}

.info-wrapper {
  float: right;
  clear: both;
}

.low {
  text-align: center;
  color: #43b02a;
}

.medium {
  text-align: center;
  color: #ff8200;
}

.high {
  text-align: center;
  color: #c63663;
}

.insight-content-wrapper {
  height: 100vh;
  max-height: 200px;
  min-width: 350px;
}

.npi-name-heading {
  font-size: 1.75rem;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.top-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./appcore/components/chart/chart-types/shared-insight.scss","webpack://./appcore/components/chart/chart-types/insight-top/top-insight.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;ACCJ;;ADEA;EACI,iBAAA;EACA,gBAAA;ACCJ;;ADEA;EACI,gBAAA;ACCJ;;ADEA;EACI,iBAAA;ACCJ;;ADEA;EACI,YAAA;EACA,WAAA;ACCJ;;AAdA;EAJI,kBAAA;EACA,cAIoB;AAkBxB;;AAfA;EARI,kBAAA;EACA,cAQoB;AAmBxB;;AAhBA;EAZI,kBAAA;EACA,cAYoB;AAoBxB;;AAjBA;EACI,aAAA;EACA,iBAAA;EACA,gBAAA;AAoBJ;;AAjBA;EACI,kBAAA;AAoBJ;;AAjBA;EACI,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AAoBJ;;AAjBA;EACI,oBAAA;EACA,4BAAA;EACA,qBAAA;EACA,gBAAA;AAoBJ","sourcesContent":[".insight-wrapper {\n    min-width: 350px;\n    min-height: 256px; // same as an insight with a header that takes up 2 lines\n}\n\n.insight-small-text {\n    font-size: 1.2rem;\n    min-height: 56px;\n}\n\n.info-panel {\n    max-width: 500px;\n}\n\n.info-text {\n    font-size: 1.2rem;\n}\n\n.info-wrapper {\n    float: right;\n    clear: both;\n}\n","@use '../shared-insight';\n\n@mixin make-color($colorCode) {\n    text-align: center;\n    color: $colorCode;\n}\n\n.low {\n    @include make-color(#43b02a);\n}\n\n.medium {\n    @include make-color(#ff8200);\n}\n\n.high {\n    @include make-color(#c63663);\n}\n\n.insight-content-wrapper {\n    height: 100vh;\n    max-height: 200px;\n    min-width: 350px;\n}\n\n.npi-name-heading {\n    font-size: 1.75rem;\n}\n\n.line-clamp {\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n}\n\n.top-text {\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 3;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
