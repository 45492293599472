import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUserResponse } from '../models/user';
import { ApplicationCacheService } from '../services/application-cache.service';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '../services/navigation.service';
import { SSOService } from '../services/sso.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {
	constructor(
		private authService: AuthenticationService,
		private appCache: ApplicationCacheService,
		private navigationService: NavigationService,
		private ssoService: SSOService,
        private authenticationService: AuthenticationService
	) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const token = await this.authenticationService.getCognitoToken();
		if (token) {
			// We have a valid token
			if (this.appCache.user) 
				return true;
			
			// Haven't retrieved the current user yet, so do that now
			return this.authService
				.getCurrentUser()
				.then((response: CurrentUserResponse) => {
					const { user } = response;
					if (!user) {
						// Something went wrong
						return this.onAuthError(state, route);
					}
					return true;
				})
				.catch(() => this.onAuthError(state, route));
		}
		return this.onAuthError(state, route);
	}

	async onAuthError(state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
		this.appCache.setReturnUrl(state.url && !state.url.includes('login') ? state.url : null);
		const sourceToken = this.ssoService.determineSource(route);
		const url = await this.authService.getClientIdBasedUrl(sourceToken);
		this.navigationService.navigateToLogin(undefined, url);
		return false;
	}
}
