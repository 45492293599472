import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Selection } from '@appcore/interfaces/selection.interface';
import { NetworkRequest, NETWORK_TYPE } from '../shared/models/network';
import { GrowthRequest } from '../shared/models/growth/growth-request';
import { GrowthResponse } from '../shared/models/growth/growth-response';
import { ProgramType } from '../shared/enums/aco-period-type.enum';
import { AcoPeriod } from '../shared/models/aco-period';
import { AlignType } from '../shared/enums/aco-period-alignment-type.enum';
import { QueryOptions } from '../shared/models/query-options';
import { NpiGroup } from '@appcore/models/npi-group.model';
import { NPI_GROUP_TYPE } from '@appcore/enums/npi-group-type.enum';

@Injectable({
	providedIn: 'root'
})
export class NetworkApi {
	_controllerName = 'Network';

	constructor(private api: ApiService, protected http: HttpClient) {}

	list(): Observable<NetworkResponse[]> {
		return this.api.get(this._controllerName);
	}

	getNames(primaryProviderType: string): Observable<NetworkResponse[]> {
		return this.api.get(this._controllerName, `names?primaryProviderType=${primaryProviderType}`);
	}

	getNetworksWithNpi(npi: string): Observable<NpiResponseWithFlag[]> {
		return this.api.get(this._controllerName, `networksWithNpi/${npi}`);
	}

	addToNetwork(request: AddNpisToGroupRequest) {
		const { groupId } = request;
		return this.api.post(this._controllerName, `add/${groupId}`, request);
	}

	getNetworkAccessLevels(): Observable<NetworkAccessLevelResponse[]> {
		return this.api.get(this._controllerName, 'accessLevels');
	}

	getNetworkTypes(): Observable<NetworkTypeResponse[]> {
		return this.api.get(this._controllerName, 'networkTypes');
	}

	getActiveAcoPeriodSelections(programType: ProgramType, alignType: AlignType = AlignType.None): Observable<Selection[]> {
		return this.api.post(this._controllerName, 'activeAcoPeriodSelections', {programType, alignType});
	}

	getDefaultAcoPeriod(programType: ProgramType): Observable<AcoPeriod> {
		return this.api.post(this._controllerName, 'defaultAcoPeriod', {programType});
	}

	getPhysicianCounties(networkId: number): Observable<Selection[]> {
		return this.api.get(this._controllerName, `physicianCounties/${networkId}`);
	}

	getGrowth(request: GrowthRequest): Observable<GrowthResponse> {
		return this.api.post(this._controllerName, 'growth', request);
	}

	saveGrowth(request: GrowthRequest): Observable<GrowthResponse> {
		return this.api.post(this._controllerName, 'saveGrowth', request);
	}

	addTopX(request: AddTopXNpisRequest): Observable<string[]> {
		const { groupId } = request;
		return this.api.post(this._controllerName, `${groupId}/addtopx`, request);
	}

	remove(networkId: string) {
		return this.api.delete(this._controllerName, networkId);
	}

	removeNpisFromGroup(npis: string[], networkId: number) {
		return this.api.put(this._controllerName, `${networkId}/removeNpis`, npis);
	}

	createOrupdate(networkId: NpiGroup) {
		return this.api.post(this._controllerName, null, networkId);
	}

	create(network: NetworkRequest) {
		return this.api.post(this._controllerName, null, network);
	}

	edit(network: NetworkRequest) {
		return this.api.put(this._controllerName, null, network);
	}

	share(networkIds: number[]) {
		return this.api.put(this._controllerName, 'share', networkIds);
	}

	delete(networkIds: number[]) {
		const query = `?${networkIds.map(x => 'id=' + x).join('&')}`;
		return this.api.delete(this._controllerName, query);
	}

	addNpis(request: GrowthRequest): Observable<NpiGroup[]> {
		return this.api.post(this._controllerName, 'add', request);
	}

	getNetworkEdit(networkId: string): Observable<any> {
		return this.api.get(this._controllerName, `edit/${networkId}`);
	}
}

export class NetworkResponse {
	id: number;
	companyId: number;
	description: string;
	isShared: boolean;
	markets: string[];
	name: string;
	networkType: NETWORK_TYPE;
	npis: NpiResponse[];
	userId: number;
}

export class NpiResponse {
	id: number;
	npiId: string;
	npiName: string;
}

export class NpiResponseWithFlag {
	name: number;
	id: string;
	isMatch: boolean;
}

export class AddNpisToGroupRequest {
	groupId: number;
	NPIs: string[];
}

export class AddTopXNpisRequest {
	groupId: number;
	topX?: number;
	gridFilters?: QueryOptions | any;
	singleFilters?: unknown;
	reportNpi: string;
	reportName: string;
}

export interface NetworkListOptions {
	groupType?: NPI_GROUP_TYPE;
	withDefaults?: boolean;
	portal?: string;
	npi?: string;
	providerType?: string;
}

export class NetworkTypeResponse {
	id: string;
	display: string;
}

export class NetworkAccessLevelResponse {
	id: string;
	display: string;
}

export class NetworkReportingPeriodResponse {
	id: string;
	display: string;
}
