import { Injectable } from '@angular/core';
import { ApplicationCacheService } from 'src/app/shared/services/application-cache.service';

import { LOCAL_STORAGE_KEY } from '../shared.constants';
import StrategyUtils from '../Utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyzePageTab } from '@appcore/models/analyze-page-tab.model';
import { NpiFloatingListModel } from '@appcore/models/npi/npi-floating-list.model';

export interface RecentAnalyzeListItem {
	guid: any;
	// type: PROVIDER_TYPE;
	display: string;
	id: any;
}

export interface AnalyzeRecentItem {
	guid: any;
	// type: PROVIDER_TYPE;
	display: string;
	id: any;
}

@Injectable({
	providedIn: 'root'
})
export class RecentAnalyzeListService {
	public readonly recentItems: Observable<NpiFloatingListModel[]>;
	public readonly floatListExpanded: Observable<boolean>;
	public readonly floatListHidden: Observable<boolean>;
	
	private _expanded = false;
	private _hidden = false;
	private _recentItems: BehaviorSubject<NpiFloatingListModel[]> = new BehaviorSubject(this.convertRecent(this.getRecent()));
	private _floatListExpanded: BehaviorSubject<boolean> = new BehaviorSubject(this._expanded);
	private _floatListHidden: BehaviorSubject<boolean> = new BehaviorSubject(this._hidden);
	
	constructor(private appCache: ApplicationCacheService) {
		this.recentItems = this._recentItems.asObservable();
		this.floatListExpanded = this._floatListExpanded.asObservable();
		this.floatListHidden = this._floatListHidden.asObservable();
	}

	addRecent(model: NpiFloatingListModel) {
		const item = this.getAnalyzePageInfo(model);
		const recentItems = this.getRecent().filter(t => t.guid !== item.guid);
		recentItems.unshift(item);
		this.updateRecent(recentItems);
	}

	removeRecent(floatingListModel: NpiFloatingListModel) {
		const recentItems = this.getRecent().filter(t => t.guid !== this.getAnalyzePageInfo(floatingListModel).guid);
		this.updateRecent(recentItems);
	}

	toggleFloatListExpand() {
		this._expanded = !this._expanded;
		this._floatListExpanded.next(this._expanded);
	}

	getItems(): NpiFloatingListModel[] {
		return this._recentItems.value;
	}

	private updateRecent(recentItems: AnalyzePageTab[]) {
		localStorage.setItem(this.getStorageKey(), JSON.stringify(recentItems));
		this._recentItems.next(this.convertRecent(recentItems));
	}

	private convertRecent(recentItems: AnalyzePageTab[]): NpiFloatingListModel[]  {
		return recentItems.map(x => {
			const config = StrategyUtils.getMedicalEntityTypeConfig(x.type);
			return {
				display: x.display,
				title: config.labelSingular,
				showRemove: true,
				class: config.routeString,
				colorClass: config.colorClass,
				icon: config.icon,
				type: x.type,
				id: x.id
			};
		});
	}

	private getStorageKey() {
		return this.appCache.userSpecificKey(LOCAL_STORAGE_KEY.SCORE_CARD_TABS);
	}

	private getAnalyzePageInfo(floatingListModel: NpiFloatingListModel): AnalyzePageTab {
		return new AnalyzePageTab({
			display: floatingListModel.display,
			id: floatingListModel.id,
			type: floatingListModel.type
		});
	}

	private getRecent(): AnalyzePageTab[] {
		const json = localStorage.getItem(this.getStorageKey());
		let parsed = JSON.parse(json) || [];
		parsed = parsed.map(tab => new AnalyzePageTab(tab));
		return parsed;
	}
}
