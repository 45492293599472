import { DemographicCardOptions } from '../models/demographic-card-options.model';

export const marketscapeOptions: Partial<DemographicCardOptions> = {
    displayTarget: true,
    displayFavorite: true,
    displayPDGMProLink: true,
    displayDetails: true,
    displayLinkToNPPES: true,
    displayStaff: true,
    displayTitleBar: true,
    displayPresentationButton: true
};
