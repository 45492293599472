import { DemographicCardType } from '../enums/demographic-card-type.enum';
import { mosaicOptions } from '../constants/mosaic-options.constant';
import { marketscapeOptions } from '../constants/marketscape-options.constant';
import { MAX_NUMBER_OF_STARS } from '../constants/constants';

export class DemographicCardOptions {
    displayDetails?: boolean;
    displayLinkToNPPES?: boolean;
    displayPDGMProLink?: boolean;
    displayAssignees?: boolean;
    displayTarget?: boolean;
    displayFavorite?: boolean;
    displayTitleBar?: boolean;
    displayStaff?: boolean;
    displayPresentationButton?: boolean;
    addToCartChart?: boolean;
    addUser?: boolean;
    editInfo?: boolean;
    editTarget?: boolean;
    editFavorite?: boolean;
    removeDataKit?: boolean;
    removeNpiAssignee?: boolean;
    removeUser?: boolean;
    displaySubSpecialty?: boolean;
    displayNpiGroup?: boolean;
    displayCRMLink?: boolean;
    editInternalNote?: boolean;
    editNpiGroup?: boolean;
    maxNumberofStars: number;
    crmSvg?: string;


    constructor(type: DemographicCardType) {
        this.maxNumberofStars = MAX_NUMBER_OF_STARS;

        switch (type) {
            case DemographicCardType.mosaic: {
                Object.assign(this, mosaicOptions);
                break;
            }
            case DemographicCardType.market: {
                Object.assign(this, marketscapeOptions);
                break;
            }
            case DemographicCardType.simple: {
                Object.assign(this, mosaicOptions);
                break;
            }
        }
    }
}


