import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomListApi } from 'src/app/api/custom-list.api';
import { CUSTOM_LIST_TYPE } from 'src/app/shared/enums/custom-list-type.enum';
import { CustomList } from 'src/app/shared/models/custom-list';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'mosaic-create-custom-list',
	templateUrl: './create-custom-list.component.html',
	styleUrls: ['./create-custom-list.component.scss']
})
export class CreateCustomListComponent extends ComponentWithSubscription {
	@Input() customListType: CUSTOM_LIST_TYPE;
	@Input() customLists: CustomList[];
	@Input() opened: boolean;

	@Output() newListCreated: Observable<void>;
	@Output() closed: Observable<void>;
	
	description: string;
	listName: string;
	loading = false;
	
	private _closed: EventEmitter<void> = new EventEmitter();
	private _newListCreated: EventEmitter<void> = new EventEmitter();
	
	constructor(private customListApi: CustomListApi, private userFeedbackService: UserFeedbackService) {
		super();
		this.newListCreated = this._newListCreated.asObservable();
		this.closed = this._closed.asObservable();
	}
	
	get canSave() {
		return this.listName;
	}

	close(): void {
		this._closed.emit();
	}

	save(): void {
		if (!this.canSave) 
			return;
		

		if (this.checkIfDuplicateName()) 
			this.appendNumberToName();
		

		const list = {
			description: this.description,
			name: this.listName,
			type: this.customListType
		} as CustomList;

		this.loading = true;

		this.customListApi
			.createCustomList(list)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				success => this._newListCreated.emit(),
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private appendNumberToName(): void {
		const regExp = /\(([^)]+)\)/;
		const matches = regExp.exec(this.listName);
		if (matches) {
			const nameOnly = this.listName.replace(matches[0], '');
			const highestNumber = this.getHighestAppendedNumber(nameOnly);
			this.listName = nameOnly.concat('(', highestNumber.toString(), ')');
		} else {
			const highestNumber = this.getHighestAppendedNumber(this.listName);
			this.listName = this.listName.concat('(', highestNumber.toString(), ')');
		}
	}

	private checkIfDuplicateName(): boolean {
		if (!this.customLists || this.customLists.length === 0) 
			return false;
		
		return this.customLists.some(list => list.name === this.listName);
	}

	private getHighestAppendedNumber(newName: string): number {
        const lowestAppendedNumber = 1;
        const regExp = /\(([^)]+)\)/;
        const appendedNumbers = this.customLists
            .filter(list => list.name.includes(newName) && list.name.match(regExp))
            .map(list => Number(regExp.exec(list.name)[1]));

        if (appendedNumbers.length) 
            return lowestAppendedNumber + Math.max(...appendedNumbers);
        

      return lowestAppendedNumber;
    }
}
