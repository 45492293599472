// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.mosaic-chart-wrapper {
  /* Charts have a min-height already, which is greater than 200, so they will still render ok. */
  min-width: 400px;
  min-height: 200px;
}

.shadow {
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.75);
}

.rounded-5 {
  border-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/chart/chart.component.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACD;;AAEE;EACC,+FAAA;EACA,gBAAA;EACA,iBAAA;AACH;;AAEA;EACC,+CAAA;AACD;;AAEA;EACC,qBAAA;AACD","sourcesContent":[":host {\n\tdisplay: block;\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n  }\n\n  .mosaic-chart-wrapper {\n\t  /* Charts have a min-height already, which is greater than 200, so they will still render ok. */\n\t  min-width: 400px;\n\t  min-height: 200px;\n}\n\n.shadow{\n\tbox-shadow: 0px 2px 4px 4px rgba(0,0,0,0.75);\n}\n\n.rounded-5 {\n\tborder-radius: .5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
