import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { NetworkApi, NetworkResponse } from 'src/app/api/network.api';
import { GrowthRequest } from 'src/app/shared/models/growth/growth-request';
import { NetworkDelta } from 'src/app/shared/models/growth/network-delta';
import { NetworkFilter, NETWORK_FILTER_TYPE } from 'src/app/shared/models/network';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { ProviderIds } from '@appcore/models/npi/provider-ids.model';

@Component({
	selector: 'add-to-network-modal',
	templateUrl: './add-to-network-modal.component.html',
	styleUrls: ['./add-to-network-modal.component.scss']
})
export class AddToNetworkModalComponent extends ComponentWithSubscription implements OnChanges {
	@Input() primaryProviderType: string;
	@Input() networkFilter: NetworkFilter;
	
	public npis: ProviderIds[] = [];
	networks = [];
	selectedNetwork: number;
	opened = false;

	constructor(private networkApi: NetworkApi, private userFeedbackService: UserFeedbackService) {
		super();
	}


	get canAdd() {
		return this.selectedNetwork && this.npis.length;
	}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.primaryProviderType.currentValue !== undefined) {
			this.networkApi.getNames(this.primaryProviderType).subscribe(response => {
				response = this.filter(response);
				this.networks = response.map(x => ({
					value: x.id,
					display: x.name
				}));
			});
        }
    }

	open() {
		this.opened = true;
	}

	cancel() {
		this.selectedNetwork = null;
		this.opened = false;
	}

	add() {
		const delta = new NetworkDelta;
		delta.npisToAdd = this.npis;
		const request = new GrowthRequest();
		request.networkId = this.selectedNetwork;
		request.networkDelta = delta;
		this.networkApi.addNpis(request).subscribe(
			() => {
				this.cancel();
				this.userFeedbackService.showSaveSucess('NPIs');
			},
			err => {
				this.cancel();
				if (err) 
					this.userFeedbackService.showWarning(err);
				 else 
					this.userFeedbackService.showSaveError('NPIs');
				

				throw new Error(err || 'Could not add Npis.');
			}
		);
	}

	private filter(response: NetworkResponse[]): NetworkResponse[] {
		if(this.networkFilter?.filterType === NETWORK_FILTER_TYPE.EXCLUDE) 
		
			return response.filter(f => f.networkType !== this.networkFilter.networkType);
		
		if(this.networkFilter?.filterType === NETWORK_FILTER_TYPE.INCLUDE) 
		
			return response.filter(f => f.networkType === this.networkFilter.networkType);
		
		return response;
	}
}
