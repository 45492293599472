import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DiagnosticCategoryService } from '../../../shared/services/diagnostic-category.service';
import { map } from 'rxjs/operators';
import {
	FILTER_TYPE
} from '@appcore/enums/filter-type.enum';
import {
	ACUITY_OPTIONS, BPCI_OPTIONS,
	CAP_OPTIONS,
	CONSTRAINT_OPTIONS,
	FILTER_CATEGORY, RPG_OPTIONS,
	SUNBURST_ALL_OPTION
} from '@appcore/constants/constants';

@Component({
	selector: 'mosaic-journey-options',
	templateUrl: './journey-options.component.html'
})
export class JourneyOptionsComponent implements OnInit {
	@Output() filterOptionsEvent = new EventEmitter<string>();

	filterCategory = FILTER_CATEGORY;
	optionAll = {
		display: 'All Patients',
		value: SUNBURST_ALL_OPTION,
		category: { value: SUNBURST_ALL_OPTION },
		id: SUNBURST_ALL_OPTION
	};
	optionTimeAll = {
		display: '12 Months',
		value: '',
		category: { value: '' }
	};
	timeOptionList = [this.getFilterList(CAP_OPTIONS), this.getFilterList(CONSTRAINT_OPTIONS)];
	patientCharacteristicsOptionList = [
		this.getFilterList(ACUITY_OPTIONS),
		this.getFilterList(RPG_OPTIONS),
		this.getFilterList(BPCI_OPTIONS)
	];
	selectedTimeFilter: any = this.optionTimeAll;
	selectedPatientCharacteristicsFilter: any = this.optionAll;
	filters;
	diagnosticCategories: Selection[];

	constructor(private diagnosticCategoryService: DiagnosticCategoryService){}

	ngOnInit(): void {
		this.selectedTimeFilter = this.optionTimeAll;
		this.selectedPatientCharacteristicsFilter = this.optionAll;
		this.filters = this.getFilters();
		this.retrieveDiagnosticCategories();
	}

	getFilterList(options) {
		const header = options[0].category;
		return { options, ...header };
	}

	onFilterChange() {
		this.filters = this.getFilters();
		this.filterOptionsEvent.emit(this.filters);
	}

	getFilters() {
		const categoryFilters = [
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'category1HC',
				modelJson: this.selectedPatientCharacteristicsFilter.category.value
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'category2HC',
				modelJson: this.selectedTimeFilter.category.value
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'group1HC',
				modelJson: this.getGroup(this.selectedPatientCharacteristicsFilter)
			},
			{
				filterType: FILTER_TYPE.HARD_CODED,
				paramName: 'group2HC',
				modelJson: this.getGroup(this.selectedTimeFilter)
			},
			{
				filterType: FILTER_TYPE.CARE_PATH_TYPE,
				modelJson: 'non-aco'
			}
		];

		return categoryFilters;
	}

	getGroup(selectedFilter) {
		return selectedFilter.value || selectedFilter.id || '';
	}

	private retrieveDiagnosticCategories(): void {
		this.diagnosticCategoryService.diagnosticCategories.pipe(map(selections=>{
			if(selections){
				selections.forEach(selection =>{
					(selection as any).category = {value: 'MDC'};
				});
			}
			return selections;
		})).subscribe(selections=>{
			this.diagnosticCategories = selections;
			if(selections)
				this.insertDiagnosticCategoriesIntoDropdown(selections);
			
		});
	}

	private insertDiagnosticCategoriesIntoDropdown(selections: Selection[]){
		const formatted =  { options: selections, display: 'Trella Diagnostic Category', value: 'Trella Diagnostic Category' };
		this.patientCharacteristicsOptionList.splice(1, 0, formatted);
	}
}
