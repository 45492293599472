import { Component, Input } from '@angular/core';
import { Aco } from '@appcore/models/aco/aco.model';
import { NpiTypeConfig } from '@appcore/models/npi/npi-type-config.model';
import { MedicalEntityTypeConfig } from '@appcore/models/medical-entity-type-config.model';
import { Npi } from '@appcore/models/npi/npi.model';
import { DemographicCardOptions } from '@appcore/models/demographic-card-options.model';
import { DemographicCardType } from '@appcore/enums/demographic-card-type.enum';

@Component({
	selector: 'mosaic-demographic-card',
	templateUrl: './demographic-card.component.html',
	styleUrls: ['./demographic-card.component.scss']
})
export class DemographicCardComponent {
	@Input() config: NpiTypeConfig | MedicalEntityTypeConfig;
	@Input() info: Npi | Aco;
	@Input() key = '';
	@Input() loading = false;
	options = new DemographicCardOptions(DemographicCardType.mosaic);

	get isAco()
	{
		return ((this.info as Aco).id);
	}
}
