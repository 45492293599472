import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomListApi } from 'src/app/api/custom-list.api';
import { LocationApi } from 'src/app/api/location.api';
import { NpiApi } from 'src/app/api/npi.api';
import { CUSTOM_LIST_TYPE } from 'src/app/shared/enums/custom-list-type.enum';
import { REGIONAL_SCOPE } from 'src/app/shared/enums/regional-scope.enum';
import { NpiStatesRequest } from 'src/app/shared/models/npi-states-request';
import { NpisRequest } from 'src/app/shared/models/npis-request';
import { RegionalComparison } from 'src/app/shared/models/regional-comparison';
import { RegionalComparisonGroup } from 'src/app/shared/models/regional-comparison-group';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	CheckedSelection
} from '@appcore/interfaces/checked-selection.interface';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { Utils } from '@appcore/helpers/Utils';
import { Locality } from '@appcore/interfaces/locality.interface';
import { Npi } from '@appcore/models/npi/npi.model';
import { CheckedSelectionGroup } from '@appcore/interfaces/checked-selection-group.interface';
import { ALL_OPTION } from '@appcore/constants/constants';

@Component({
	selector: 'mosaic-regional-comparisons',
	templateUrl: './regional-comparisons.component.html',
	styleUrls: ['./regional-comparisons.component.scss']
})
export class RegionalComparisonsComponent extends ComponentWithSubscription implements OnChanges {
	@Input() customListType: CUSTOM_LIST_TYPE;
	@Input() npiType: NpiType = null;
	@Input() opened: boolean;
	@Input() singleSelect = false;

	@Output() applied: Observable<any>;
	@Output() canceled: Observable<any>;
	@Output() groupSelected: Observable<any>;

	counties: CheckedSelection[] = [];
	defaultStates: CheckedSelection[] = [];
	filterType = FILTER_TYPE;
	groups: RegionalComparisonGroup[] = [];
	hasDefaultSelection = true;
	loading = false;
	npiStates: CheckedSelection[] = [];
	searchString: string;

	private _applied: EventEmitter<any> = new EventEmitter();
	private _canceled: EventEmitter<any> = new EventEmitter();
	private _committedComparisons: RegionalComparison[] = [];
	private _groupSelected: EventEmitter<any> = new EventEmitter();
	private _cachedNpiResults: RegionalComparison[] = [];
	private _selectAllChecked = false;
	private _selectedGroup: RegionalComparisonGroup;
	private _selectedGroupName: string;

	constructor(
		private customListApi: CustomListApi,
		private locationApi: LocationApi,
		private npiApi: NpiApi,
		private userFeedbackService: UserFeedbackService
	) {
		super();

		this.applied = this._applied.asObservable();
		this.canceled = this._canceled.asObservable();
		this.groupSelected = this._groupSelected.asObservable();
	}

	get filteredComparisons(): RegionalComparison[] {
		if (!this._selectedGroup)
			return [];

		const filteredBySearch = this.filterBySearch(this._selectedGroup.regionalComparisons);
		return this.filterBySelectedComparisons(filteredBySearch);
	}

	get selectAllChecked(): boolean {
		return this._selectAllChecked;
	}

	get states(): CheckedSelection[] {
		if (this.hasSelectedProvider() && this.hasApplicableProvider())
			return this.npiStates;

		return this.defaultStates;
	}
	
	get committedComparisons(): RegionalComparison[] {
		return this._committedComparisons;
	}
	@Input()
	set committedComparisons(committedComparisons: RegionalComparison[]) {
		if (!committedComparisons)
			return;


		this._committedComparisons = Utils.deepClone(committedComparisons);
		const npiComparisons = this._committedComparisons.filter(cc => this.isNpi(cc.name));
		if (npiComparisons.length)
			this.findNpiComparisons(npiComparisons);
		else
			this._committedComparisons.forEach(cc => (cc.display = this.tryToConvertCounty(cc.name)));

	}

	get selectedGroupName(): string {
		return this._selectedGroupName;
	}
	@Input()
	set selectedGroupName(name: string) {
		this._selectedGroupName = name;
	}

	get selectedGroup(): RegionalComparisonGroup {
		const selectedGroup = this.groups.find(g => g.title === this._selectedGroupName);
		if (selectedGroup)
			this._selectedGroup = selectedGroup;

		return this._selectedGroup;
	}
	set selectedGroup(group: RegionalComparisonGroup) {
		this._selectedGroup = group;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.opened && changes.opened.currentValue) {
			this.groups = [];
			this.setComparisonsGroups();
		}

		if (changes.opened && !changes.opened.currentValue) 
			this.hasDefaultSelection = true;
		
	}

	apply(): void {
		this._applied.emit(this._committedComparisons);
		this.opened = false;
	}

	cancel(): void {
		this._canceled.emit();
		this.opened = false;
	}

	filterNpiResults(group: CheckedSelectionGroup): void {
		const counties = group.selections.filter(selection => selection.checked).map(selection => selection.display.toLocaleUpperCase());
		this._selectedGroup.regionalComparisons = this._cachedNpiResults.filter(npiResult =>
			this.filterByCounty(npiResult.display, counties)
		);
		group.selections.forEach(county => {
			if (county.id === ALL_OPTION) 
				county.checked = group.selections.every(selection => (selection.id === ALL_OPTION ? true : selection.checked));
			
		});
		this.counties = group.selections;
	}

	populateAdditionalFilter(group: CheckedSelectionGroup): void {
		this.locationApi
			.getCountiesByStates(group.selections.filter(selection => selection.checked).map(selection => selection.id))
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(items => {
				this.counties = [
					...[{ id: ALL_OPTION, display: 'Select All', checked: true } as CheckedSelection],
					...items.map(item => ({ id: item.county, display: item.county, checked: true }) as CheckedSelection)
				];
			});
	}

	populateCountyFilter(group: CheckedSelectionGroup): void {
		if (!(this._selectedGroup.hasStateFilter && group.selections.length)) 
			return;
		

		if (!this.hasSelectedProvider()) {
			this.defaultStates = group.selections;

			this.loading = true;
			this.locationApi
				.getCountiesByStates(this.defaultStates.filter(selection => selection.checked).map(selection => selection.id))
				.pipe(
					takeUntil(this.ngUnsubscribe),
					finalize(() => (this.loading = false))
				)
				.subscribe(items => this.handleGetCountiesByStatesResponse(items));
		}

		if (this.hasSelectedProvider() && this.hasApplicableProvider()) {
			this.npiStates = group.selections;
			this.loadNpis(this.npiStates.filter(selection => selection.checked));
		}
	}

	removeComparison(comparison: string): void {
		this._committedComparisons = this._committedComparisons.filter(c => c.name !== comparison);
	}

	selectComparison(comparison: RegionalComparison): void {
		this._committedComparisons.push(comparison);
	}

	selectComparisonGroup(group: RegionalComparisonGroup): void {
		if (this._selectedGroupName && this.hasDefaultSelection) 
			this._selectedGroup = this.groups.find(g => g.title === this._selectedGroupName);
		 else {
			this._selectedGroupName = group.title;
			this._groupSelected.emit(this._selectedGroupName);
			this._selectedGroup = group;
		}

		this.hasDefaultSelection = false;
		this.searchString = '';

		if (this._selectedGroup.hasStateFilter && !this.hasSelectedProvider() && !this.hasApplicableProvider()) 
			this.setStates();
		

		if (this._selectedGroup.hasStateFilter && this.hasSelectedProvider() && this.hasApplicableProvider()) 
			this.setNpiStates();
		

		if (this._selectedGroup.customListId) 
			this.setCustomListItems(this._selectedGroup.customListId);
		
	}

	setPlaceholderText(): string {
		switch (this._selectedGroup?.title) {
			case 'National Benchmark': {
				return 'Search Name or Alias';
			}
			case 'States': {
				return 'Search State';
			}
			case 'Counties': {
				return 'Search County';
			}
			default: {
				return 'Search Name, NPI or Alias';
			}
		}
	}

	showNpiStateFilter(): boolean {
		return this.selectedGroup?.hasStateFilter && this.hasSelectedProvider() && this.hasApplicableProvider();
	}

	showStateFilter(): boolean {
		return this.selectedGroup?.hasStateFilter && !(this.hasSelectedProvider() && this.hasApplicableProvider());
	}

	toggleSelectAll(change: MatCheckboxChange): void {
		this._selectAllChecked = change.checked;
		if (change.checked) 
			this._committedComparisons = [...this._committedComparisons, ...this.filteredComparisons];
		 else {
			this._committedComparisons = this._committedComparisons.filter(
				committedComparison =>
					!this._selectedGroup.regionalComparisons.some(
						filteredComparison => filteredComparison.name === committedComparison.name
					)
			);
		}
	}

	private containsSearchString(value: string, searchString: string): boolean {
		if (!value || !searchString) 
			return;
		
		const stringValue = '' + value;
		return stringValue.toLowerCase().includes(searchString.toLowerCase());
	}

	private filterByCounty(npiResult: string, targetCounties: string[]): boolean {
		const parts = npiResult.split(',');
		const county = parts[parts.length - 1].trim().toLocaleUpperCase();

		return targetCounties.some(targetCounty => county.includes(targetCounty));
	}

	private filterBySearch(comparisons: RegionalComparison[]): RegionalComparison[] {
		if (!comparisons) 
			return [];
		

		if (!this.searchString) 
			return [...comparisons];
		

		const filtered = comparisons.filter(comparison => this.containsSearchString(comparison.display, this.searchString));
		return filtered;
	}

	private filterBySelectedComparisons(comparisons: RegionalComparison[]): RegionalComparison[] {
		return comparisons.filter(comparison => !this._committedComparisons.some(committed => committed.name === comparison.name));
	}

	private findNpiComparisons(npiComparisons: RegionalComparison[]): void {
		const request = {
			providerType: this.npiType,
			npis: npiComparisons.map(nc => nc.name)
		} as NpisRequest;

		this.loading = true;
		this.npiApi
			.getNpiInfosByProviderTypeAndNpis(request)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(items => {
				const npis = items;
				this._committedComparisons.forEach(cc => {
					if (this.isNpi(cc.name)) {
						const selectedNpi = npis.find(n => n.npi === cc.name);
						cc.display = this.getNpiDisplayValue(selectedNpi);
					} else 
						cc.display = this.tryToConvertCounty(cc.name);
					
				});
			});
	}

	private getNpiDisplayValue(npi: Npi): string {
		return `${npi.npi} - ${npi.display} - ${this.getPatientTotalString(npi.patientTotal)} Patients - ${npi.state}, ${npi.county}`;
	}

	private getNpiGroupTitle(): string {
		switch (this.npiType) {
			case NpiType.homeHealthAgency: {
				return 'Home Health Agencies';
			}
			case NpiType.hospice: {
				return 'Hospice Agencies';
			}
			default: {
				return '';
			}
		}
	}

	private getPatientTotalString(patientTotal: string): string {
		return patientTotal === '' ? '0' : patientTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	private getPatientTotalValue(display: string): number {
		const value = parseInt(display, 10);
		return Number.isInteger(value) ? value : 0;
	}

	private handleGetCountiesByStatesResponse(locations: Locality[]): void {
		const countyItems: RegionalComparison[] = [];
		locations.forEach(location => {
			countyItems.push({
				regionalScope: REGIONAL_SCOPE.County,
				display: `${location.market}, ${location.county}`,
				name:
					this.npiType === null ? `${location.market}, ${location.county}` : `${location.market}-${location.county.toUpperCase()}`
			});
		});
		this._selectedGroup.regionalComparisons = countyItems;
	}

	private handleGetNpisResponse(npis: Npi[]): void {
		const npiItems: Array<{ display: string; name: string }> = [];
		npis.sort((a, b) => this.getPatientTotalValue(b.patientTotal) - this.getPatientTotalValue(a.patientTotal));
		npis.forEach(npi => npiItems.push({ display: this.getNpiDisplayValue(npi), name: npi.npi }));

		const npiComparisons: RegionalComparison[] = [];
		npiItems.forEach(npiItem =>
			npiComparisons.push({ regionalScope: REGIONAL_SCOPE.Npi, display: npiItem.display, name: npiItem.name })
		);
		this._selectedGroup.regionalComparisons = npiComparisons;
		this._cachedNpiResults = npiComparisons;
		this._selectAllChecked = npiComparisons.every(comparison =>
			this._committedComparisons.some(committedComparison => comparison.name === committedComparison.name)
		);
	}

	private hasApplicableProvider(): boolean {
		const providers = [NpiType.homeHealthAgency, NpiType.hospice];
		return providers.includes(this.npiType);
	}

	private hasSelectedProvider(): boolean {
		return this._selectedGroup ? this._selectedGroup.provider === this.npiType && this.npiType !== null : false;
	}

	private isNpi(regionalComparison: string): boolean {
		const npi = parseInt(regionalComparison, 10);
		return !isNaN(npi);
	}

	private loadNpis(selectedStates: CheckedSelection[]): void {
		if (!selectedStates.length) 
			return;
		

		const request = {
			providerType: this.npiType,
			states: selectedStates.map(selection => selection.id)
		} as NpiStatesRequest;

		this.loading = true;
		this.npiApi
			.getNpiInfosByProviderTypeAndStates(request)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(items => this.handleGetNpisResponse(items));
	}

	private loadStatesByProvider(): void {
		this.loading = true;
		this.locationApi
			.getStatesByProvider(this.npiType)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				states => {
					this.npiStates = states.map(state => ({ id: state, display: state }) as CheckedSelection);
					this.setDefaultNpiStates();
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private setCustomListItems(customListId: number): void {
		this.loading = true;
		this.customListApi
			.getCustomListItems(customListId, this.npiType)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				listItems => {
					const newListItems: RegionalComparison[] = [];
					listItems.forEach(listItem => {
						if (listItem.providerType) 
							newListItems.push({ display: listItem.displayName, name: listItem.name, regionalScope: REGIONAL_SCOPE.Npi });
						 else 
							newListItems.push({ display: listItem.displayName, name: listItem.name, regionalScope: REGIONAL_SCOPE.Custom });
						
					});
					this._selectedGroup.regionalComparisons = newListItems;
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private setComparisonsGroups(): void {
		if (this.groups.length) 
			return;
		

		this.loading = true;
		forkJoin([this.locationApi.getStatesByCompany(), this.customListApi.getCustomLists(this.customListType)])
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => {
					this.loading = false;
					if (this.groups.length) 
						this.selectComparisonGroup(this.groups[0]);
					
				})
			)
			.subscribe(
				([states, lists]) => {
					this.defaultStates = states.map(state => ({ id: state, display: state }) as CheckedSelection);
					this.setDefaultGroups(states);
					const groups = lists.map(list => ({ customListId: list.id, title: list.name }) as RegionalComparisonGroup);
					this.groups = [...this.groups, ...groups];
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private setDefaultGroups(states: string[]): void {
		if (
			(this.customListType === CUSTOM_LIST_TYPE.Hha || this.customListType === CUSTOM_LIST_TYPE.Hos) &&
			this.hasApplicableProvider()
		) {
			const group: RegionalComparisonGroup = {
				title: this.getNpiGroupTitle(),
				hasStateFilter: true,
				provider: this.npiType,
				regionalComparisons: null
			};
			this.groups.push(group);
		}

		if (this.customListType === CUSTOM_LIST_TYPE.StateCounties) {
			const nationalBenchmark = 'National Benchmark';
			const nationalGroup: RegionalComparisonGroup = {
				title: nationalBenchmark,
				hasStateFilter: false,
				provider: null,
				regionalComparisons: [{ regionalScope: REGIONAL_SCOPE.National, display: nationalBenchmark, name: nationalBenchmark }]
			};
			this.groups.push(nationalGroup);
		}

		const stateItems: RegionalComparison[] = [];
		states.forEach(state => stateItems.push({ regionalScope: REGIONAL_SCOPE.State, display: state, name: state }));
		const stateGroup: RegionalComparisonGroup = {
			title: 'States',
			hasStateFilter: false,
			provider: null,
			regionalComparisons: stateItems
		};
		this.groups.push(stateGroup);

		const countyGroup: RegionalComparisonGroup = {
			title: 'Counties',
			hasStateFilter: true,
			provider: null,
			regionalComparisons: null
		};
		this.groups.push(countyGroup);
	}

	private setDefaultNpiStates(): void {
		if (!this.npiStates.some(npiState => npiState.checked)) 
			this.npiStates[0].checked = true;
		
		const group = { selections: this.npiStates } as CheckedSelectionGroup;
		this.populateCountyFilter(group);
		this.populateAdditionalFilter(group);
	}

	private setNpiStates(): void {
		if (!this.hasApplicableProvider()) 
			return;
		

		if (!this.npiStates.length && this.hasApplicableProvider()) {
			this.loadStatesByProvider();
			return;
		}

		if (this.npiStates.length) {
			this.populateCountyFilter({ selections: this.npiStates } as CheckedSelectionGroup);
			this.setDefaultNpiStates();
			return;
		}

		const request = {
			providerType: this.npiType,
			states: this.npiStates.map(selection => selection.id)
		} as NpiStatesRequest;

		this.loading = true;
		forkJoin([this.locationApi.getStatesByProvider(this.npiType), this.npiApi.getNpiInfosByProviderTypeAndStates(request)])
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				([states, npis]) => {
					this.npiStates = states.map(state => ({ id: state, display: state }) as CheckedSelection);
					this.handleGetNpisResponse(npis);
					this.setDefaultNpiStates();
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private setStates(): void {
		if (this.defaultStates.length) {
			this.populateCountyFilter({ selections: this.defaultStates } as CheckedSelectionGroup);
			return;
		}

		forkJoin([
			this.locationApi.getStatesByCompany(),
			this.locationApi.getCountiesByStates(this.defaultStates.filter(selection => selection.checked).map(selection => selection.id))
		])
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				([states, counties]) => {
					this.defaultStates = states.map(state => ({ id: state, display: state }) as CheckedSelection);
					this.handleGetCountiesByStatesResponse(counties);
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private tryToConvertCounty(regionalComparison: string): string {
		const parts = regionalComparison.split('-');
		if (parts.length === 2) {
			const [state, county] = parts.map(part => part.trim().replace(/\s+/g, ' '));
			const formattedCounty = county
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
				.join(' ');
			return `${state}, ${formattedCounty}`;
		}

		return regionalComparison;
	}
}
