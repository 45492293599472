import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../shared/services/authentication.service';
import { from } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		return from(this.handle(request, next));
	}
	async handle(request: HttpRequest<any>, next: HttpHandler) {
		const authToken = await this.authenticationService.getCognitoToken();

		const authRequest = request.clone({
			setHeaders: {
				Authorization: `Bearer ${authToken}`
			}
		});
		return next.handle(authRequest).toPromise();
	}
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
