import { AcoDashboardService } from './../../../shared/services/aco-dashboard.service';
import { NetworkAcoOption } from './../../../shared/models/network-aco-option';
import { Component, OnInit } from '@angular/core';

import { AcoService } from 'src/app/shared/services/aco.service';
import { NpiTypeConfigs } from 'src/app/shared/models/npi-type-configs';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	NpiTypeConfig
} from '@appcore/models/npi/npi-type-config.model';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { MapMarker } from '@appcore/interfaces/map-marker.interface';

@Component({
	selector: 'trella-aco-growth',
	templateUrl: './aco-growth.component.html',
	styleUrls: ['./aco-growth.component.scss']
})
export class AcoGrowthComponent extends ComponentWithSubscription implements OnInit {
	markers: MapMarker[] = [];
	loading = false;
	npiTypeOptions: NpiTypeConfig[] = [
		NpiTypeConfigs.homehealth,
		NpiTypeConfigs.hospice,
		NpiTypeConfigs.hospital,
		NpiTypeConfigs.physician,
		NpiTypeConfigs.skilledNursing
	];
	defaultMarkerType = NpiType.physician;

	constructor(public acoDashboardService: AcoDashboardService, public acoService: AcoService, private userFeedbackService: UserFeedbackService) {
		super();
	}

	ngOnInit() {
		this.acoDashboardService.selectedNetworkAco.subscribe(selected => {
			if(selected) {
				this.loading = true;
				this.markers = [];
				this.acoDashboardService.setSelectedAcoNpiInfo(selected);
			}
		});

		this.acoDashboardService.selectedAcoNpiInfo.subscribe(selected => {
			selected.forEach(aco => {
				this.markers.push({
					icon: this.generateIconUrl(aco.trellaType),
					lat: aco.gpsLatitude,
					lng: aco.gpsLongitude,
					npi: aco,
					visible: aco.trellaType === this.defaultMarkerType
				});
			});
			this.loading = false;
		},
		err => {
			this.loading = false;
			this.userFeedbackService.showUnexpectedError();
		});
	}

	generateIconUrl(type: NpiType) {
		const routeString = this.npiTypeOptions.find(option => option.npiType === type).routeString;
		return `/assets/img/icons/map_icon_${routeString}.png`;
	}

	selectNpiType(config: NpiTypeConfig) {
		this.markers.forEach(marker => marker.visible = marker.npi.trellaType === config.npiType);
	}

	getVisibleMarkers() {
		return this.markers.filter(marker => marker.visible);
	}
}
