import { Injectable } from '@angular/core';
import { AcoApi } from '../../api/aco.api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PmpmSpending } from '../models/pmpm-spending';
import { ApplicationCacheService } from './application-cache.service';
import { LOCAL_STORAGE_KEY } from '../shared.constants';
import { AcoPeriod } from '../models/aco-period';
import { YearsAco } from '@appcore/models/aco/years-aco.model';

@Injectable({
	providedIn: 'root'
})
export class AcoService {
	public readonly acos: Observable<YearsAco[]>;
	public readonly latestFullClaimsYear: Observable<string>;
	public readonly pmpmSpending: Observable<PmpmSpending[]>;
	
	private _acos: BehaviorSubject<YearsAco[]> = new BehaviorSubject([]);
	private _latestFullClaimsYear: BehaviorSubject<string> = new BehaviorSubject(null);
	private _pmpmSpending: BehaviorSubject<PmpmSpending[]> = new BehaviorSubject(null);
	
	constructor(private acoApi: AcoApi, private appCache: ApplicationCacheService) {
		this.acos = this._acos.asObservable();
		this.latestFullClaimsYear = this._latestFullClaimsYear.asObservable();
		this.pmpmSpending = this._pmpmSpending.asObservable();
	}

	getAcos(): Observable<YearsAco[]> {
		if (this._acos.value && this._acos.value.length) 
			return this.acos;
		

		return this.acoApi.getAcos().pipe(
			map(response => {
				if (!response.length) 
					return response;
				
				this._acos.next(response);

				return response;
			})
		);
	}

	getCmsAcoPmpmSums(acoId: string): Observable<PmpmSpending[]> {
		return this.acoApi.getCmsAcoPmpmSums(acoId).pipe(
			map(response => {
				this._pmpmSpending.next(response);
				return response;
			})
		);
	}

	getLatestFullClaimsYear() {
		return this.acoApi.getLatestFullClaimsYear().pipe(
			map(response => this._latestFullClaimsYear.next(response))
		);
	}

	getAvailableCmsAcoYears() : Observable<AcoPeriod[]>{
		return this.acoApi.getAvailableCmsAcoYears();
	}

	getUserAcoPmpmSums(networkId: number, acoPeriodId: number): Observable<PmpmSpending[]> {
		return this.acoApi.getUserAcoPmpmSums(networkId, acoPeriodId).pipe(
			map(response => {
				this._pmpmSpending.next(response);
				return response;
			})
		);
	}

	hasPhysicians(networkId: number): Observable<boolean> {
		return this.acoApi.hasPhysicians(networkId);
	}

	getSelectedYear() {
		const json = localStorage.getItem(this.getSelectedYearStorageKey());
		const parsed = JSON.parse(json);
		return parsed;
	}

	setSelectedYear(year: number) {
		localStorage.setItem(this.getSelectedYearStorageKey(), JSON.stringify(year));
	}

	private getSelectedYearStorageKey() {
		return this.appCache.userSpecificKey(LOCAL_STORAGE_KEY.ACO_YEAR);
	}
}
