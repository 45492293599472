import { NavTabSectionModel } from '../page-model/page-model';
import { ACO_DASHBOARD_PATH, ACO_DASHBOARD_TITLE } from './aco-dashboard.constants';

export const DashboardTabModel: NavTabSectionModel[] = [
	{
		title: ACO_DASHBOARD_TITLE.ACO_INSIGHTS,
		id: ACO_DASHBOARD_PATH.ACO_INSIGHTS
	},
	// {
	// 	title: ACO_DASHBOARD_TITLE.ACO_GROWTH,
	// 	id: ACO_DASHBOARD_PATH.ACO_GROWTH
	// },
	// {
	// 	title: ACO_DASHBOARD_TITLE.ACO_QUALITY,
	// 	id: ACO_DASHBOARD_PATH.ACO_QUALITY
	// }
];
