import { SharedConfigurationFields } from './shared-configuration-fields.model';
import { GridColumn } from './grid-column.model';
import { CHART_TYPE } from '../enums/chart-type.enum';
import { ChartDataType } from '../enums/chart-data-type.enum';
import { ChartGroupModel } from '../interfaces/chart-group-model.interface';
import { Target } from './target.model';
import { LabelRotation } from '@progress/kendo-angular-charts';
import { ChartConfigJson } from '../interfaces/chart-config-json.interface';

export class ChartConfig extends SharedConfigurationFields {
    columns: GridColumn[] = [];
    hideDisplayName?: boolean;
    showNameExpanded?: boolean;
    type: CHART_TYPE;
    adapter?: string;
    dataType?: ChartDataType;
    wrapText?: boolean;
    maxCharacters?: number;
    showLegend?: boolean;
    notes?: string;
    text?: string;
    toolTip: string;
    formatter?: string;

    // column chart
    stacked?: boolean;
    sumStack?: boolean;

    // move to appropriate class
    xLabel?: string;
    yLabel?: string;
    plotOptions?: any;

    // insights
    decimalPoints?: number;
    categoriesField?: any;
    npiType?: string;
    rating?: string;

    // Chart
    reportName: string;
    displayName: string;
    linkedTableReports?: string[];

    key?: string;
    value?: string;
    groups?: ChartGroupModel[];

    target?: Target;

    npiField?: string;
    xField?: string;
    yField?: string;
    xAxisField?: string;
    xAxisLabelRotation?: number | LabelRotation | 'auto';

    // sunburst chart
    colorMap?: any;
    colorField?: string;

    seriesNameFields?: string;
    valueFields?: string[];
    radius?: string;
    colors?: string[];
    labels?: string[];

    // categories: string[];
    data?: any;
    chartOptions?: any;
    title?: string;

    constructor(config: ChartConfigJson, reportName: string) {
        super();
        if (!config)
            return;


        Object.assign(this, config);

        this.reportName = reportName;
        this.title = config.reportName || reportName;

        this.displayName = config.displayName || this.title;
        this.type = config.type as CHART_TYPE;
        this.dataType = config.dataType as ChartDataType;
    }
}

