import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, Router, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CognitoClient } from '../models/authentication';
import { Stack } from '../models/stack';
import { ROUTE_CONSTANTS } from '../shared.constants';
import StrategyUtils from '../Utils';
import { ApplicationCacheService } from './application-cache.service';
import { SSOService } from './sso.service';
import { EnvService } from  '@appcore/services/env.service';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	MedicalEntityType
} from '@appcore/enums/medical-entity-type.enum';
import {
	FormattedData
} from '@appcore/interfaces/formatted-data.interface';
import { GridInfo } from '@appcore/models/grid-info.model';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	private _urls: Stack<string>;

	constructor(
		private router: Router,
		private applicationCacheService: ApplicationCacheService,
		private environmentService: EnvService,
		private ssoDataService: SSOService,
		private titleService: Title
	) {}

	public async navigateToHome() {
		return this.navigateTo(['']);
	}

	/** **************************************************
	 ********************* Dashboard *********************
	 ****************************************************/

	public async navigateToDashboardChild(childRoute: string[]) {
		const route = [ROUTE_CONSTANTS.dashboard.route].concat(childRoute);
		return this.navigateTo(route);
	}

	/** **************************************************
	 ********************* Explore *********************
	 ****************************************************/

	public async navigateToExplore(childRoute, params?: any) {
		const route = [ROUTE_CONSTANTS.explore.route].concat(childRoute);
		return this.navigateTo(route, params);
	}

	/** **************************************************
	 ********************* Common ***********************
	 ****************************************************/
	public async navigateToLogin(returnUrl?: string, url: string = null) {
		const thisReturnUrl = returnUrl || this.applicationCacheService.getReturnUrl() || ROUTE_CONSTANTS.login.route;
		const paramObj = {};
		paramObj[ROUTE_CONSTANTS.returnUrlLabel] = returnUrl || '';

		return this.navigateTo([ROUTE_CONSTANTS.login.route], paramObj);
	}

	public async navigateAfterLogin(route: string) {
		if (route) {
			this.applicationCacheService.setReturnUrl(null);
			return this.navigateTo([route]);
		}
		return this.navigateTo([ROUTE_CONSTANTS.dashboard.route]);
	}

	public async navigateToLogout() {
		return this.navigateTo([ROUTE_CONSTANTS.logout.route]);
	}

	public async navigateToUnauthorized() {
		return this.navigateTo([ROUTE_CONSTANTS.unauthorized.route]);
	}

	public initUrlStack() {
		if (this._urls !== null) 
			return;
		

		this._urls = new Stack<string>();
		this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			// Reset the page title for any pages that don't specify it
			this.titleService.setTitle('Strategy - Trella Health');
			this._urls.push(event.url);
		});
	}

	public getRouteArrayFromUrl(url: string) {
		if (!url) 
			return [];
		

		const urlArray = url.split('/');
		return urlArray.filter(x => x !== '');
	}

	public reload() {
		const currentUrl = this.router.url;
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate([currentUrl]);
	}

	public navigateToSSO(cognitoClient: CognitoClient) {
		const goToUrl = this.environmentService.cognitoUrl(cognitoClient.clientId);
		/* Here we store Client/Auth for the final stage of the Cognito login process (landing page)
		It is not stored for subsequent logins, instead source is used to lookup Client/Auth for each new login
		*/
		this.ssoDataService.storeSSOauth(goToUrl);
		window.location.href = goToUrl;
	}

	/** **************************************************
	 ********************* Analyze *********************
	 ****************************************************/

	public async navigateToAnalyze(type: NpiType | MedicalEntityType, id?: any) {
		if (StrategyUtils.isNpiType(type)) 
			return this.navigateToAnalyzeByNpiOrMedicalEntityType(type as NpiType, id);
		
		return this.navigateToAcoAnalyze(id);
	}

	public async navigateToAcoAnalyze(id: string, tab?: string) {
		if (tab) 
			return this.navigateTo([ROUTE_CONSTANTS.analyze.route, 'aco', id, tab]);
		
		return this.navigateTo([ROUTE_CONSTANTS.analyze.route, 'aco', id]);
	}

	public async navigateToAcoAnalyzeChild(id: string, childRoute: string[]) {
		const route = [ROUTE_CONSTANTS.analyze.route, 'aco', id].concat(childRoute);
		return this.navigateTo(route);
	}

	public async navigateToAnalyzeByNpiOrMedicalEntityType(type?: NpiType | MedicalEntityType, id?: any) {
		if (!type || !id) 
			return this.navigateTo([ROUTE_CONSTANTS.analyze.route]);
		

		const routeAttr = StrategyUtils.getRouteAttrByNpiType(type as NpiType) || StrategyUtils.getRouteAttrByMedicalEntityType(type as MedicalEntityType);
		if (!routeAttr) 
			return this.navigateTo([ROUTE_CONSTANTS.analyze.route]);
		

		return this.navigateTo(['analyze', routeAttr.route, id]);
	}

	public getAnalyzeUrl = (dataItem: Record<string, FormattedData>, info: GridInfo) => {
		this.validateAnalyzeUrl(dataItem, info);
		const npiData = dataItem[info.npiField] ?? dataItem[info.keyField];
		return this.buildAnalyzeByNpiOrMedicalEntityTypeUrl(info.npiType as NpiType | MedicalEntityType, npiData.value);
	};

	private validateAnalyzeUrl(dataItem: Record<string, FormattedData>, info: GridInfo) {
		if (info.npiField && dataItem[info.npiField]) {
			if (!info.npiType) 
				throw Error('Grid is missing npiType');
			
		}
	}

	private buildAnalyzeByNpiOrMedicalEntityTypeUrl(type?: NpiType | MedicalEntityType, id?: any) {
		if (!type || !id) 
			return this.buildUrl([ROUTE_CONSTANTS.analyze.route]);
		

		const routeAttr = StrategyUtils.getRouteAttrByNpiType(type as NpiType) || StrategyUtils.getRouteAttrByMedicalEntityType(type as MedicalEntityType);
		if (!routeAttr) 
			return this.buildUrl([ROUTE_CONSTANTS.analyze.route]);
		

		return this.buildUrl(['analyze', routeAttr.route, id]);
	}

    private async navigateTo(path: Array<string>, params?: any) {
		const url = this.buildUrl(path, params);
		return this.router.navigateByUrl(url);
	}

	private buildUrl(relativePath: Array<string>, params?: any): UrlTree {
		const extras = params ? { queryParams: params } : {};
		const url = this.router.createUrlTree([...relativePath], extras);
		return url;
	}
}
