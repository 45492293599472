// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  background-color: #5b6770;
  color: white;
}

.fa-trash:hover {
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./appcore/components/comparisons-dialog/comparisons-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ","sourcesContent":["\n.active {\n    background-color: #5b6770;\n    color: white;\n}\n\n.fa-trash:hover {\n    font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
