import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginResponse, CognitoClient } from '../shared/models/authentication';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthApi {
	controller = 'Authentication';
	constructor(private api: ApiService, protected http: HttpClient) {}
	login(email, password): Observable<LoginResponse> {
		return this.api.post(this.controller, 'Login', {
			email,
			password
		});
	}

	loginCognito(jwtToken: string): Observable<LoginResponse> {
		return this.api.post(this.controller, 'LoginCognito', {
			jwtToken
		});
	}

	getCognitoClients(source: string): Observable<CognitoClient[]> {
		return this.api.get(this.controller, `CognitoClients?source=${source}`);
	}

	logout(): Observable<any> {
		return this.api.post(this.controller, 'Logout');
	}

	getCognitoClient(source: string): Observable<CognitoClient>{
		return this.api.post(this.controller, 'CognitoClient', {
			source
		});
	}

	identifyClient(email): Observable<CognitoClient> {
		return this.api.post(this.controller, 'IdentifyClient', {
			email
		});
	}
}
