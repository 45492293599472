import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ApplicationCacheService } from '../services/application-cache.service';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard  {
	constructor(
		private authService: AuthenticationService,
		private appCache: ApplicationCacheService,
		private navigationService: NavigationService,
        private authenticationService: AuthenticationService
	) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const token = await this.authenticationService.getCognitoToken();
		if (!token) 
			return this.onAuthError();
		
		// We have a valid token
		if (this.appCache.user) {
			this.navigationService.navigateToHome();
			return false;
		}

		// Haven't retrieved the current user yet, so do that now
		try {
			const response = await this.authService.getCurrentUser();
			const { user } = response;

			if (!user) {
				// Something went wrong
				return this.onAuthError();
			}

			this.navigationService.navigateToHome();
			return false;
		} catch (ex) {
			return this.onAuthError();
		}
	}

	private onAuthError() {
		return true;
	}
}
