import { NpiType } from '@appcore/enums/npi-type.enum';
import { NpiTypeConfig } from '@appcore/models/npi/npi-type-config.model';


export type NpiTypeKeys = 'physician' | 'physiciangroup' | 'homehealth' | 'hospice' | 'hospital' | 'skilledNursing';

export const NpiTypeConfigs: { [key in NpiTypeKeys]: NpiTypeConfig } = {
	physiciangroup: {
		npiType: NpiType.physicianGroup,
		routeString: 'physiciangroup',
		label: 'Physician Groups',
		labelSingular: 'Physician Group',
		icon: 'fas fa-users-class',
		colorClass: 'text-physician-group',
		bgClass: 'bg-physician-group'
	},
	physician: {
		npiType: NpiType.physician,
		routeString: 'physician',
		label: 'Physicians',
		labelSingular: 'Physician',
		icon: 'fas fa-user-md',
		colorClass: 'text-physician',
		bgClass: 'bg-physician'
	},
	homehealth: {
		npiType: NpiType.homeHealthAgency,
		routeString: 'homehealth',
		label: 'Home Health Agencies',
		labelSingular: 'Home Health Agency',
		icon: 'fas fa-home',
		colorClass: 'text-agency',
		bgClass: 'bg-agency'
	},
	hospice: {
		npiType: NpiType.hospice,
		routeString: 'hospice',
		label: 'Hospices',
		labelSingular: 'Hospice',
		icon: 'fas fa-hands-heart',
		colorClass: 'text-agency',
		bgClass: 'bg-agency'
	},
	hospital: {
		npiType: NpiType.hospital,
		routeString: 'hospital',
		label: 'Hospitals',
		labelSingular: 'Hospital',
		icon: 'fas fa-hospital',
		colorClass: 'text-facility',
		bgClass: 'bg-facility'
	},
	skilledNursing: {
		npiType: NpiType.skilledNursingFacility,
		routeString: 'skilledNursing',
		label: 'Skilled Nursing Facilities',
		labelSingular: 'Skilled Nursing Facility',
		icon: 'fas fa-user-nurse',
		colorClass: 'text-agency',
		bgClass: 'bg-agency'
	}
};

export const NpiTypeNpiTypeConfigMap = new Map<NpiType, NpiTypeConfig>([
	[NpiType.physician, NpiTypeConfigs.physician],
	[NpiType.hospital, NpiTypeConfigs.hospital],
	[NpiType.homeHealthAgency, NpiTypeConfigs.homehealth],
	[NpiType.hospice, NpiTypeConfigs.hospice],
	[NpiType.skilledNursingFacility, NpiTypeConfigs.skilledNursing]
]);
