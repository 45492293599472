import { Component, OnInit, Input } from '@angular/core';
import { NetworkAcoService } from '../../shared/services/network-aco.service';
import { NetworkAcoOption } from '../../shared/models/network-aco-option';

import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { takeUntil } from 'rxjs/operators';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';

@Component({
	selector: 'mosaic-network-selector',
	templateUrl: './network-selector.component.html',
	styleUrls: ['./network-selector.component.scss']
})
export class NetworkSelectorComponent extends ComponentWithSubscription implements OnInit {
	@Input() mini = false;
	selectedNetworkAco = new NetworkAcoOption();
	networks: NetworkAcoOption[];
	showNpiSelector = false;

	constructor(private networkAcoService: NetworkAcoService, private acoDashboardService: AcoDashboardService) {
		super();
	}


	ngOnInit(): void {
		this.networkAcoService.getOptions()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(networks => {
				this.networks = networks;
				const selected = this.acoDashboardService.getLastViewedNetworkAco();
				const selectedNetwork = this.networks?.find(network => +network.id === selected);
				this.selectedNetworkAco = selected && selectedNetwork ? selectedNetwork : new NetworkAcoOption();
				if (this.acoDashboardService.selectedNetworkAcoIsNull()) 
					this.handleSelectNetworkAcoEvent(this.selectedNetworkAco);
				
			});

		this.acoDashboardService.selectedNetworkAco
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(network => {
				this.selectedNetworkAco = network ? this.networks?.find(n => +n.id === +network.id) ?? new NetworkAcoOption() : new NetworkAcoOption();
			});
	}

	toggleNpiSelector() {
		this.showNpiSelector = !this.showNpiSelector;
	}

	handleSelectNetworkAcoEvent(networkAco: NetworkAcoOption) {
		this.showNpiSelector = false;
		this.acoDashboardService.selectNetworkAco(networkAco);
	}
}
