import { NpiType } from '../../enums/npi-type.enum';

export class NpiSearchResult {
    alias?: string;
    city?: string;
    display: string;
    icon?: string;
    npi: string;
    npiType?: NpiType;
    search?: string;
    specialty?: string;
    state?: string;
    zipcode?: string;
}
