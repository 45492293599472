import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'singularPossessive' })
export class SingularPossessivePipe implements PipeTransform {
	transform(fullName: string, firstName: string, lastName: string): any {
		if(fullName)
			return `${fullName}'s`;
		
		if(firstName && lastName)
			return `${firstName} ${lastName}'s`;
		
		if(lastName)
			return `${lastName}'s`;
		
		if(firstName)
			return `${firstName}'s`;
		
		return '';
	}
}
