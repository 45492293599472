// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metrics:first-child {
  margin-top: 27px;
}

.metric {
  font-size: 1.75rem;
  font-weight: 100;
  line-height: 0.8;
  margin-bottom: 1rem;
}

.metricSubtitle {
  font-size: 1.25rem;
  font-weight: 550;
  margin-top: 0.3125rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/aco-pmpm/aco-pmpm-sums/aco-pmpm-sums.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAEA;EACC,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AACD;;AAEA;EACC,kBAAA;EACA,gBAAA;EACA,qBAAA;AACD","sourcesContent":[".metrics:first-child {\n\tmargin-top: 27px;\n}\n\n.metric {\n\tfont-size: 1.75rem;\n\tfont-weight: 100;\n\tline-height: 0.8;\n\tmargin-bottom: 1rem;\n}\n\n.metricSubtitle {\n\tfont-size: 1.25rem;\n\tfont-weight: 550;\n\tmargin-top: .3125rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
