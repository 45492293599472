// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `i.fixed {
  position: fixed;
  left: 47.09%;
  margin-top: 110px;
  margin-left: 40px;
}

h1.fixed {
  position: fixed;
  left: 47%;
  margin-top: 100px;
  margin-left: 0px;
}

p.fixed {
  position: fixed;
  left: 46%;
  margin-top: 250px;
  margin-left: -33px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/not-found/not-found.component.scss"],"names":[],"mappings":"AAEA;EACC,eAAA;EACG,YAAA;EACA,iBAAA;EACA,iBAAA;AADJ;;AAIE;EACD,eAAA;EACG,SAAA;EACA,iBAAA;EACA,gBAAA;AADJ;;AAIE;EACD,eAAA;EACG,SAAA;EACA,iBAAA;EACA,kBAAA;AADJ","sourcesContent":["@use 'appcore/styles/variables' as variables;\n\ni.fixed {\n\tposition: fixed;\n    left: 47.09%;\n    margin-top: 110px;\n    margin-left: 40px;\n  }\n\n  h1.fixed {\n\tposition: fixed;\n    left: 47%;\n    margin-top: 100px;\n    margin-left: 0px;\n  }\n\n  p.fixed {\n\tposition: fixed;\n    left: 46%;\n    margin-top: 250px;\n    margin-left: -33px\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
