import { AfterViewInit, Component, Input } from '@angular/core';

import { Tile } from 'src/app/shared/models/tile.model';
import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { NetworkAcoOption } from 'src/app/shared/models/network-aco-option';
import { PROVIDER_TYPE, ROUTE_CONSTANTS } from 'src/app/shared/shared.constants';
import { TILE_DRILLDOWN_TYPE } from 'src/app/shared/enums/tile-drilldown-type.enum';

enum TileChartCategories {
	PMPM = 'PMPM',
	NationalAverage = 'National MSSP Average'
};
@Component({
	// I wanted to name this selector 'mosaic-aco-mosaic'
	selector: 'mosaic-aco-tile-grid',
	templateUrl: './aco-tile-grid.component.html',
	styleUrls: ['./aco-tile-grid.component.scss']
})
export class AcoTileGridComponent implements AfterViewInit{
	@Input() draggable = true;
	@Input() empty = false;
	@Input() loading = false;
	@Input() tiles: Tile[] = [];
	@Input() hideDetailButtons = false;

	constructor(public acoDashboardService: AcoDashboardService) {}

	get hasNoTiles(){
		return !this.tiles.length && !this.empty && !this.loading;
	}

	selectNetworkAco(networkAco: NetworkAcoOption) {
		this.acoDashboardService.selectNetworkAco(networkAco);
	}

	hideDetailButton(tile: Tile){
		return this.hideDetailButtons && tile.buttonText && tile.buttonText.toLowerCase() === 'details' && !this.isPmpm(tile);
	}

	isPmpm(tile: Tile) : boolean{
		return tile.drillDownType === TILE_DRILLDOWN_TYPE.PMPM;
	}

	getRouteToAddPhysicians() {
		return `/${ROUTE_CONSTANTS.explore.route}/${PROVIDER_TYPE.physician}`;
	}

	getTileSize(tile: Tile): string {
		switch (`${tile.width},${tile.height}`) {
			case '1,1':
				return '_1x1';
			case '1,2':
				return '_1x2';
			default:
				return '';
		}
	}

	ngAfterViewInit() {
		this._enableDragging(this.draggable);
	}

	private _enableDragging(enabled: boolean) {
		if (!enabled) 
			return;
		

		const dragStart = ev => {
			const element = document.getElementById(ev.target.id);
			const clone = element.cloneNode(true) as HTMLElement;
			ev.target.classList.add('dragging');
			ev.dataTransfer.setData('text/plain', ev.target.id);
			clone.classList.add('dragging_clone');
			document.body.appendChild(clone);
			ev.dataTransfer.setDragImage(clone, 200, 45);
		};

		const dragEnd = ev => {
			const element = document.getElementById(ev.target.id);
			element.classList.remove('dragging');
			const elements = document.getElementsByClassName('dragging_clone');
			while (elements.length > 0) 
				elements[0].parentNode.removeChild(elements[0]);
			
		};

		const drop = ev => {
			ev.preventDefault();
			const draggableId = ev.dataTransfer.getData('text');
			const draggable = document.getElementById(draggableId);
			const droppable = ev.currentTarget;
			const draggablePosition = +draggable.id.match(/\d+/)[0];
			const droppablePosition = +droppable.id.match(/\d+/)[0];
			draggable.id = 'd_' + droppablePosition;
			droppable.id = 'd_' + draggablePosition;
			[this.tiles[draggablePosition], this.tiles[droppablePosition]] = [this.tiles[droppablePosition], this.tiles[draggablePosition]];
		};

		const dragover = ev => {
			ev.preventDefault();
		};

		const els = document.getElementsByClassName('draggable');
		let c = 0;
		Array.prototype.forEach.call(els, e => {
			e.draggable = true;
			e.ondragstart = dragStart;
			e.ondragend = dragEnd;
			e.ondrop = drop;
			e.ondragover = dragover;
			if (e.id === '') {
				e.id = 'd_' + c;
				c++;
			}
		});
	}

}
