// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-button {
  position: absolute;
  width: 90%;
  bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/explore/add-to-network-modal/add-to-network-modal.component.scss"],"names":[],"mappings":"AAEA;EACC,kBAAA;EACG,UAAA;EACA,YAAA;AADJ","sourcesContent":["@use 'appcore/styles/variables' as variables;\n\n.bottom-button{\n\tposition: absolute;\n    width: 90%;\n    bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
