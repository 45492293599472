import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { BaseMetric } from 'src/app/shared/models/base-metric';
import { MetricSelection } from 'src/app/shared/models/metric-selection';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	CheckedSelection
} from '@appcore/interfaces/checked-selection.interface';

@Component({
	selector: 'mosaic-base-metrics',
	templateUrl: './metrics.component.html',
	styleUrls: ['./metrics.component.scss']
})
export class BaseMetricsComponent extends ComponentWithSubscription {
	@Input() currentMetrics: BaseMetric[];
	@Input() filteredMetrics: BaseMetric[];
	@Input() opened: boolean;
	@Input() searchPlaceholder = 'Search to select your metric';
	@Input() tags: CheckedSelection[];
	@Input() loading = false;
	
	@Output() closed: Observable<void>;
	@Output() metricsChanged: Observable<MetricSelection>;
	@Output() viewed: Observable<BaseMetric[]>;
	
	userSearchString = '';

	private _closed: EventEmitter<void> = new EventEmitter();
	private _metricsChanged: EventEmitter<MetricSelection> = new EventEmitter();
	private _viewed: EventEmitter<BaseMetric[]> = new EventEmitter();


	constructor() {
		super();
		this.closed = this._closed.asObservable();
		this.metricsChanged = this._metricsChanged.asObservable();
		this.viewed = this._viewed.asObservable();
	}

	add(metric: BaseMetric): void {
		this.currentMetrics.push(metric);
		this._metricsChanged.emit(new MetricSelection(this.currentMetrics, this.userSearchString, metric));
	}

	close(): void {
		this._closed.emit();
	}

	drop(e: CdkDragDrop<string[]>): void {
		const metric = this.currentMetrics[e.previousIndex];
		this.currentMetrics.splice(e.previousIndex, 1);
		this.currentMetrics.splice(e.currentIndex, 0, metric);
		this.refreshMetricOrder();
		this.filterMetrics();
	}

	filterMetrics(): void {
		this._metricsChanged.emit(new MetricSelection(this.currentMetrics, this.userSearchString));
	}

	isTagged(id: number): boolean {
		return this.tags.find(t => t.id === id).checked;
	}

	moveUp(index: number): void {
		const metric = this.currentMetrics[index];
		this.currentMetrics.splice(index, 1);
		this.currentMetrics.splice(index - 1, 0, metric);
		this.refreshMetricOrder();
		this.filterMetrics();
	}

	moveDown(index: number): void {
		const metric = this.currentMetrics[index];
		this.currentMetrics.splice(index, 1);
		this.currentMetrics.splice(index + 1, 0, metric);
		this.refreshMetricOrder();
		this.filterMetrics();
	}

	remove(metric: BaseMetric): void {
		const index = this.currentMetrics.findIndex(c => c.name === metric.name);
		this.currentMetrics.splice(index, 1);
		this.refreshMetricOrder();
		this.filterMetrics();
	}

	toggleTag(id: number): void {
		const tag = this.tags.find(t => t.id === id);
		tag.checked = !tag.checked;
		this._metricsChanged.emit(new MetricSelection(this.currentMetrics, this.userSearchString, null, true));
	}

	view(): void {
		this._viewed.emit(this.currentMetrics);
	}

	private refreshMetricOrder(): void {
		this.currentMetrics.map((m, i) => {
			m.order = i;
		});
	}
}
