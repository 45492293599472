import { Injectable } from '@angular/core';
import { HttpOptions, HttpRequest } from './http-request';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(private httpRequest: HttpRequest) {}

	static encodeQueryParam(object: any) {
		const ret = [];
		const usedKeys = [];

		for (const key of Object.keys(object)) {
			const value = object[key];
			const qKey = key
				.toLowerCase()
				.split('')
				.find(e => !usedKeys.includes(e));
			usedKeys.push(qKey);
			if (value instanceof Array) {
				if (value.length) {
					value.forEach(v => {
						ret.push(qKey + '=' + v);
					});
				}
			} else if (value) 
				ret.push(qKey + '=' + value);
			
		}
		return ret.join('&');
	}

	static makeList(object: any, listKey: string) {
		const ret = [];

		for (const key of Object.keys(object)) {
			const value = object[key];
			if (value instanceof Array) {
				if (value.length) {
					value.forEach(v => {
						ret.push(key + '=' + v);
					});
				}
			} else if (value) 
				ret.push(listKey + '=' + value);
			
		}
		return ret.join('&');
	}

	get<T = any>(controller: string, route?: string, params?: any, useHttpOverride?: boolean, doNotWrapObservable?: boolean): Observable<T> {
		const url = this.getUrl(controller, route);
		return this.httpRequest.get<T>(url, params, useHttpOverride, doNotWrapObservable);
	}

	post<T = any>(controller: string, route?: string, params?: any, options?: HttpOptions, doNotWrapObservable?: boolean): Observable<T> {
		const url = this.getUrl(controller, route);
		return this.httpRequest.post<T>(url, params, options, doNotWrapObservable);
	}

	patch(controller: string, route?: string, params?: any, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.httpRequest.patch(url, params, options);
	}

	delete(controller: string, route?: string, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.httpRequest.delete(url, options);
	}

	put(controller: string, route?: string, params?: any, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.httpRequest.put(url, params, options);
	}

	getUrl(controller: string, route?: string) {
		return this.httpRequest.getUrl(controller, route);
	}
}
