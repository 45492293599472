// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: separate;
  border-spacing: 0.3em;
}

a {
  color: #43b02a;
  text-decoration: underline;
}

.table-data-text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25;
}`, "",{"version":3,"sources":["webpack://./appcore/components/chart/chart-types/top-places-insight/top-places-insight.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,cAAA;EACA,0BAAA;AACJ;;AAEA;EACI,2CAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":["table {\n    border-collapse: separate;\n    border-spacing: 0.3em;\n}\n\na {\n    color: #43b02a;\n    text-decoration: underline;\n}\n\n.table-data-text {\n    font-family: \"Roboto Condensed\", sans-serif;\n    font-size: 0.75rem;\n    font-weight: 700;\n    line-height: 1.25;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
