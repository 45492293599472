import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AcoOptionResponse } from '../shared/models/aco-options';
import { PmpmSpending } from '../shared/models/pmpm-spending';
import { AcoPeriod } from '../shared/models/aco-period';
import { YearsAco } from '@appcore/models/aco/years-aco.model';
import { Aco } from '@appcore/models/aco/aco.model';

@Injectable({
	providedIn: 'root'
})
export class AcoApi {
	_controllerName = 'Aco';

	constructor(private api: ApiService) {}

	getAco(acoId: string): Observable<Aco> {
		return this.api.get(this._controllerName, `${acoId}`);
	}

	/**
	 * Gets the acos corresponding on an inpatient hospital npi
	 */
	getAcoOptions(id: string, state: string): Observable<AcoOptionResponse[]> {
		return this.api.get(this._controllerName, `options/${id}?s=${state}`);
	}

	/**
	 * Gets all acos available to the user
	 */
	getAcos(): Observable<YearsAco[]> {
		return this.api.get(this._controllerName, '');
	}

	/**
	 * Gets years available to the user based on a CMS ACO
	 */
	 getAvailableCmsAcoYears(): Observable<AcoPeriod[]> {
		const route = `${this._controllerName}/GetAvailableYears`;
		return this.api.get(route);
	}

	getCmsAcoPmpmSums(acoId: string): Observable<PmpmSpending[]> {
		return this.api.get(this._controllerName, `cms/pmpm-sums/${acoId}`);
	}

	getLatestFullClaimsYear(): Observable<string> {
		return this.api.get(this._controllerName, 'latest-full-claims-year');
	}

	getUserAcoPmpmSums(networkId: number, acoPeriodId: number): Observable<PmpmSpending[]> {
		return this.api.get(this._controllerName, `user/pmpm-sums/${networkId}/${acoPeriodId}`);
	}

	hasPhysicians(networkId: number): Observable<boolean> {
		return this.api.get(this._controllerName, `user/has-physicians/${networkId}`);
	}
}
