import { Component, Input, OnInit } from '@angular/core';

import { TILE_LAYOUT_TYPE } from 'src/app/shared/enums/tile-layout-type.enum';
import { TileElement } from 'src/app/shared/models/tile-element.model';
import { Tile } from 'src/app/shared/models/tile.model';
import { AcoDetailConfigs } from 'src/app/main/aco-dashboard/aco-dashboard.constants';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SeriesLabelsVisualArgs, RenderEvent } from '@progress/kendo-angular-charts';
import { Group, Text, geometry, Path } from '@progress/kendo-drawing';
const { transform } = geometry;
import { AcoTileService } from '../../../shared/services/aco-tile.service';
import { TILE_DRILLDOWN_TYPE } from '../../../shared/enums/tile-drilldown-type.enum';
import { TrellaFormat } from 'src/app/shared/methods/trella-format';
import { ACO_DESTINATIONS } from 'src/app/main/analyze/analyze-aco/aco-tab-models/aco-destinations.models';
import { AcoInsightDetailConfigs } from 'src/app/main/analyze/analyze-aco/aco-tab-models/aco-insights.models';
import { ACO_PARTICIPATING_PROVIDERS } from 'src/app/main/analyze/analyze-aco/aco-tab-models/aco-participating-providers.models';
import { PROVIDER_TYPE } from '../../../shared/shared.constants';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { GridCellEnumType } from '@appcore/enums/grid-cell-enum-type.enum';
import { PercentileQuality } from '@appcore/enums/percentile-quality.enum';

enum TileChartCategories {
	PMPM = 'PMPM',
	NationalAverage = 'National MSSP Average'
};
@Component({
	selector: 'mosaic-aco-tile',
	templateUrl: './aco-tile.component.html',
	styleUrls: ['./aco-tile.component.scss']
})
export class AcoTileComponent extends ComponentWithSubscription implements OnInit {
	@Input() tile: Tile;
	@Input() isButtonHidden = false;

	TILE_LAYOUT_TYPE = TILE_LAYOUT_TYPE;
	GridCellEnumType = GridCellEnumType;
	riskAdjustSwitchId: string;
	isRiskAdjusted = true;
	colors = {
		green: '#8AC591',
		blue: '#D3E0F7',
		red: '#D5839A',
		orange: '#FF8200'
	};
	categories = TileChartCategories;
	targetTooltipText = '';
	
	constructor(private navigationService: NavigationService,
		 private acoTileService: AcoTileService) {
		super();
	}

	ngOnInit() {
		this.isRiskAdjusted = this.acoTileService.isRiskAdjusted(this.tile);
		this.riskAdjustSwitchId = `${this.tile.elements[0].title.replace(' ', '_').toLowerCase()}_risk_adjust_switch-id`;
	}

	onRiskAdjustToggleChange() {
		this.isRiskAdjusted = !this.isRiskAdjusted;
		this.acoTileService.setRiskAdjusted(this.tile);
	}

	getBenchmark(tile: Tile) {
		return this.isRiskAdjusted? tile.benchmarkRiskAdjustment : tile.benchmark;
	}

	getEffectiveTarget(tile: Tile) {
		return this.isRiskAdjusted? tile.riskAdjustedTarget : tile.target;
	}

	getMetric(tileElement: TileElement) {
		return this.isRiskAdjusted? tileElement.riskAdjustedValue : tileElement.value;
	}

	getTargetTooltipText(tile: Tile) {
		return this.tile.acoId && this.tile.acoId.length ? 'This represents the PMPM Target for the Performance Year selected.  Source: CMS (https://www.cms.gov/Research-Statistics-Data-and-Systems/Downloadable-Public-Use-Files/SSPACO)'
		: this.tile.networkId ? 'This represents the user-supplied PMPM Target.' : '';
	}

	getPercentile(tile: Tile) {
		return this.isRiskAdjusted?
			tile.riskAdjustedPercentile :
			tile.percentile;
	}

	getPercentileValue(tile: Tile){
		const text = this.getPercentile(tile);
		const percentile = text.replace('%', '');
		return parseInt(percentile, 10);
	}

	getPmpmBarColor(tile: Tile): string {
		if (!this.hasTarget(tile)) 
			return this.colors.green;
		
		const targetSurpassed = Math.round(this.getPmpmBarValue(tile)) > this.getEffectiveTarget(tile);
		return targetSurpassed ? this.colors.red : this.colors.green;
	}

	getPmpmBarValue(tile: Tile): number {
		return this.isRiskAdjusted ? tile.chart.pmpm_adj : tile.chart.pmpm;
	}

	getNationalAverageBarValue(tile: Tile): number {
		return this.isRiskAdjusted ? tile.chart.national_pmpm_adj : tile.chart.national_pmpm;
	}

	getQuality(tile: Tile) : PercentileQuality {
		if (this.isNeutral(tile)) 
			return PercentileQuality.Moot;
		

		const percentile = this.getPercentileValue(tile);

		if ((tile.isHigherValueBetter && percentile >= 50) ||
			(!tile.isHigherValueBetter && percentile <= 50)) 
			return PercentileQuality.Good;
		

		return PercentileQuality.Bad;
	}

	getQualifier(tile: Tile) {
		return this.isNeutral(tile)? 'higher' : 'better';
	}

	hasMetric(tile: Tile) {
		return tile.elements.some(e => {
			const metric = this.getMetric(e);
			return metric && metric !== '-';
		});
	}

	hasPercentile(tile: Tile) {
		const percentile = this.isRiskAdjusted? tile.riskAdjustedPercentile : tile.percentile;
		return !!(percentile && percentile !== '-');
	}

	hasChart(tile: Tile): boolean {
		return tile.chart !== null;
	}

	hasTarget(tile: Tile): boolean {
		const target = this.isRiskAdjusted? tile.riskAdjustedTarget : tile.target;
		return !!target;
	}

	isNegative(trend: string): boolean {
		return trend === '-';
	}

	isNeutral(tile: Tile) {
		return tile.isHigherValueBetter === null || tile.isHigherValueBetter === undefined;
	}

	onClick(tile: Tile, exploreSettingId?: string) {
		switch (tile.drillDownType) {
			case TILE_DRILLDOWN_TYPE.Explore:
				this.navigationService.navigateToExplore(PROVIDER_TYPE.physician, {
					exploreSettingId,
					acoId: tile.acoId,
					networkId: tile.networkId
				});
				break;
			case TILE_DRILLDOWN_TYPE.PMPM:
				if(tile.acoId){
					this.navigationService.navigateToAcoAnalyzeChild(tile.acoId, AcoInsightDetailConfigs.pmpm.routeString);
					break;
				}
				this.navigationService.navigateToDashboardChild(AcoDetailConfigs.pmpm.routeString);
				break;
			case TILE_DRILLDOWN_TYPE.Destinations:
				this.navigationService.navigateToAcoAnalyze(tile.acoId, ACO_DESTINATIONS.id);
				break;
			case TILE_DRILLDOWN_TYPE.ParticipatingProviders:
				this.navigationService.navigateToAcoAnalyze(tile.acoId, ACO_PARTICIPATING_PROVIDERS .id);
				break;
		}
	}

	formatLabel(e: SeriesLabelsVisualArgs): Group {
		if (!e.value) 
			return;
		
		const defaultLabel = e.createVisual();
		const value = `${TrellaFormat.wholeDollars(Math.round(e.value))}`;
		const text = new Text(value, [defaultLabel.bbox().center().getX(), defaultLabel.bbox().center().getY()]);
		text.transform(transform().translate(-(text.bbox().width() / 2), 0));
		const group = new Group();
		group.append(text);

		return group;
	}

	onRenderChart(args: RenderEvent): void {
		if (!this.hasTarget(this.tile)) 
			return;
		
		const target = this.getEffectiveTarget(this.tile);
		const chart = args.sender;
		const valueAxis = chart.findAxisByName('valueAxis');
		const categoryAxis = chart.findAxisByName('categoryAxis');
		const valueSlot = valueAxis.slot(target) as geometry.Rect;
		const range = categoryAxis.range();
		const categorySlot = categoryAxis.slot(range.min as number, range.max as number) as geometry.Rect;

		const line = new Path({
			opacity: 0.6,
			stroke: {
				color: this.colors.orange,
				width: 2
			}
		}).moveTo(valueSlot.origin)
			.lineTo(categorySlot.topRight().x, valueSlot.origin.y);

		const label = new Text(`PMPM Target: ${TrellaFormat.wholeDollars(target)}`, [0, 0]);

		const offset = this.getTargetPixelOffset(target);
		label.position([categorySlot.topRight().x - offset, valueSlot.origin.y]);

		const group = new Group();
		group.append(line, label);

		chart.surface.draw(group);
	}

	getTargetPixelOffset(target: number) {
		return this.isAtLeastFourDigits(target) ? 183: 180;
	}

	isAtLeastFourDigits(numb: number): boolean {
		return numb >= 1000;
	}

	isDoubleHeight(tile: Tile) {
		return this.acoTileService.isDoubleHeight(tile);
	}

	isSingleHeight(tile: Tile) {
		return this.acoTileService.isSingleHeight(tile);
	}
}
