import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NavigationService } from '../shared/services/navigation.service';
import { ERROR_MESSAGES } from '../shared/shared.constants';

@Component({
    selector: 'mosaic-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
    landingMessage = ERROR_MESSAGES.authenticationFailed;

    constructor(private authenticationService: AuthenticationService, private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.authenticationService.logoutWithoutNavigate();
    }

    async toLogin() {
        await this.navigationService.navigateToLogin();
    }
}
