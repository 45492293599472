import { Store } from '@ngrx/store';
import { AppState } from './../../store/rootReducer';
import { selectNetworkAco } from './../../main/aco-dashboard/aco-dashboard.actions';
import { NetworkAcoOption } from './../models/network-aco-option';
import { Injectable } from '@angular/core';
import { Selection } from '@appcore/interfaces/selection.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { NpiApi } from 'src/app/api/npi.api';
import { LOCAL_STORAGE_KEY } from '../../shared/shared.constants';
import { NetworkApi } from 'src/app/api/network.api';
import { map } from 'rxjs/operators';
import { AcoNpi } from '@appcore/models/npi/aco-npi.model';

@Injectable({
	providedIn: 'root'
})
export class AcoDashboardService {
	public readonly selectedAcoNpiInfo: Observable<AcoNpi[]>;
	public readonly beforeSelectAco: Observable<any>;
	public readonly countiesSelected: Observable<Selection[]>;
	public readonly networkPhysicianCountiesLoaded: Observable<Selection[]>;
	public readonly selectedNetworkAco: Observable<NetworkAcoOption>;
	
	private _selectedAcoNpiInfo: BehaviorSubject<AcoNpi[]> = new BehaviorSubject([]);
	private _beforeSelectAco: BehaviorSubject<any> = new BehaviorSubject(false);
	private _countiesSelected: BehaviorSubject<Selection[]> = new BehaviorSubject([]);
	private _networkPhysicianCountiesLoaded: BehaviorSubject<Selection[]> = new BehaviorSubject([]);
	private _selectedNetworkAco: BehaviorSubject<NetworkAcoOption> = new BehaviorSubject(null);
	
	constructor(private store: Store<AppState>, private npiApi: NpiApi, private networkApi: NetworkApi) {
		this.selectedAcoNpiInfo = this._selectedAcoNpiInfo.asObservable();
		this.beforeSelectAco = this._beforeSelectAco.asObservable();
		this.countiesSelected = this._countiesSelected.asObservable();
		this.networkPhysicianCountiesLoaded = this._networkPhysicianCountiesLoaded.asObservable();
		this.selectedNetworkAco = this._selectedNetworkAco.asObservable();
	}

	selectNetworkAco(networkAco: NetworkAcoOption) {
		if(networkAco) {
			this.setLastViewedNetworkAco(networkAco.id);
			this._selectedNetworkAco.next(networkAco);
			this.store.dispatch(selectNetworkAco(networkAco));
			this._beforeSelectAco.next(true);
			this.networkApi.getPhysicianCounties(+networkAco.id).
				subscribe(counties => this._networkPhysicianCountiesLoaded.next(counties));
		}
	}

	selectCounties(counties: Selection[]){
		this._countiesSelected.next(counties);
	}

	selectedNetworkAcoIsNull()
	{
		return this._selectedNetworkAco.getValue() === null;
	}

	setSelectedAcoNpiInfo(networkAco: NetworkAcoOption) {
		this._beforeSelectAco.next(true);
		// eslint-disable-next-line
		networkAco.isUserDefined ? this.requestUserAcoNpiInfo(networkAco.id) : this.requestCmsAcoNpiInfo(networkAco.id);
	}

	getSelectedNetworkAco() {
        const networkId = +localStorage.getItem(LOCAL_STORAGE_KEY.MOSAIC_NETWORKS_VIEW_NETWORKS_NPIGROUP);
        this.networkApi.list().pipe(
            map(networks => {
                const results = networks.map(network => NetworkAcoOption.fromNetwork(network));
								const selectedNetwork = results.find(f => f.networkId === networkId) ?? results[0] ?? null;
                this._selectedNetworkAco.next(selectedNetwork);
								this.networkApi.getPhysicianCounties(+selectedNetwork.id).
									subscribe(counties => this._networkPhysicianCountiesLoaded.next(counties));
            })
        ).subscribe();
    }

	setLastViewedNetworkAco(id: string): void {
		localStorage.setItem(LOCAL_STORAGE_KEY.MOSAIC_NETWORKS_VIEW_NETWORKS_NPIGROUP, id);
	}

	getLastViewedNetworkAco(): number {
		return +localStorage.getItem(LOCAL_STORAGE_KEY.MOSAIC_NETWORKS_VIEW_NETWORKS_NPIGROUP);
	}

	private requestCmsAcoNpiInfo(aco: string) {
		this.npiApi.getCmsAcoNpiInfo(aco).subscribe( response => {
					this._selectedAcoNpiInfo.next(response);
			});
	}

	private requestUserAcoNpiInfo(aco: string) {
		this.npiApi.getUserAcoNpiInfo(aco).subscribe( response => {
				this._selectedAcoNpiInfo.next(response);
		});
	}
}
