import { ToolContainerComponent, DataDisplayComponent } from '../../../../shared/models/display';

const ACO_INSIGHTS_CHARTS: DataDisplayComponent[] = [

];

const ACO_INSIGHTS_RISK_ADJ_CHARTS: DataDisplayComponent[] = [];

const ACO_INSIGHTS_GRIDS: DataDisplayComponent[] = [

];

const ACO_INSIGHTS_RISK_ADJ_GRIDS: DataDisplayComponent[] = [];

const ACO_INSIGHTS_TOOLBARS: ToolContainerComponent[] = [

];

export const ACO_INSIGHTS = {
	title: 'Insights',
	id: 'Insights',
	riskAdjKey: 'trella-insights-risk-adj-toggle',
	configuration: {
		TOOLBARS: [...ACO_INSIGHTS_TOOLBARS],
		CHART_VIEW: [...ACO_INSIGHTS_CHARTS],
		GRID_VIEW: [...ACO_INSIGHTS_GRIDS],
		RISK_ADJ_CHART_VIEW: [...ACO_INSIGHTS_RISK_ADJ_CHARTS],
		RISK_ADJ_GRID_VIEW: [...ACO_INSIGHTS_RISK_ADJ_GRIDS]
	}
};

export const ACO_DETAILS_PATH = {
	ACO_DETAIL: 'details',
	ACO_DETAIL_PMPM: 'pmpm'
};

export const AcoInsightDetailConfigs: { [key in AcoInsightDetailKeys]: AcoInsightDetailConfig } = {
	pmpm: {
		routeString: [ACO_DETAILS_PATH.ACO_DETAIL, ACO_DETAILS_PATH.ACO_DETAIL_PMPM]
	}
};

export type AcoInsightDetailKeys = 'pmpm';
export interface AcoInsightDetailConfig {
    routeString: string[];
}