// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `cdk-virtual-scroll-viewport {
  display: block;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.radio-button {
  font-size: smaller;
}

.radio-group > div:nth-of-type(1) .radio-button {
  margin-top: 5px;
}

::ng-deep .mat-mdc-radio-button .mdc-radio__outer-circle {
  border-color: #5b6770 !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio__inner-circle {
  color: #5b6770 !important;
  background-color: #5b6770 !important;
}

::ng-deep .mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  display: none;
  background-color: #333f48 !important;
}`, "",{"version":3,"sources":["webpack://./appcore/components/list-box/list-box.component.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;AADJ;;AAIA;EACI,oBAAA;EACA,YAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAKI;EACI,eAAA;AAFR;;AAMA;EACI,gCAAA;AAHJ;;AAMA;EACI,yBAAA;EACA,oCAAA;AAHJ;;AAMA;EACI,aAAA;EACA,oCAAA;AAHJ","sourcesContent":["@import '../../styles/variables';\n\ncdk-virtual-scroll-viewport {\n    display: block;\n}\n\n.disabled {\n    pointer-events: none;\n    opacity: 0.4;\n}\n\n.radio-button {\n    font-size: smaller;\n}\n\n.radio-group > div:nth-of-type(1) {\n    .radio-button {\n        margin-top: 5px;\n    }\n}\n\n::ng-deep .mat-mdc-radio-button .mdc-radio__outer-circle {\n    border-color: $exc-primary !important;\n}\n\n::ng-deep .mat-mdc-radio-button .mdc-radio__inner-circle {\n    color: $exc-primary !important;\n    background-color: $exc-primary !important;\n}\n\n::ng-deep .mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {\n    display: none;\n    background-color: $exc-secondary !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
