// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover-page-width {
  max-width: 1000px;
}

.logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/logout/logout.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACC,cAAA;EACG,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".cover-page-width {\n  max-width: 1000px;\n}\n\n.logo{\n\tdisplay: block;\n    margin-right: auto;\n    margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
