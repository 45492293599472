// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swatch_INP {
  color: rgb(198, 96, 128);
}

.swatch_HHA {
  color: rgb(34, 177, 2);
}

.swatch_RHHA {
  color: rgb(196, 214, 0);
}

.swatch_SNF {
  color: rgb(255, 170, 101);
}

.swatch_HOS {
  color: rgb(134, 169, 236);
}

.swatch_DECEASED {
  color: rgb(51, 63, 72);
}

.swatch_HOME {
  color: rgb(194, 202, 218);
}

.swatch_OUTPEROBS {
  color: rgb(208, 162, 177);
}

.swatch_INPER {
  color: rgb(135, 65, 87);
}`, "",{"version":3,"sources":["webpack://./src/app/components/journey/journey-key/journey-key.component.scss","webpack://./src/app/components/journey/_sunburst-colors.scss"],"names":[],"mappings":"AAEA;EACC,wBCEK;ADHN;;AAIA;EACC,sBCLK;ADIN;;AAIA;EACC,uBCHM;ADEP;;AAIA;EACC,yBCNK;ADKN;;AAIA;EACC,yBCfK;ADcN;;AAIA;EACC,sBCtBU;ADqBX;;AAIA;EACC,yBCxBM;ADuBP;;AAIA;EACC,yBCxBW;ADuBZ;;AAIA;EACC,uBC7BO;AD4BR","sourcesContent":["@use '../_sunburst-colors.scss' as sunburst-colors;\n\n.swatch_INP {\n\tcolor: sunburst-colors.$INP;\n}\n\n.swatch_HHA {\n\tcolor: sunburst-colors.$HHA;\n}\n\n.swatch_RHHA {\n\tcolor: sunburst-colors.$RHHA;\n}\n\n.swatch_SNF {\n\tcolor: sunburst-colors.$SNF;\n}\n\n.swatch_HOS {\n\tcolor: sunburst-colors.$HOS;\n}\n\n.swatch_DECEASED {\n\tcolor: sunburst-colors.$DECEASED;\n}\n\n.swatch_HOME {\n\tcolor: sunburst-colors.$HOME;\n}\n\n.swatch_OUTPEROBS {\n\tcolor: sunburst-colors.$OUTPEROBS;\n}\n\n.swatch_INPER {\n\tcolor: sunburst-colors.$INPER;\n}\n","$center: rgb(221, 165, 182);\n$DECEASED: rgb(51, 63, 72);\n$HHA: rgb(34, 177, 2);\n$HOME: rgb(194, 202, 218);\n$HOS: rgb(134, 169, 236);\n$INP: rgb(198, 96, 128);\n$INPER: rgb(135, 65, 87);\n$OUTPEROBS: rgb(208, 162, 177);\n$RHHA: rgb(196, 214, 0);\n$SNF: rgb(255, 170, 101);\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
