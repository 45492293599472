import { NgModule } from '@angular/core';
import { MarketsPipe } from './pipes/markets.pipe';
import { NetworkTypePipe } from './pipes/network-type.pipe';
import { SingularPossessivePipe } from './pipes/singular-possessive.pipe';
import { ViewTypePipe } from './pipes/view-type.pipe';

@NgModule({
	imports: [],
	declarations: [MarketsPipe, NetworkTypePipe, SingularPossessivePipe, ViewTypePipe],
	exports: [MarketsPipe, NetworkTypePipe, SingularPossessivePipe, ViewTypePipe]
})
export class PipeModule {
	static forRoot() {
		return {
			ngModule: PipeModule,
			providers: []
		};
	}
}
