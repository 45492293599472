
import { createAction, props } from '@ngrx/store';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	MedicalEntityType
} from '@appcore/enums/medical-entity-type.enum';
import { YearsAco } from '@appcore/models/aco/years-aco.model';
import {
	Selection
} from '@appcore/interfaces/selection.interface';
import { SelectionsWithRange } from '@appcore/interfaces/selections-with-range.interface';
import { Locality } from '@appcore/interfaces/locality.interface';
import { RangeFilterRulesJson } from '@appcore/interfaces/range-filter-rules-json.interface';
import { Specialty } from '@appcore/models/specialty.model';

export const fetchAllFilters = createAction(
	'[Explore Component] Fetch All Filters',
	props<{
		filterTypes: string[];
		providerType: NpiType | MedicalEntityType;
		primaryProviderType: string;
	}>()
);

export const setAllFilters = createAction(
	'[Explore Component] Set All Filters',
	props<{
		acos?: YearsAco[];
		affiliations?: SelectionsWithRange;
		locations?: Locality[];
		medicalGroups?: Selection[];
		networks?: Selection[];
		rangeFilters?: RangeFilterRulesJson[];
		specialties?: Specialty[];
		providerType: NpiType | MedicalEntityType;
	}>()
);
