export const ACO_DASHBOARD_PATH = {
	ACO_INSIGHTS: 'top',
	ACO_GROWTH: 'growth',
	ACO_QUALITY: 'quality',
	ACO_DETAIL: 'details',
	ACO_DETAIL_PMPM: 'pmpm'
};

export const ACO_DASHBOARD_TITLE = {
	ACO_INSIGHTS: 'Insights',
	ACO_GROWTH: 'Growth',
	ACO_QUALITY: 'Quality'
};


export type AcoDetailKeys = 'pmpm';

export const AcoDetailConfigs: { [key in AcoDetailKeys]: AcoDetailConfig } = {
	pmpm: {
		routeString: [ACO_DASHBOARD_PATH.ACO_DETAIL, ACO_DASHBOARD_PATH.ACO_DETAIL_PMPM]
	}
};
export interface AcoDetailConfig {
    routeString: string[];
}
