// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  display: inline-block;
}

.unselected-light-circle {
  color: #ffffff;
}

.selected-light,
.unselected-light {
  color: #5991f7;
}

.unselected-dark-fad {
  --fa-secondary-opacity: 1;
  --fa-primary-color: #5991f7;
  --fa-secondary-color: #ffffff;
}

.selected-dark-fad {
  --fa-secondary-opacity: 1;
  --fa-primary-color: #ffffff;
  --fa-secondary-color: #5991f7;
}`, "",{"version":3,"sources":["webpack://./appcore/components/info-circle/info-circle.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;;EAEI,cAAA;AACJ;;AAEA;EACI,yBAAA;EACA,2BAAA;EACA,6BAAA;AACJ;;AAEA;EACI,yBAAA;EAEA,2BAAA;EACA,6BAAA;AAAJ","sourcesContent":[".tooltip {\n    display: inline-block;\n}\n\n.unselected-light-circle {\n    color: #ffffff;\n}\n\n.selected-light,\n.unselected-light {\n    color: #5991f7;\n}\n\n.unselected-dark-fad {\n    --fa-secondary-opacity: 1;\n    --fa-primary-color: #5991f7;\n    --fa-secondary-color: #ffffff;\n}\n\n.selected-dark-fad {\n    --fa-secondary-opacity: 1;\n    // switch primary and secondary when selected\n    --fa-primary-color: #ffffff;\n    --fa-secondary-color: #5991f7;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
