import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ERROR_MESSAGES } from '../shared/shared.constants';
import { NavigationService } from '../shared/services/navigation.service';
import { LoginResponse } from '../shared/models/authentication';
import { takeUntil } from 'rxjs/operators';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';

@Component({
	selector: 'mosaic-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})
export class LandingComponent extends ComponentWithSubscription implements OnInit {
	landingMessage = '';

	constructor(private navigationService: NavigationService,
		private applicationCacheService: ApplicationCacheService,
		private authenticationService: AuthenticationService) {
		super();
	}

	ngOnInit() {
		this.landingMessage = ERROR_MESSAGES.processingLogin;
		Auth.currentAuthenticatedUser({ bypassCache: true }).then(user => {
			if (user) {
				this.landingMessage = ERROR_MESSAGES.processingRequesting;
				this.authenticationService.getCognitoToken().then(jwt => {
					this.login(jwt);
				});
			} else 
				this.landingMessage = ERROR_MESSAGES.loginFailed;
			
		}).catch(error => {
			this.landingMessage = ERROR_MESSAGES.authenticationFailed;
		});
	}

	login(jwt: string) {
		this.authenticationService.loginCognito(jwt)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(
				async (obj: LoginResponse) => {
					if (obj.authenticated && !obj.authorized) {
						this.landingMessage = ERROR_MESSAGES.userNotFound;
						this.navigationService.navigateToUnauthorized();
						return;
					}
					if (!obj.authenticated){
						this.landingMessage = ERROR_MESSAGES.authenticationFailed;
						this.navigationService.navigateToUnauthorized();
						return;
					}
					try {
						this.authenticationService.onLogin(obj);
						await this.authenticationService.getCurrentUser();
						this.navigationService.navigateAfterLogin(this.applicationCacheService.getReturnUrl());
					} catch (ex) {
						this.landingMessage = ERROR_MESSAGES.authenticationFailed;
					}
				},
				error => {
					console.error('Authentication error: ', error);
					this.landingMessage = ERROR_MESSAGES.default;
					return;
				}
			);
	}
}
