import { createReducer, on } from '@ngrx/store';
import { setAllFilters } from './explore.actions';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	MedicalEntityType
} from '@appcore/enums/medical-entity-type.enum';
import {
	Selection
} from '@appcore/interfaces/selection.interface';
import { RangeFilterType } from '@appcore/enums/range-filter-type.enum';
import { FilterRules } from '@appcore/interfaces/filter-rules.interface';

const getSelection = (array: string[]): Selection[] =>
	array.map(value => ({
		id: value,
		display: value
	}));
export interface exploreState {
	filterRules: FilterRules;
	providerType?: NpiType  | MedicalEntityType;
}

export const initialExploreState: exploreState = {
	filterRules: {
		acos: [],
		locations: [],
		specialties: [],
		affiliations: { items: [], range: { name: RangeFilterType.LEVEL_OF_AFFILIATION } },
		medicalGroups: [],
		networks: [],
		rangeFilters: []
	},
	providerType: null
};

export const exploreReducer = createReducer(
	initialExploreState,
	on(setAllFilters, (state, action) => ({
		...state,
		filterRules: {
			acos: action.acos,
			locations: action.locations,
			specialties: action.specialties,
			affiliations: action.affiliations,
			medicalGroups: action.medicalGroups,
			networks: action.networks,
			rangeFilters: action.rangeFilters
		},
		providerType: action.providerType
	}))
);
