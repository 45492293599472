import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChartDataApi } from 'src/app/api/chartData.api';
import { ConfigApi, ReportDataRequest } from 'src/app/api/config.api';
import { TileConfig } from 'src/app/shared/models/tile-config.model';
import { TileElement } from 'src/app/shared/models/tile-element.model';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { InsightOptions } from '@appcore/models/insight-options.model';
import { ChartExportService } from '@appcore/services/chart-export.service';
import { ChartConfig } from '@appcore/models/chart-config.model';
import { TrellaChartComponent } from '@appcore/components/chart/chart.component';
import { ConfigType } from '@appcore/enums/config-type.enum';

@Component({
	selector: 'mosaic-assess-tile',
	templateUrl: './assess-tile.component.html',
	styleUrls: ['./assess-tile.component.scss']
})
export class AssessTileComponent extends ComponentWithSubscription implements OnInit {
	@Input() config: TileConfig;
	@Input() primaryProviderType: string;
	@Input() updateData = new Subject();
	
	loading = false;
	tileElements: Array<TileElement> = [];
	tileConfig: InsightOptions;
	valid = false;

	constructor(
		private chartDataApi: ChartDataApi,
		private configApi: ConfigApi,
		private chartExportService: ChartExportService,
		private userFeedbackService: UserFeedbackService
	) {
		super();
	}

	ngOnInit() {
		this.updateData
			.pipe(
				takeUntil(this.ngUnsubscribe),
				debounceTime(1000),
				tap(() => {
					this.loading = true;
				}),
				switchMap(() => this.configApi.getChartConfig(this.config.reportName, this.primaryProviderType)),
				tap(config => {
					if (!config) 
						return;
					

					this.tileConfig = new InsightOptions(config, this.config.reportName);
				}),
				switchMap(() => this.getData())
			)
			.subscribe(
				response => {
					this.loading = false;
					this.valid = true;
					this.setData(response);
				},
				err => {
					this.loading = false;
					this.userFeedbackService.showUnexpectedError();
				},
				() => {
					this.loading = false;
				}
			);
	}

	exportImage(): void {
		const chartComponent = { chartInfo: this.tileConfig as ChartConfig } as TrellaChartComponent;
		this.chartExportService.exportChart(chartComponent);
	}

	private getData(): Observable<any> {
		this.loading = true;
		const request: Partial<ReportDataRequest> = {
			reportName: this.config.reportName,
			gridFilters: null,
			sqlFilters: [this.config.filter],
			queryType: this.primaryProviderType,
			type: ConfigType.Chart
		};

		return this.chartDataApi.post(this.config.reportName, request as ReportDataRequest);
	}

	private setData(response: any): void {
		if (!response) 
			return;
		

		if (this.tileElements.length > 0) 
			this.tileElements = [];
		

		response.forEach(item => {
			const element = {
				title: item.extraData && item.extraData.formattedValue,
				value: item.data && item.data.formattedValue
			} as TileElement;
			this.tileElements.push(element);
		});
	}
}
