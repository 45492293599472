import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

import { EConfigInfo, EConfigSuggestion } from '../shared/models/e-config-info';
import { Utils } from '@appcore/helpers/Utils';
import { QueryOptions } from '../shared/models/query-options';
import { ReplaceValueMappingService } from '@appcore/services/replacement-value-mapping.service';
import { ChartConfigJson } from '@appcore/interfaces/chart-config-json.interface';
import { ReportConfigJson } from '@appcore/interfaces/report-config-json.interface';
import { ConfigType } from '@appcore/enums/config-type.enum';

export type ConfigModelNames = 'Chart' | 'SunburstChart';

@Injectable({
	providedIn: 'root'
})
export class ConfigApi {
	_controllerName = 'Config';

	constructor(private api: ApiService, protected http: HttpClient, private replacementService: ReplaceValueMappingService) {}

	getChartConfig(name: string, queryType: string) : Observable<any> {
		return this.api.get(this._controllerName, `chartConfig?name=${name}&queryType=${queryType}`).pipe(
			map((result: ChartConfigJson) => this.processChart(result)
			));
	}

	getGridConfig(name: string, queryType: string) : Observable<any> {
		return this.api.get(this._controllerName, `gridConfig?name=${name}&queryType=${queryType}`).pipe(
			map((result: ReportConfigJson) => {
				if (!result) 
					return result;
				

				if (result.columns && Array.isArray(result.columns)) {
					for (const column of result.columns) {
						column.title = this.replacementService.runReplacement(column.title);
						column.definition = this.replacementService.runReplacement(column.definition);

						if (column.columns && Array.isArray(column.columns)) {
							for (const subColumn of column.columns) {
								subColumn.title = this.replacementService.runReplacement(subColumn.title);
								subColumn.definition = this.replacementService.runReplacement(subColumn.definition);
							}
						}
					}
				}

				return result;
			}));
	}

	getInsightConfig(name: string, queryType: string) : Observable<any> {
		return this.api.get(this._controllerName, `insightConfig?name=${name}&queryType=${queryType}`).pipe(
			map((result: ChartConfigJson) => this.processChart(result)));
	}

	getSunburstConfig(name: string, queryType: string) : Observable<any> {
		return this.api.get(this._controllerName, `sunburstConfig?name=${name}&queryType=${queryType}`).pipe(
			map((result: ChartConfigJson) => this.processChart(result)));
	}

	processChart(result: ChartConfigJson) : ChartConfigJson {
		if (!result) 
			return result;
		

		if (result.labels) 
			result.labels = result.labels.map(label => Utils.getParsedGridColumnHeader(label));
		

		return result;
	}

	getEConfig(name: string, configType:string, queryType: string): Observable<EConfigInfo> {
		return this.api.get(this._controllerName, `e?name=${name}&configType=${configType}&queryType=${queryType}`).pipe(result => result);
	}

	saveEConfigSuggestion(configSuggestions: EConfigSuggestion) {
		return this.api.post(this._controllerName, 'e', configSuggestions);
	}

	getData(request: Partial<ReportDataRequest>): Observable<any> {
		return this.api.post(this._controllerName, `data?name=${request.reportName}`, request);
	}
}

export interface ReportDataRequest {
	additionalMetrics: number[];
	gridFilters: QueryOptions;
	overrideTimeout?: number;
	queryType: string;
	reportName: string;
	skipAllCounts?: boolean;
	sqlFilters: any;
	type: ConfigType;
}
