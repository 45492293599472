// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}

.star_color {
  color: gold;
}`, "",{"version":3,"sources":["webpack://./appcore/components/stars/stars.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[":host {\n    display: block;\n    position: relative;\n}\n\n.star_color {\n    color: gold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
