// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trella-filter-group-holder {
  display: flex;
  flex-direction: column;
}

.specialty-filter ::ng-deep .multi-level-filter {
  width: 50vw;
  min-width: 500px;
  height: 60vh;
  min-height: 450px;
}

.specialty-filter ::ng-deep .multi-level-filter .list-width {
  width: 50%;
  min-width: 250px;
}

.mssp-aco-filter ::ng-deep .multi-level-filter {
  width: 40vw;
  min-width: 500px;
  height: 60vh;
  min-height: 450px;
}

.mssp-aco-filter ::ng-deep .column-0 {
  width: 25%;
  min-width: 130px;
}

.mssp-aco-filter ::ng-deep .column-1 {
  width: 75%;
  min-width: 350px;
}`, "",{"version":3,"sources":["webpack://./appcore/components/filters/filter-group.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ","sourcesContent":[".trella-filter-group-holder {\n    display: flex;\n    flex-direction: column;\n}\n\n.specialty-filter ::ng-deep .multi-level-filter {\n    width: 50vw;\n    min-width: 500px;\n    height: 60vh;\n    min-height: 450px;\n}\n\n.specialty-filter ::ng-deep .multi-level-filter .list-width {\n    width: 50%;\n    min-width: 250px;\n}\n\n.mssp-aco-filter ::ng-deep .multi-level-filter {\n    width: 40vw;\n    min-width: 500px;\n    height: 60vh;\n    min-height: 450px;\n}\n\n.mssp-aco-filter ::ng-deep .column-0 {\n    width: 25%;\n    min-width: 130px;\n}\n\n.mssp-aco-filter ::ng-deep .column-1 {\n    width: 75%;\n    min-width: 350px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
