import { NetworkDelta } from './network-delta';

export class GrowthRequest {
	networkDelta: NetworkDelta;
	networkId: number;
	sqlFilters: any[];

	constructor(networkId?: number) {
		this.networkDelta = new NetworkDelta();
		this.networkId = networkId;
		this.sqlFilters = [];

	}
}
