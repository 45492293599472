import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationCacheService } from './application-cache.service';
import { DiagnosticCateogryApi } from '../../api/diagnostic-category.api';


@Injectable({
	providedIn: 'root'
})
export class DiagnosticCategoryService {
	public readonly diagnosticCategories: Observable<Selection[]>;
	
	private _diagnosticCateogries: BehaviorSubject<Selection[]> = new BehaviorSubject(null);
	
	constructor(private diagnosticCategoryApi: DiagnosticCateogryApi, private applicationCacheService: ApplicationCacheService) {
		this.diagnosticCategories = this._diagnosticCateogries.asObservable();
		
		this.diagnosticCategoryApi.getDiagnosticCategories().subscribe((selections: Selection[]) => {
			this._diagnosticCateogries.next(selections);
		});
	}
}
