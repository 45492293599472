import { Component, Input } from '@angular/core';
import { ConfigApi } from '../../api/config.api';
import { EConfigInfo, EColumnModel, EConfigSuggestion } from '../../shared/models/e-config-info';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { Utils } from '@appcore/helpers/Utils';
import { ConfigType } from '@appcore/enums/config-type.enum';


@Component({
	selector: 'mosaic-dev-info-dialog',
	templateUrl: './dev-info-dialog.component.html',
	styleUrls: ['./dev-info-dialog.component.scss']
})
export class DevInfoDialogComponent {
	@Input() configType: ConfigType;
	@Input() reportName: string;
	@Input() primaryProviderType: string;
	
	opened = false;
	eConfigInfo: EConfigInfo;
	columnsToShow: EColumnModel[];
	hasColumns = true;

	constructor(private configApi: ConfigApi, private userFeedbackService: UserFeedbackService) {}
	
	open() {
		this.hasColumns =  this.configType !== ConfigType.Chart;
		this.opened = true;
		this.configApi.getEConfig(this.reportName, this.configType.toString(), this.primaryProviderType).subscribe(async (response: any) => {
			this.eConfigInfo = response;
			this.columnsToShow = this.getAllColumns(this.eConfigInfo);
			this.hasColumns = !!this.columnsToShow.length;
		});
	}

	close() {
		this.opened = false;
		this.reset();
	}

	reset() {
		this.eConfigInfo = null;
		this.columnsToShow = [];
	}

	save() {
		if(!this.hasColumns)
			return;
		
		const configSuggestions = new EConfigSuggestion();
		configSuggestions.configPath = this.eConfigInfo.configPath;
		configSuggestions.columnSuggestions = this.columnsToShow.filter(c => {
			const oldColumns = this.getAllColumns(this.eConfigInfo);
			const column = oldColumns.find(o => o.field === c.field);
			return column.title !== c.title || column.definition !== c.definition ? c : null;
		});
		this.configApi.saveEConfigSuggestion(configSuggestions).subscribe(
			response => {
				this.close();
				this.userFeedbackService.showSaveSucess('Suggestions');
			},
			error => {
				this.userFeedbackService.showSaveError('Suggestions');
			}
		);
		this.opened = false;
	}

	getAllColumns(configInfo: EConfigInfo): EColumnModel[] {
		const columns = Utils.deepClone(configInfo).columns.filter(c => !c.hidden);
		const columnsToReturn: EColumnModel[] = [];
		columns.forEach(column => {
			if (column.columns && column.columns.length) {
				column.columns
					.filter(c => !c.hidden)
					.forEach(subColumn => {
						columnsToReturn.push({
							preHeader: column.title,
							title: subColumn.title,
							columns: null,
							field: subColumn.field,
							hidden: subColumn.hidden,
							definition: subColumn.definition,
							transformers: subColumn.transformers
						});
					});
				return;
			}

			columnsToReturn.push(column);
		});
		return columnsToReturn;
	}
}
