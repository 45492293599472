import { Component, OnInit } from '@angular/core';
import { NetworkSelectionService } from 'src/app/shared/services/network-selection.service';
import { Network } from 'src/app/shared/models/npi-group';

import { takeUntil } from 'rxjs/operators';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'mosaic-network-dropdown',
	templateUrl: './network-dropdown.component.html',
	styleUrls: ['./network-dropdown.component.scss']
})
export class NetworkDropdownComponent extends ComponentWithSubscription implements OnInit {
	networks: Partial<Network>[] = [];
	selectedGroupId: number;
	
	constructor(public networkSelectionService: NetworkSelectionService, private userFeedbackService: UserFeedbackService) {
		super();
	}

	ngOnInit() {
		this.networkSelectionService.networks.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			groups => {
				this.networks = groups;
			},
			error => {
				this.userFeedbackService.showUnexpectedError();
			}
		);

		this.networkSelectionService.selectedNetwork.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			selection => {
				if (selection) 
					this.selectedGroupId = selection.network;
				
			},
			error => {
				this.userFeedbackService.showUnexpectedError();
			}
		);
	}

	selectedNetworkChanged() {
		this.networkSelectionService.selectNetwork(this.selectedGroupId);
	}
}
