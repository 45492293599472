import { SearchItem } from '@appcore/models/search/search-item.model';
import { NETWORK_ACCESS_LEVEL } from '@appcore/enums/network-access-level.enum';


export enum NETWORK_FILTER_TYPE {
	EXCLUDE = 'EXCLUDE',
	INCLUDE = 'INCLUDE'
}

export enum NETWORK_TYPE_STRING {
	ACO_MSSP = 'ACO - MSSP',
	SYSTEM = 'System',
	PHYSICIAN_GROUP = 'Physician Group',
	OWNED = 'Owned',
	PARTNERS = 'Partners',
	PREFERRED = 'Preferred',
	OTHER = 'Other',
	DCE_NEW_ENTRANT = 'DCE - New Entrant',
	DCE_STANDARD = 'DCE - Standard',
	DCE_HIGH_NEEDS = 'DCE - High Needs',
	PROSPECTIVE_MSSP_ACO = 'Prospective MSSP ACO',
	RETROSPECTIVE_MSSP_ACO = 'Retrospective MSSP ACO',
	STANDARD_REACH_ACO = 'Standard REACH ACO',
}

export enum NETWORK_TYPE {
	ACO_MSSP = 'AcoMssp',
	SYSTEM = 'System',
	PHYSICIAN_GROUP = 'PhysicianGroup',
	OWNED = 'Owned',
	PARTNERS = 'Partners',
	PREFERRED = 'Preferred',
	OTHER = 'Other',
	DCE_NEW_ENTRANT = 'DceNewEntrant',
	DCE_STANDARD = 'DceStandard',
	DCE_HIGH_NEEDS = 'DceHighNeeds',
	PROSPECTIVE_MSSP_ACO = 'ProspectiveMsspAco',
	RETROSPECTIVE_MSSP_ACO = 'RetrospectiveMsspAcp',
	STANDARD_REACH_ACO = 'StandardReachAco',
}

export enum REPORTING_PERIOD {
	PY_2021 = ' PY2021'
}

export enum REPORTING_PERIOD_STRING {
	PY_2021 = ' PY2021 (PY1) - DoS 2020'
}

export class Network {
	id?: string;
	name: string;
	description?: string;
	networkType: string;
	isInSharedView = false; // only populated by edit endpoint
	isShared = false;
	markets: string[] = [];
	selectedNpis: SearchItem[] = [];
	accessLevel: string = NETWORK_ACCESS_LEVEL.CAN_EDIT;

	constructor(network?: NetworkRequest) {
		if (!network) 
			return this;
		
		this.id = network.id;
		this.name = network.name;
		this.description = network.description;
		this.networkType = network.networkType;
		this.isInSharedView = network.isInSharedView;
		this.isShared = network.isShared;
		this.accessLevel = network.accessLevel;
	}
}

export class NetworkRequest {
	id: string;
	name: string;
	description: string;
	networkType: string;
	isInSharedView: boolean;
	isShared: boolean;
	accessLevel: string;

	constructor(network?: Network) {
		if (!network) 
			return this;
		
		this.id = network.id;
		this.name = network.name;
		this.description = network.description;
		this.networkType = network.networkType;
		this.isShared = !!network.isShared;
		this.accessLevel = network.accessLevel;
	}
}

export class NetworkFilter {
	filterType: NETWORK_FILTER_TYPE;
	networkType: NETWORK_TYPE;

	constructor(filterType: NETWORK_FILTER_TYPE, networkType: NETWORK_TYPE) {
		this.filterType = filterType;
		this.networkType = networkType;
	}
}