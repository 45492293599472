import { MedicalEntityType } from '../enums/medical-entity-type.enum';
import { NpiType } from '../enums/npi-type.enum';

export class AnalyzePageTab {
    type: NpiType | MedicalEntityType;
    display: string;
    id: any;

    public constructor(init?: Partial<AnalyzePageTab>) {
        Object.assign(this, init);
    }

    get guid() {
        return `${this.type}_${this.id}`;
    }
}
