// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}

.compareChartContainer {
  align-items: center;
}

.bar {
  height: 5px;
  margin-right: 5px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.grey {
  background-color: grey;
}`, "",{"version":3,"sources":["webpack://./appcore/components/sparkline-area/sparkline-area.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":[":host {\n    display: block;\n    position: relative;\n}\n\n.compareChartContainer {\n    align-items: center\n}\n\n.bar {\n    height: 5px;\n    margin-right: 5px;\n}\n\n.red {\n    background-color: red;\n}\n\n.green {\n    background-color: green;\n}\n\n.grey {\n    background-color: grey;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
