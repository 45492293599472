// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `trella-grid {
  margin-top: -3px;
  margin-bottom: -3px;
}

::ng-deep .k-grid-header tr:hover {
  background: #5b6770 !important;
}

::ng-deep .k-grid-footer tr:hover {
  background: #5b6770 !important;
}

::ng-deep .k-detail-row > .k-hierarchy-cell + td {
  padding-left: 2.5%;
  padding-right: 0;
}

::ng-deep .k-detail-row * kendo-pager {
  display: none;
}

::ng-deep .k-detail-row > .k-hierarchy-cell + td.k-grid {
  padding: 0;
}

::ng-deep .k-detail-row > .k-hierarchy-cell + td * colgroup > col:nth-child(2) {
  width: 209.99px !important;
}

.overlayContainer {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./appcore/components/grid/row-detail-grid/row-detail-grid.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":["trella-grid {\n    margin-top: -3px;\n    margin-bottom: -3px;\n}\n\n::ng-deep .k-grid-header tr:hover {\n    background: #5b6770 !important;\n}\n\n::ng-deep .k-grid-footer tr:hover {\n    background: #5b6770 !important;\n}\n\n::ng-deep .k-detail-row > .k-hierarchy-cell + td {\n    padding-left: 2.5%;\n    padding-right: 0;\n}\n\n::ng-deep .k-detail-row * kendo-pager {\n    display: none;\n}\n\n::ng-deep .k-detail-row > .k-hierarchy-cell + td.k-grid {\n    padding: 0;\n}\n\n::ng-deep .k-detail-row > .k-hierarchy-cell + td * colgroup > col:nth-child(2) {\n    width: calc(230px * .913) !important;\n}\n\n.overlayContainer {\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
