import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChartDataApi } from 'src/app/api/chartData.api';
import { ConfigApi, ReportDataRequest } from '../../api/config.api';
import { ExpandDialogComponent } from '../expand-dialog/expand-dialog.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DevInfoDialogComponent } from '../dev-info-dialog/dev-info-dialog.component';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { Target } from '@angular/compiler';
import { Utils } from '@appcore/helpers/Utils';
import {
	Filter
} from '@appcore/models/filter.model';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { ConfigType } from '@appcore/enums/config-type.enum';
import { FilterSelection } from '@appcore/models/filter-selection.model';
import { ChartConfig } from '@appcore/models/chart-config.model';
import { ChartService } from '@appcore/services/chart.service';
import { FilterSelectionService } from '@appcore/services/filter-selection.service';
import { subscribeToKey } from '@appcore/models/keyed';
import { CHART_TYPE } from '@appcore/enums/chart-type.enum';

// DO NOT DELETE THESE 6 LINES - THEY ARE A DEPENDENCY FOR HIGHCHARTS
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
declare let require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/sunburst')(Highcharts);
exporting(Highcharts);
// ^^^^^^^

@Component({
	selector: 'mosaic-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']
})
export class ChartComponent extends ComponentWithSubscription implements OnInit {
	@ViewChild(ExpandDialogComponent) expandDialog: ExpandDialogComponent;
	@ViewChild(DevInfoDialogComponent, { static: false }) devInfoDialogComponent: DevInfoDialogComponent;
	
	@Input() chartType: ConfigType = ConfigType.Chart;
	@Input() filters: any[];
	@Input() filterChangeFunc: (value: any[]) => void;
	@Input() updateData?: Subject<any> = new Subject<any>();
	@Input() activeLegendItems = [];
	@Input() target: Target;
	@Input() chartNames: string[];
	@Input() primaryProviderType: string;
	@Input() selectedNpi: string;
	@Input() showNpiDisplay = true;
	@Input() showLoading = true;
	@Input() key = Utils.generateGuid();
	@Input() filterOptions: FilterSelection[];

	@Output() clickEvent: EventEmitter<any> = new EventEmitter();
	@Output() activeLegendItemsChange = new EventEmitter();
	@Output() dataChanged = new EventEmitter();
	
	chartConfigs: ChartConfig[] = [];
	dataChange = new Subject<ChartConfig>();
	effectiveFilters: Filter[];
	loading = true;
	selectedChartConfig: ChartConfig;

	constructor(
		private authenticationService: AuthenticationService,
		private configApi: ConfigApi,
		private chartDataApi: ChartDataApi,
		private chartService: ChartService,
		private userFeedbackService: UserFeedbackService,
		private filterSelection: FilterSelectionService
	) {
		super();
	}

	ngOnInit() {
		this.updateData
			.pipe(
				takeUntil(this.ngUnsubscribe),
				debounceTime(1000),
				tap(() => {
					this.loading = true;
				}),
				switchMap(() => {
					if (!this.haveConfigsChanged()) 
						return of([]);
					
					return forkJoin(this.chartNames.map(name => this.getConfig(name)));
				}),
				tap(configs => {
					if (!(configs && this.haveConfigsChanged())) 
						return;
					

					const newConfigs = this.chartNames.map(name => {
						const index = this.chartNames.indexOf(name);
						const config = configs[index];
						return new ChartConfig(config, name);
					});
					this.chartConfigs = newConfigs;
					this.selectedChartConfig = newConfigs[0];
					this.chartService.allowEButton(this.key, this.authenticationService.hasEButtonPermission());
				}),
				switchMap(() => this.getChartData())
			)
			.subscribe(
				data => {
					this.loading = false;
					this.setData(data);
				},
				err => {
					this.loading = false;
					this.userFeedbackService.showUnexpectedError();
				},
				() => {
					this.loading = false;
				}
			);

		this.filterSelection.selectedFilter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if (data && this.filterOptions && this.filterOptions.find(s => s.key === data.key)) 
				this.updateData.next(null);
			
		});
		subscribeToKey(this.chartService.eButtonClicked.pipe(takeUntil(this.ngUnsubscribe)), this.key, () => {
			this.devInfoDialogComponent.open();
		});
	}
    
	refreshData(config: ChartConfig) {
        this.selectedChartConfig = config;
		this.updateData.next(null);
	}
    
	onClickEvent(options: any) {
        this.clickEvent.emit(options);
	}
    
	openExpandDialog() {
        this.expandDialog.open();
	}
    
	showTitle() {
        const chartTypesWithNoTitles = [CHART_TYPE.insight, CHART_TYPE.topInsight];
		return !(chartTypesWithNoTitles.includes(this.selectedChartConfig.type) || this.selectedChartConfig.hideDisplayName);
	}
    
    private getConfig(name: string): Observable<any> {
        switch (this.chartType) {
            case ConfigType.Insight:
                return this.configApi.getInsightConfig(name, this.primaryProviderType);
            case ConfigType.Sunburst:
                return this.configApi.getSunburstConfig(name, this.primaryProviderType);
            default:
                return this.configApi.getChartConfig(name, this.primaryProviderType);
        }
    }

    private haveConfigsChanged(): boolean {
        const hasConfigs = !!this.chartConfigs;
        const hasReportNames = !!this.chartNames;

        if (!hasConfigs) 
            return hasReportNames;
        

        if (!hasReportNames) 
            return true;
        

        if (this.chartConfigs && this.chartConfigs.length !== this.chartNames.length) 
            return true;
        

        return this.chartNames.some(name => !this.chartConfigs.some(info => info.reportName === name));
    }

    private getChartData(): Observable<any> {
        this.loading = true;
        const matchingConfig = this.chartConfigs.find(c => c.title === this.selectedChartConfig.title);
        const index = this.chartConfigs.indexOf(matchingConfig);
        const chartName = this.chartNames[index];

        this.effectiveFilters = (this.filters || []).concat(this.getSelectedFilters());
        const request: Partial<ReportDataRequest> = {
            reportName: chartName,
            gridFilters: null,
            sqlFilters: this.effectiveFilters,
            queryType: this.primaryProviderType,
            type: this.chartType
        };

        return this.chartDataApi.post(chartName, request as ReportDataRequest);
    }

    private getSelectedFilters() {
        if (this.filterOptions && this.filterOptions.length) {
            const allFilters = this.filterOptions.map((selection: FilterSelection) => {
                const filter = selection.filter;
                const value = selection.values.find(v => v.key === localStorage.getItem(selection.key)) || selection.values[0];
                filter.modelJson = value.value;
                return filter;
            });
            return allFilters;
        }

        return [];
    }

    private setData(response: any): void {
        if (!response) 
            return;
        

        this.selectedChartConfig.data = response;
        this.dataChange.next(this.selectedChartConfig);
        this.dataChanged.emit();
    }
}
