export class Stack<T>{

    private _items: T[] = [];

    public pop(){
        this._items.pop();
    }
    
    public push(item:T){
        this._items.push(item);
    }
}