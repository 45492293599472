
import Utils from '../../Utils';
import { TotalCostOfCareType } from '../total-cost-of-care.model';
import { SearchItem } from '@appcore/models/search/search-item.model';

export class TccSearchResponse {
	city: string;
	npi: string;
	npiName: string;
	specialty: string;
	state: string;
	type?: TotalCostOfCareType;

	static toSearchItem(search: TccSearchResponse): SearchItem {
		return Object.assign(new SearchItem(), {...search,
			npi: search.npi,
			display: search.npiName,
			state: search.state,
			specialty: search.specialty,
			searchResultType: Utils.getSearchTypeByTccType(search.type),
			icon: Utils.getNpiTypeConfigByTccType(search.type)?.icon ?? ''
		});
	}
}
