import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomListApi } from 'src/app/api/custom-list.api';
import { CUSTOM_LIST_TYPE } from 'src/app/shared/enums/custom-list-type.enum';
import { CustomList } from 'src/app/shared/models/custom-list';
import { CustomListGroup } from 'src/app/shared/models/custom-list-group';
import { CustomListItem } from 'src/app/shared/models/custom-list-item';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { NpiType } from '@appcore/enums/npi-type.enum';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'mosaic-update-custom-list',
	templateUrl: './update-custom-list.component.html'
})
export class UpdateCustomListComponent extends ComponentWithSubscription implements OnChanges {
	@Input() customListItems: CustomListItem[];
	@Input() customListType: CUSTOM_LIST_TYPE;
	@Input() npiType: NpiType = null;
	@Input() opened: boolean;

	@Output() closed: Observable<void>;
	@Output() listCreationOpened: Observable<CustomList[]>;
	@Output() listUpdated: Observable<CustomListGroup>;
	
	customLists: CustomList[] = [];
	loading = false;
	selectedCustomList: CustomList;
	selectedCustomListItems: CustomListItem[] = [];
	virtual: VirtualizationSettings = { itemHeight: 33 };
	
	private _closed: EventEmitter<void> = new EventEmitter();
	private _listCreationOpened: EventEmitter<CustomList[]> = new EventEmitter();
	private _listUpdated: EventEmitter<CustomListGroup> = new EventEmitter();

	constructor(private customListApi: CustomListApi, private userFeedbackService: UserFeedbackService) {
		super();
		
		this.closed = this._closed.asObservable();
		this.listCreationOpened = this._listCreationOpened.asObservable();
		this.listUpdated = this._listUpdated.asObservable();
	}
	
	get areButtonsDisabled(): boolean {
		return this.selectedCustomList === null || this.selectedCustomList === undefined;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.opened && changes.opened.currentValue) 
			this.loadLists();
		
	}

	add(): void {
		if (!this.selectedCustomListItems) 
			return;
		

		this.selectedCustomListItems = [...this.selectedCustomListItems, ...this.customListItems];
		this.uniqueComparisons();
		this.updateGroup();
	}

	close(): void {
		this.resetOptions();
		this._closed.emit();
	}

	onSelectChange(list: CustomList): void {
		if (list?.id === -1) {
			const customLists = this.customLists;
			this.resetOptions();
			this._listCreationOpened.emit(customLists);
		} else 
			this.loadListItems();
		
	}

	remove() {
		if (!this.selectedCustomListItems || !this.selectedCustomListItems.length) 
			return;
		

		this.selectedCustomListItems = this.selectedCustomListItems.filter(
			selectedItem => !this.customListItems.some(customList => customList.name === selectedItem.name)
		);
		this.updateGroup();
	}

	private loadListItems(): void {
		this.loading = true;
		this.customListApi
			.getCustomListItems(this.selectedCustomList.id, this.npiType)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				listItems => {
					this.selectedCustomListItems = listItems;
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private loadLists(): void {
		this.loading = true;

		this.customListApi
			.getCustomLists(this.customListType)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				lists => {
					this.customLists = lists;
					this.setOptions();
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}

	private resetOptions(): void {
		this.customLists = [];
		this.selectedCustomList = null;
	}

	private setOptions(): void {
		this.customLists = this.customLists.sort((a, b) => a.name.localeCompare(b.name));

		const newList = new CustomList();
		newList.name = 'Create New List...';
		newList.id = -1;
		this.customLists.unshift(newList);
	}

	private uniqueComparisons(): void {
		const uniqueListItems: CustomListItem[] = [];

		for (const listItem of this.selectedCustomListItems) {
			if (!uniqueListItems.some(uniqueItem => uniqueItem.name === listItem.name)) 
				uniqueListItems.push(listItem);
			
		}

		this.selectedCustomListItems = uniqueListItems;
	}

	private updateGroup(): void {		
		const group = {
			customListId: this.selectedCustomList.id,
			items: this.selectedCustomListItems
		} as CustomListGroup;
		
		this.loading = true;
		this.customListApi
			.updateCustomListItems(group)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				success => {
					this.resetOptions();
					this._listUpdated.emit(group);
				},
				error => this.userFeedbackService.showUnexpectedError()
			);
	}
}
