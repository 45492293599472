import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';

import { NavigationService } from '../../shared/services/navigation.service';
import { GridColumn } from '@appcore/models/grid-column.model';
import { ChartConfig } from '@appcore/models/chart-config.model';

@Component({
	selector: 'mosaic-expand-dialog',
	templateUrl: './expand-dialog.component.html',
	styleUrls: ['./expand-dialog.component.scss']
})
export class ExpandDialogComponent implements AfterViewInit {
	@Input() currentChart: ChartConfig;
	@Input() filters: any[];
	@Input() primaryProviderType: string;
	@Input() showNpiDisplay: boolean;
	@Input() npiDisplay: string;

	opened: boolean;
	updateData = new Subject<GridColumn[]>();
	updateChartData = new Subject<ChartConfig>();
	currentFilters: any[];
	reportNames = [];
	getAnalyzeUrl = this.navigationService.getAnalyzeUrl;

	constructor(private ref: ChangeDetectorRef, private navigationService: NavigationService) {}

	ngAfterViewInit() {
		this.updateElements();
	}

	open() {
		this.opened = true;
		this.reportNames = this.currentChart.linkedTableReports;
		this.currentFilters = this.filters;
		this.ref.detectChanges();
		this.updateElements();
	}

	updateElements() {
		this.updateData.next(null);
		this.updateChartData.next(this.currentChart);
	}

	close() {
		this.opened = false;
	}

	showDisplayName() {
		return !this.currentChart.hideDisplayName || this.currentChart.showNameExpanded;
	}
}
