import { Component } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
	constructor(private navigationService: NavigationService) {}

	toHome() {
		this.navigationService.navigateToHome();
	}

	getYear(){
		return new Date().getFullYear();
	}
}
