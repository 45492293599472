import { Component, OnInit } from '@angular/core';

import { Tile } from 'src/app/shared/models/tile.model';
import { AcoDashboardService } from 'src/app/shared/services/aco-dashboard.service';
import { AcoTopMetricsService } from 'src/app/shared/services/aco-top-metrics.service';
import { ACO_DASHBOARD_PATH } from 'src/app/main/aco-dashboard/aco-dashboard.constants';
import { Subject } from 'rxjs';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { AcoTileService } from 'src/app/shared/services/aco-tile.service';
import { AcoService } from 'src/app/shared/services/aco.service';
import { TILE_DRILLDOWN_TYPE } from '../../shared/enums/tile-drilldown-type.enum';
import { PAGE } from 'src/app/shared/enums/page.enum';
import { PmpmSpending } from 'src/app/shared/models/pmpm-spending';
import { AcoPeriodService } from 'src/app/shared/services/aco-period.service';
import { takeUntil } from 'rxjs/operators';
import { ProgramType } from 'src/app/shared/enums/aco-period-type.enum';
import { NetworkSelectionService } from 'src/app/shared/services/network-selection.service';
import {
	ComponentWithSubscription
} from '@appcore/components/component-with-subscription';
import { Filter } from '@appcore/models/filter.model';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { Selection } from '@appcore/interfaces/selection.interface';
import { Target } from '@appcore/models/target.model';

@Component({
	selector: 'mosaic-user-network-pmpm',
	templateUrl: './user-network-pmpm.component.html',
	styleUrls: ['./user-network-pmpm.component.scss']
})
export class UserNetworkPmpmComponent extends ComponentWithSubscription implements OnInit {
	networkId: number;
	acoPeriodId: number;

	updateChartData = new Subject();
	updatePhysicianGridData = new Subject();
	updateCountyGridData = new Subject();

	tile: Tile;
	target: Target;
	sums: PmpmSpending[] = [];

	processedTrendChartName: string;
	processedProviderGridName: string;
	processedCountyBreakoutGridName: string;
	primaryProviderType: string;

	chartLoading = true;
	tileLoading = true;
	isRiskAdjusted = false;

	programType = ProgramType.Dce;

	trendsChartName = 'aco_pmpm_yearly_trends_user';
	countyBreakoutGridName = 'aco_providers_user_county_breakout';
	providerGridName = 'aco_providers_user';

	gridFilters: Filter[];
	chartFilters: Filter[];
    
    getAnalyzeUrl = this.navigationService.getAnalyzeUrl;
	
    private _counties: Selection[] = [];

    constructor(
		public acoDashboardService: AcoDashboardService,
		private acoPeriodService: AcoPeriodService,
		private acoService: AcoService,
		private acoTileService: AcoTileService,
		private acoTopMetricsService: AcoTopMetricsService,
		private navigationService: NavigationService,
		private networkSelectionService: NetworkSelectionService
	) {
		super();
	}

	ngOnInit() {
		this.acoTopMetricsService.userTilesFetched.pipe(
			takeUntil(this.ngUnsubscribe)
			).subscribe(t => {
				this.handleTiles(t);
			});

		this.acoPeriodService.selectedAcoPeriod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedAcoPeriod => {
			if(selectedAcoPeriod) {
				this.acoPeriodId = selectedAcoPeriod;
				this.getNewTiles();
				this.onChange();
			}
			this.tileLoading = true;
		});

		this.acoDashboardService.selectedNetworkAco.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedNetwork => {
			if(selectedNetwork) {
				const showAcoPeriodDrowpdown = this.networkSelectionService.shouldShowAcoPeriodDropdown(this.programType, selectedNetwork.networkType);
				this.networkId = selectedNetwork.networkId;
				if (!showAcoPeriodDrowpdown)
					this.acoPeriodService.getDefaultAcoPeriod(this.programType).subscribe();
				
				this.getNewTiles();
			}
			this.tileLoading = true;
		});

		this.acoTileService.riskAdjustedTileChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(t => {
			this.isRiskAdjusted = this.acoTileService.isRiskAdjusted(this.tile);
			this.onChange();
		});

		this.acoService.pmpmSpending.pipe(takeUntil(this.ngUnsubscribe)).subscribe(p => {
			if (!p) 
				return;
			

			const spendingWithTarget = this.isRiskAdjusted ? p.find(s => s.riskAdjustedTarget) : p.find(s => s.target);
			const target = this.isRiskAdjusted ? spendingWithTarget?.riskAdjustedTarget : spendingWithTarget?.target;
			this.sums = p;

			if (!target) {
				this.target = null;
				return;
			}
			this.target = {
				value: Number(target.replace(/[^0-9\.-]+/g, '')).toString(),
				title: target
			};
		});

		this.acoDashboardService.countiesSelected.pipe(takeUntil(this.ngUnsubscribe)).subscribe(counties => {
			this._counties = counties;
			this.onChange();
		});
	}

	onBackClick() {
		this.navigationService.navigateToDashboardChild([ACO_DASHBOARD_PATH.ACO_INSIGHTS]);

	}

	chartDataChanged() {
		this.chartLoading = false;
	}

    getNewTiles() {
		if(this.networkId && this.acoPeriodId){
			this.acoTopMetricsService.getTilesByNetwork(this.networkId, this.acoPeriodId, PAGE.ACO_DASHBOARD).subscribe();
			this.acoService.getUserAcoPmpmSums(this.networkId, this.acoPeriodId).subscribe();
		}
	}

	private onChange() {
		this.chartLoading = true;
		this.processedTrendChartName = this.getProcessedName(this.trendsChartName);
		this.processedCountyBreakoutGridName = this.getProcessedName(this.countyBreakoutGridName);
		this.processedProviderGridName = this.getProcessedName(this.providerGridName);
		this.primaryProviderType = 'aco';
		this.gridFilters = this.getGridFilters();
		this.chartFilters = this.getSharedFilters();
		this.updateChartData.next(null);
		this.updatePhysicianGridData.next(null);
		this.updateCountyGridData.next(null);
	}

	private getProcessedName(name: string): string {
		return !this.isRiskAdjusted ? name : `${name}_adj`;
	}

	private getSharedFilters() : Filter[] {
		return [{
			filterType: 'Network',
			modelJson: `[${this.networkId}]`
		},
		{
			filterType: FILTER_TYPE.HARD_CODED,
			paramName: 'acoPeriodId',
			modelJson: String(this.acoPeriodId)
		}];
	}

	private getGridFilters() {
		const filters: Filter[] = this.getSharedFilters();

		if (this._counties?.length){
			filters.push({
				filterType: FILTER_TYPE.PHYSICIAN_COUNTIES,
				modelJson: JSON.stringify(this._counties.map(c => c.id))
			});
		}
		return filters;
	}

	private handleTiles(t: Tile[]){
		if (!t) 
			return;
		
			this.tile = t.find(x => x.drillDownType === TILE_DRILLDOWN_TYPE.PMPM);
			this.isRiskAdjusted = this.acoTileService.isRiskAdjusted(this.tile);
			this.tileLoading = !this.tile;
			this.onChange();
	}
}
