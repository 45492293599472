import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { AcoApi } from 'src/app/api/aco.api';
import { MedicalEntityTypeConfigs } from 'src/app/shared/models/medical-entity-type-configs';
import { Aco } from '@appcore/models/aco/aco.model';

@Injectable({ providedIn: 'root' })
export class AcoInfoService {
	acoInfo: Observable<Aco>;
	acoConfig = MedicalEntityTypeConfigs.aco;
	
	private info: Subject<Aco> = new BehaviorSubject<Aco>(null);
	
	constructor(private acoApi: AcoApi) {
		this.acoInfo = this.info.asObservable();
	}

	getAcoInfo(acoId: string) {
		this.acoApi.getAco(acoId).subscribe(
			info => {
				this.info.next(info);
			}
		);
	}


}
