import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { AuthApi } from '../../api/auth.api';
import { CognitoClient, LoginResponse } from '../models/authentication';
import { CurrentUserResponse } from '../models/user';
import { LOCAL_STORAGE_KEY } from '../shared.constants';
import { UserApi } from './../../api/user.api';
import { ApplicationCacheService } from './application-cache.service';
import { NavigationService } from './navigation.service';
import { SSOService } from './sso.service';
import { EnvService } from  '@appcore/services/env.service';
import { TokenService } from '@appcore/services/token.service';
import { PERMISSIONS } from '@appcore/constants/permissions.constant';
import { ReplaceValueMappingService } from '@appcore/services/replacement-value-mapping.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	defaultSource = 'default';
	sourceParam = 'source';

    
	constructor(
        private authApi: AuthApi,
		private userApi: UserApi,
		private navigationService: NavigationService,
		private appCache: ApplicationCacheService,
		private replacementValueService: ReplaceValueMappingService,
		private envService: EnvService,
		private ssoService: SSOService,
        private tokenService: TokenService
        ) {}
        

    async getCognitoToken() {
        return TokenService.getCognitoToken();
    }
    
	login(email, password) {
		return this.authApi.login(email, password);
	}

	loginCognito(jwt: string) {
		return this.authApi.loginCognito(jwt);
	}

	onLogin(authResponse: LoginResponse) {
		if (!authResponse || !authResponse.authenticated) 
			return;
		
		this.storeSource(authResponse.source);
	}

	public getCognitoClientBySource(source: string): Observable<CognitoClient> {
		return this.authApi.getCognitoClient(source);
	}

	public getCognitoClientByEmail(userEmail: string): Observable<CognitoClient> {
		return this.authApi.identifyClient(userEmail);
	}

	async logoutWithoutNavigate() {
		await Auth.signOut();
		this.appCache.setReturnUrl(null);
	}

	async logout() {
		await Auth.signOut();
		this.appCache.setReturnUrl(null);
		this.navigationService.navigateToLogout();
	}

	hasEButtonPermission() {
		const user = this.appCache.user;
		return user.permissions.some(permission => permission.name === PERMISSIONS.ALLOW_E_BUTTON);
	}


	public canImpersonate(): boolean {
		return !!this.appCache.user.imitableUsers.length;
	}

	async getCurrentUser(): Promise<CurrentUserResponse> {
		const results = await this.userApi
			.getCurrent()
			.toPromise()
			.catch(err => null);

		if (!results) {
			this.navigationService.navigateToUnauthorized();
			return;
		}

		const userResponse = new CurrentUserResponse(results);
		const { user: currentUser } = userResponse;
		this.appCache.setUser(currentUser);
		this.replacementValueService.setMap(currentUser.replacementValues);

		return userResponse;
	}

	getSourceFromStorage() {
		return localStorage.getItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
	}

	storeSSOSource(sourceToken: string) {
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_SOURCE, sourceToken);
	}

	async getClientIdBasedUrl(sourceToken: string): Promise<string> {
		let cognitoClients = [];
		const source = sourceToken || this.defaultSource;
		cognitoClients = (await this.authApi
			.getCognitoClients(source)
			.toPromise()
			.catch(err => {
				console.log('Error getting SSO client ids: ', err);
			})) as CognitoClient[];
		let matchedClientId = cognitoClients.find(x => x.source === sourceToken);
		if (!matchedClientId || !matchedClientId.clientId) 
			matchedClientId = cognitoClients.find(x => x.source === this.defaultSource);
		
		return this.envService.cognitoUrl(matchedClientId.clientId);
	}

	private storeSource(source: string) {
		if (source)
			this.ssoService.storeSSOSource(source);

	}
}
